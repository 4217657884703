import type { ReactNode } from 'react'
import { clsx } from 'clsx'

export const PanelHeader = ({ children, ...props }) => {
  return (
    <div
      className={clsx(
        'z-10 size-fit truncate bg-white-off px-8 py-5 text-2.5xl lowercase text-blue first-letter:capitalize',

        props.className
      )}
    >
      {children}
    </div>
  )
}

interface PanelProps {
  header: string
  position?: 'left' | 'center' | 'top-left' | 'none'
  className?: string
  children?: ReactNode
}

export const Panel = ({ position = 'top-left', ...props }: PanelProps) => {
  function getPosition(position: 'left' | 'center' | 'top-left' | 'none') {
    switch (position) {
      case 'top-left':
        return {
          header: '',
          layout: 'flex flex-col',
          in: `-mt-12`,
          out: `-top-12 -left-8`,
        }
      case 'left':
        return {
          header: 'my-auto',
          layout: 'flex',
          in: `-ml-8`,
          out: `-left-8`,
        }
      case 'center':
        return {
          header: 'mx-auto',
          layout: 'flex flex-col',
          in: `-mt-12`,
          out: `-top-12`,
        }
      case 'none':
        return {
          out: '',
          in: '',
          header: 'hidden',
          layout: '',
        }
    }
  }

  return (
    <div className={clsx('bg-blue', getPosition(position).layout)}>
      <PanelHeader
        className={clsx(
          getPosition(position).header,
          'relative',
          getPosition(position).out
        )}
      >
        {props.header}
      </PanelHeader>
      <div
        className={clsx(
          'relative min-w-[200px] grow rounded bg-blue p-6',
          getPosition(position).in,
          props.className
        )}
      >
        {props.children}
      </div>
    </div>
  )
}
