import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import * as Sentry from '@sentry/react'

import type { FrontendUser } from '@teamup/db'

import { TRPCProvider } from '~/context/useTRPC'
import { WebsocketsProvider } from '~/context/useWebsockets'

const UserContext = createContext<UserContextType>({} as UserContextType)

interface UserContextType {
  user: FrontendUser | undefined
  setUser: (user: FrontendUser | undefined) => void
  authenticated: boolean
  setAuthenticated: (authenticated: boolean) => void
}

export function UserProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<FrontendUser | undefined>()
  const [authenticated, setAuthenticated] = useState(false)

  useEffect(() => {
    if (user) {
      Sentry.setUser(user)
    }
  }, [user])

  const memo = useMemo(
    () => ({
      user,
      setUser,
      setAuthenticated,
      authenticated,
    }),
    [user, authenticated]
  )

  return (
    <UserContext.Provider value={memo}>
      <TRPCProvider>
        <WebsocketsProvider>{children}</WebsocketsProvider>
      </TRPCProvider>
    </UserContext.Provider>
  )
}

export default function useUser() {
  return useContext(UserContext)
}
