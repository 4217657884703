import { z } from "zod";

export enum FacilitatorCommand {
  START_ALL = "start-all",
  PAUSE_ALL = "pause-all",
  RESUME_ALL = "resume-all",
  TERMINATE_SESSION = "terminate-session",

  ADD_TEAM = "add-team",

  SKIP_TO_CHALLENGE = "skip-challenge",
  PAUSE_TEAM = "pause-team",
  START_TEAM = "start-team",

  RESUME_TEAM = "resume-team",
  RESET_PLAYER = "reset-player",
  DELETE_SESSION = "DELETE_SESSION",
  RESET_CHALLENGE = "RESET_CHALLENGE",
  KICK_PLAYER = "KICK_PLAYER",
  SWITCH_MAX_PLAYERS = "SWITCH_MAX_PLAYERS",
}

export enum PlayerStatus {
  RESERVED = "reserved",
  CONNECTING = "connecting",
  CONNECTED = "connected",
  READY = "ready",
  OFFLINE = "offline",
}

export enum PlayerCommands {
  GET_CONNECTION_INFO = "get-connection-info",
  CLAIM_SPOT = "CLAIM_SPOT",
  GET_SPECTATOR_INFO = "get-spectator-info",
  READY = "READY",
  CONNECTED = "CONNECTED",
}

export enum TeamCommands {
  SKIP_CHALLENGE = "SKIP_CHALLENGE",
  RESET_CHALLENGE = "RESET_CHALLENGE",
  NEXT_CHALLENGE = "NEXT_CHALLENGE",
  RESUME = "RESUME",
  PAUSE = "PAUSE",
  SET_MAX_PLAYERS = "SET_MAX_PLAYERS",
}

// export enum ChallengeStatus {
//     PLAYING,
//     UNPLAYED,
//     PLAYED,
// }
