import Img from '~/assets/images/teamup/Mid2.png?w=512&format=webp'
import { Button } from '~/components/ui'
import { Modal, useModal } from '~/components/ui/elements/Modal'
import { ForgotPasswordForm } from '~/components/ui/forms/ForgotPasswordForm'
import { Panel } from '~/components/ui/Panel'
import { trpc } from '~/lib/trpc'

const ProfilePage = () => {
  const { isShowing, toggle } = useModal()
  const { data: user } = trpc.user.me.useQuery()

  function forgotPassword() {
    toggle()
  }

  return (
    <>
      {user && (
        <>
          <Modal title="Password reset" isShowing={isShowing}>
            <ForgotPasswordForm onClose={toggle} />
          </Modal>
          <div>
            <Panel header="Profile" className="flex flex-col" position="left">
              <div className="flex">
                <div className="mr-10">
                  <p>Email:</p>
                  <p>Company:</p>
                </div>
                <div>
                  <p>{user.email}</p>
                  <p>{user.company?.name}</p>
                </div>
              </div>
              <div className="flex flex-col items-end">
                <Button size="small" onClick={() => forgotPassword()}>
                  Change password
                </Button>
              </div>
            </Panel>
            <img
              src={Img}
              alt=""
              width={512}
              height={512}
              className="-z-10 mx-auto w-[512px] object-contain"
            />
          </div>
        </>
      )}
    </>
  )
}

export default ProfilePage
