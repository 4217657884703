import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { motion } from 'framer-motion';
import { Unity, useUnityContext } from 'react-unity-webgl';



import GameLayout2 from '~/components/game/GameLayout';
import {
  getBuildExt,
  getBuildPath,
  getStreamingAssets,
  UnityGame,
} from '~/components/game/Unity'
import opentelemetry from '@opentelemetry/api';


const TestGameLayout = () => {
  const [isOpen, setIsOpen] = useState(true);

  const {
    unityProvider,
    loadingProgression,
    isLoaded,
    sendMessage: toUnity,
  } = useUnityContext({
    loaderUrl: getBuildPath() + `webgl.loader.js`,
    dataUrl: getBuildPath() + 'webgl.data' + getBuildExt(),
    frameworkUrl: getBuildPath() + 'webgl.framework.js' + getBuildExt(),
    codeUrl: getBuildPath() + 'webgl.wasm' + getBuildExt(),
    streamingAssetsUrl: getStreamingAssets(),
    companyName: 'The Barn',
    productName: 'TeamUp',
    productVersion: '0.0.1b',
  });

  const getProgress = () => {
    return loadingProgression * 100 + '%';
  };

  useEffect(() => {
    if (isLoaded) {
      setTimeout(() => {
        setIsOpen(false);
        toUnity('Logger', 'FromReact', JSON.stringify({ id: 'test-connection' }));
        // const myMeter = opentelemetry.metrics.getMeter('my-service-meter');
        // const fpsHistogram = myMeter.createUpDownCounter('test.fps');
        // const pingHistogram = myMeter.createUpDownCounter('test.ping');
        //
        //
        // addEventListener('metrics', (metrics: any) => {
        //   const { fps, ping } = JSON.parse(metrics);
        //   console.log(fps, ping);
        //   fpsHistogram.add(fps, { test: 'test' });
        //   pingHistogram.add(ping, { test: 'test' });
        // });
        //
        // setInterval(() => {
        //   // toUnity('Logger', 'FromReact', JSON.stringify({ id: 'metrics' }));
        // }, 1000);
      }, 1000);
    }
  }, [isLoaded]);


  return (
    <Fragment>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="flex h-[272px] w-[448px] flex-col bg-blue">
                  <div className={'mt-[96px] flex flex-col items-center'}>
                    <img src="/logonoline.svg" width={276} height={64} alt="" />
                  </div>

                  <div className="flex items-center justify-center">
                    <div className="h-2 w-full bg-blue-light">
                      <motion.div
                        className="h-2 bg-white-off"
                        initial={{ width: 0 }}
                        animate={{
                          width: getProgress(),
                          transition: { duration: 0.5 },
                        }}
                      />
                    </div>
                  </div>

                  <div className={'grow'}></div>

                  <div className={'mb-6'}>
                    <p>Loading...</p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <div className={'h-screen w-screen'}>
        <Unity
          devicePixelRatio={window.devicePixelRatio}
          unityProvider={unityProvider}
          style={{ visibility: isLoaded ? 'visible' : 'hidden', width: '100%', height: '100%' }}
        />
      </div>
    </Fragment>

  )
}

export default TestGameLayout
