import { useState } from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import { MdMode } from 'react-icons/md'

import type { User } from '@teamup/db'

import { Button } from '~/components/ui'
import { Modal, useModal } from '~/components/ui/elements/Modal'
import { Table } from '~/components/ui/elements/Table'
import UserForm from '~/components/ui/forms/models/UserForm'
import { Panel } from '~/components/ui/Panel'
import { trpc } from '~/lib/trpc'

export const Users = () => {
  const { data: users, refetch, isFetched } = trpc.user.list.useQuery()

  const [user, setUser] = useState<User | null>(null)
  const { isShowing, toggle } = useModal()

  const userHelper = createColumnHelper<User>()
  const userColumns = [
    userHelper.accessor('name', {
      header: 'Name',
      cell: (info) => info.getValue(),
    }),
    userHelper.display({
      id: 'actions',
      header: 'Actions',
      cell: (info) => (
        <Button
          icon={<MdMode />}
          size="small"
          onClick={() => editUser(info.row.original)}
        >
          Edit
        </Button>
      ),
    }),
  ]

  const editUser = (user: User): void => {
    setUser(user)
    toggle()
  }

  function newUser(): void {
    setUser(null)
    toggle()
  }

  function onClose() {
    toggle()
    refetch()
  }

  return (
    <>
      <Modal isShowing={isShowing} title="Create/Edit User">
        <UserForm user={user} onClose={onClose} />
      </Modal>

      {isFetched && (
        <Panel header="Users">
          <Button icon={<MdMode />} size="small" onClick={() => newUser()}>
            Create new
          </Button>
          {users && <Table data={users} columns={userColumns} />}
        </Panel>
      )}
    </>
  )
}
