import type { Challenge, SessionWithPartialRelations } from '@teamup/db'

import Img from '~/assets/images/teamup/Tiny4.png?url&w=220&format=webp'
import { SessionPreviewList } from '~/components/dashboard/SessionPreviewList'
import { Button } from '~/components/ui'
import { CreateSession } from './CreateSession'

interface DashboardProps {
  challenges: Challenge[]
  activeSessions: SessionWithPartialRelations[]
  upcomingSessions: SessionWithPartialRelations[]
}

export const Dashboard = ({
  challenges,
  activeSessions,
  upcomingSessions,
}: DashboardProps) => {
  return (
    <div className="flex flex-col gap-y-16">
      <CreateSession challenges={challenges} />
      <SessionPreviewList sessions={activeSessions} header="Active sessions" />
      {upcomingSessions.length > 0 && (
        <SessionPreviewList
          sessions={upcomingSessions}
          header="Upcoming sessions"
        />
      )}
      <div className="mx-auto flex">
        <img src={Img} width={220} height={220} alt="" />
      </div>
    </div>
  )
}
