import { createColumnHelper } from '@tanstack/react-table'
import { DateTime } from 'luxon'
import { MdArrowForward } from 'react-icons/md'
import { Link } from 'react-router-dom'

import type {
  SessionWithPartialRelations,
  SessionWithRelations,
} from '@teamup/db'

import { Button } from '~/components/ui/elements/Button'
import { Table } from '../ui/elements/Table'

export const SessionTable = ({
  sessions,
}: {
  sessions: SessionWithPartialRelations[]
}) => {
  // TODO - get isAdmin from session

  const columnHelper = createColumnHelper<SessionWithRelations>()
  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('status', {
      header: 'Status',
    }),
    columnHelper.accessor('teams', {
      header: () => '# of teams',
      cell: (info) => info.getValue().length,
    }),
    columnHelper.accessor('start', {
      id: 'start',
      header: () => 'Date',
      sortingFn: 'datetime',
      sortDescFirst: true,
      cell: (info) =>
        DateTime.fromJSDate(new Date(info.getValue())).toLocaleString(),
    }),

    columnHelper.display({
      id: 'actions',
      cell: (info) => {
        if (info.row.original.status === 'CLOSED') return null
        return (
          <Link to={`/dashboard/sessions/${info.row.original.uuid}`}>
            <Button icon={<MdArrowForward />} size="small">
              Details
            </Button>
          </Link>
        )
      },
    }),
  ].filter(Boolean)

  const sorting = [
    {
      id: 'start',
      desc: true,
    },
  ]

  return <Table data={sessions} columns={columns} dsorting={sorting} />
}
