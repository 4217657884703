import { useState } from 'react'
import * as Sentry from '@sentry/react'
import { createColumnHelper } from '@tanstack/react-table'
import { MdMode } from 'react-icons/md'

import type { Challenge } from '@teamup/db'

import { Button } from '~/components/ui'
import { Modal, useModal } from '~/components/ui/elements/Modal'
import { Table } from '~/components/ui/elements/Table'
import { ChallengeForm } from '~/components/ui/forms/models/ChallengeForm'
import { Panel } from '~/components/ui/Panel'
import { trpc } from '~/lib/trpc'

export const Challenges = () => {
  const { data: challenges, isFetched } = trpc.challenge.list.useQuery({})

  const [challenge, setChallenge] = useState<Challenge | null>(null)
  const { isShowing, toggle } = useModal()

  const challengeHelper = createColumnHelper<Challenge>()
  const challengeColumns = [
    challengeHelper.accessor('display', {
      header: 'Display',
      cell: (info) => info.getValue(),
    }),
    challengeHelper.accessor('name', {
      header: 'Name',
      cell: (info) => info.getValue(),
    }),
    challengeHelper.accessor('order', {
      header: 'Order',
      cell: (info) => info.getValue(),
    }),
    challengeHelper.display({
      id: 'actions',
      header: 'Actions',
      cell: (info) => (
        <Button
          icon={<MdMode />}
          size="small"
          onClick={() => editChallenge(info.row.original)}
        >
          Edit
        </Button>
      ),
    }),
  ]

  const editChallenge = (challenge: Challenge): void => {
    setChallenge(challenge)
    toggle()
  }

  function newChallenge(): void {
    setChallenge(null)
    toggle()
  }

  // function onClose() {
  //   toggle();
  //   refetch();
  // }

  return (
    <>
      <Modal isShowing={isShowing} title={'Create/Edit User'}>
        {challenge && <ChallengeForm challenge={challenge} onClose={toggle} />}
      </Modal>

      <Panel header="Challenges">
        <Button icon={<MdMode />} size="small" onClick={() => newChallenge()}>
          Create new
        </Button>
        {isFetched && challenges && (
          <Table data={challenges} columns={challengeColumns} />
        )}
      </Panel>
    </>
  )
}
