import type {
  PaginationState,
  RowData,
  SortingState,
} from '@tanstack/react-table'
import type { ComponentProps } from 'react'
import React, { useEffect, useMemo, useState } from 'react'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { clsx } from 'clsx'
import {
  MdChevronLeft,
  MdChevronRight,
  MdExpandLess,
  MdExpandMore,
} from 'react-icons/md'

import { Button } from '~/components/ui'
import { InputField } from '~/components/ui/elements/InputField'
import { MyListbox } from '~/components/ui/elements/Listbox'
import { Panel } from '~/components/ui/Panel'

interface SessionTableProps extends ComponentProps<'div'> {
  data: RowData[]
  //eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO Find out columnType
  columns: any
  dsorting?: SortingState
  onClick?: (original: unknown) => unknown
}

export const Table = ({
  data,
  columns,
  onClick,
  dsorting = [],
}: SessionTableProps) => {
  const [sorting, setSorting] = useState<SortingState>(dsorting)
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  })

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  )
  const [globalFilter, setGlobalFilter] = useState<string | number>('')

  const getPages = () => {
    const totalcount = table.getPageCount()
    let currentPage = pageIndex

    // If we can fit all pages in our limit
    if (totalcount < 9) {
      // @ts-ignore
      return [...Array(totalcount).keys()]
    }
    // Always print first and last page
    const result = Array(9)
    result[0] = 0
    result[8] = totalcount - 1
    // Precaution
    currentPage = Math.min(Math.max(0, currentPage), totalcount - 1)
    // First pages
    if (currentPage < 4) {
      for (let i = 1; i < 7; i++) {
        result[i] = i
      }
      result[7] = '...'
      return result
    }
    // Last pages
    if (currentPage >= totalcount - 5) {
      for (let i = 2; i < 8; i++) {
        result[i] = totalcount - 9 + i
      }
      result[1] = '...'
      return result
    }
    // Pages in the middle
    for (let i = 2; i < 7; i++) {
      result[i] = currentPage - 4 + i
    }
    result[1] = '...'
    result[7] = '...'
    return result
  }

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      pagination,
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  })

  return (
    <>
      <div className="flex flex-col bg-blue">
        <Panel
          header="All sessions"
          position="left"
          className="flex grow text-black"
        >
          <div className="flex w-full flex-col justify-end">
            <div className="flex flex-row items-end gap-x-2">
              <div className="flex w-[72px]">
                <MyListbox
                  value={pageSize}
                  onChange={(e: unknown) =>
                    setPagination({
                      pageSize: e as number,
                      pageIndex: 0,
                    })
                  }
                  options={[10, 20, 50, 100]}
                  //@ts-ignore
                  toString={(e) => e.toString()}
                  label="label"
                />
              </div>
              <p className="text-white">per page</p>
            </div>
          </div>
          <div className="flex w-full max-w-[360px] flex-col">
            <p className="text-white-off">Search</p>
            <DebouncedInput value={globalFilter} onChange={setGlobalFilter} />
          </div>
        </Panel>
        <div className="p-4">
          <table className="w-full table-auto divide-y">
            <thead className="h-12 bg-blue-light">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: clsx(
                              header.column.getCanSort()
                                ? 'cursor-pointer select-none text-base font-normal'
                                : '',
                              'flex items-center px-4 '
                            ),
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <MdExpandLess />,
                            desc: <MdExpandMore />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="divide-y">
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className="h-12"
                  onClick={() => (onClick ? onClick(row.original) : null)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} className="px-4">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex justify-between">
            <div className="flex items-center justify-between text-white-off">
              {pageSize * pageIndex + 1} - {pageSize * (pageIndex + 1)} of{' '}
              {data.length}
            </div>
            <div className="flex items-center justify-end gap-x-2">
              <Button
                rounded={true}
                icon={<MdChevronLeft />}
                inverted={true}
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              />
              {getPages().map((page, idx) => (
                <div key={`${page}${idx}`}>
                  {Number.isInteger(page) ? (
                    <Button
                      // className={clsx(page === pageIndex ? 'bg-red' : 'bg-blue')}
                      rounded={true}
                      inverted={page !== pageIndex}
                      key={page}
                      onClick={() => table.setPageIndex(page)}
                    >
                      {page + 1}
                    </Button>
                  ) : (
                    <div>{page}</div>
                  )}
                </div>
              ))}
              <Button
                rounded={true}
                icon={<MdChevronRight />}
                inverted={true}
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <InputField
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  )
}
