import { useParams } from 'react-router-dom'

import type { Company, SessionWithRelations, User } from '@teamup/db'

import { Panel } from '~/components/ui/Panel'
import { trpc } from '~/lib/trpc'

interface CompanyDetailProps {
  company?: Company
  sessions?: SessionWithRelations[] | undefined
  users?: User[]
}

export const CompanyDetail = ({
  company,
  sessions,
  users,
}: CompanyDetailProps) => {
  const licenses = sessions
    ?.flatMap((session) =>
      session.teams.reduce((acc, team) => {
        if (team.licenceUsed === undefined) return acc
        if (typeof team.licenceUsed === 'number') return acc + team.licenceUsed
        if (team.licenceUsed) return acc + 1
        return acc
      }, 0)
    )
    .reduce((num, acc) => num + acc, 0)

  return (
    <Panel header={company?.name ?? 'Company'}>
      <div className={'grid grid-cols-2'}>
        <p>Session created: </p>
        <p>{sessions?.length}</p>
        <p>Licenses used: </p>
        <p>{licenses}</p>
      </div>
      <p className="text-xl mt-4">Users</p>
      {users?.map((user) => (
        <div key={user.id}>
          {user.role} - {user.email}
        </div>
      ))}
    </Panel>
  )
}

export const CompaniesDetail = () => {
  const params = useParams()

  const { data: company } = trpc.company.get.useQuery({
    where: { uuid: params.id },
  })

  const { data: users } = trpc.user.list.useQuery({
    where: {
      company: { uuid: params.id },
    },
  })

  const { data: sessions }: { data: SessionWithRelations[] | undefined } =
    trpc.session.list.useQuery({
      where: {
        company: {
          uuid: params.id,
        },
      },
      include: {
        teams: true,
      },
    })

  return (
    <>
      {company && users && sessions && (
        <CompanyDetail company={company} sessions={sessions} users={users} />
      )}
    </>
  )
}
