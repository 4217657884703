import type { Gameserver } from '@teamup/db'

import { Button } from '~/components/ui'
import { Panel } from '~/components/ui/Panel'
import { trpc } from '~/lib/trpc'

export const Gameservers = () => {
  const { data: gameservers, refetch } = trpc.gameserver.list.useQuery()

  const terminateMutation = trpc.gameserver.terminate.useMutation()

  const terminateGS = (uuid) => {
    terminateMutation.mutate(uuid)
    refetch()
  }

  return (
    <div>
      {gameservers && (
        <Panel header="Gameservers">
          {gameservers.map((gameserver: Gameserver) => (
            <div key={gameserver.uuid}>
              <div className={'border rounded'}>
                <p>UUID: {gameserver.uuid}</p>
                <p>State: {gameserver.state}</p>
                {gameserver.provider === 'AWS' && (
                  <p>
                    <Button onClick={() => terminateGS(gameserver.uuid)}>
                      Terminate
                    </Button>
                  </p>
                )}
              </div>
            </div>
          ))}
          {/*<pre>{JSON.stringify(gameservers, null, 2)}</pre>*/}
        </Panel>
      )}
    </div>
  )
}
