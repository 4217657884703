import { useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { motion } from 'framer-motion'
import { useForm } from 'react-hook-form'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { ResetPWSchema, UserPartial } from '@teamup/db'

import { Alert, Button, InputField } from '~/components/ui'
import { Panel } from '~/components/ui/Panel'
import { trpc } from '~/lib/trpc'

function ResetPasswordClient() {
  const [valid, setValid] = useState(false)
  const [params] = useSearchParams()
  const navigate = useNavigate()

  const id = params.get('id')
  const token = params.get('token')

  const [user, setUser] = useState<UserPartial>({})
  const checkToken = trpc.auth.check_token.useMutation()
  const setNewPassword = trpc.auth.set_password.useMutation()

  useEffect(() => {
    if (id && token) {
      checkToken.mutateAsync({ id, token }).then((res) => {
        setUser(res.user)
        setValid(true)
      })
    }
  }, [id, token])

  const [visibility, setVisibility] = useState<boolean>(false)

  const formOptions = { resolver: zodResolver(ResetPWSchema) }
  const iconProps = 'h-10 w-10'
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions)

  const [info, setInfo] = useState<string | undefined>()

  const onSubmit = (data: any) => {
    setNewPassword.mutateAsync({ ...data, token, uuid: user.uuid }).then(() => {
      setInfo('Password reset successfully, redirecting to login page')
      setTimeout(() => {
        navigate('/login')
      }, 2000)
    })
  }

  return (
    <div className="flex items-center">
      {valid && (
        <Panel header="Set new password" className="mx-auto w-[468px]">
          <p className="mb-4">
            Setting a new password for <strong>{user.email}</strong>
          </p>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-y-2"
          >
            <div>
              <label htmlFor="password">New password</label>
              <div className="relative">
                <InputField
                  type={visibility ? 'text' : 'password'}
                  id="password"
                  {...register('password')}
                  autoComplete="password"
                  placeholder="Password"
                />
                <p
                  onClick={() => {
                    setVisibility(!visibility)
                  }}
                  className="absolute inset-y-0 right-0 mr-2 flex h-10 w-6 cursor-pointer items-center text-black"
                >
                  {visibility ? (
                    <MdVisibility className={iconProps} />
                  ) : (
                    <MdVisibilityOff className={iconProps} />
                  )}
                </p>
              </div>
            </div>
            <div>
              <label htmlFor="password2">Confirm password</label>
              <div className="relative">
                <InputField
                  type={visibility ? 'text' : 'password'}
                  id="password2"
                  {...register('password2')}
                  autoComplete="password"
                  placeholder="Password"
                />
                <p
                  onClick={() => {
                    setVisibility(!visibility)
                  }}
                  className="absolute inset-y-0 right-0 mr-2 flex h-10 w-6 cursor-pointer items-center text-black"
                >
                  {visibility ? (
                    <MdVisibility className={iconProps} />
                  ) : (
                    <MdVisibilityOff className={iconProps} />
                  )}
                </p>
              </div>
            </div>

            {info && <Alert type="info">{info}</Alert>}

            {Object.keys(errors).length > 0 && (
              <motion.div
                initial={{ scale: 0.5 }}
                animate={{ scale: 1 }}
                transition={{
                  duration: 0.2,
                }}
              >
                <Alert type="error">
                  {Object.entries(errors).map(([key, error]) => (
                    <div className="first-letter:uppercase" key={key}>
                      {error?.message?.toString()}
                    </div>
                  ))}
                </Alert>
              </motion.div>
            )}
            <div className="flex flex-col items-end">
              <Button type="submit">Submit</Button>
            </div>
          </form>
        </Panel>
      )}
    </div>
  )
}

export function ResetPassWd() {
  return (
    <div className="h-screen w-screen">
      <div className="relative h-full">
        <div className="absolute bottom-8 left-8 z-10 h-10 w-40">
          <img
            src="/images/logoCMYK.svg"
            alt="Logo"
            className="-z-30 object-contain"
          />
        </div>
        <div className="flex">
          <div className="mx-auto flex flex-col items-center gap-y-16">
            <img
              src="/images/teamup/Tiny3.png"
              alt="Logo"
              width={220}
              height={220}
              className="hidden md:flex"
            />

            <div>
              <ResetPasswordClient />
            </div>

            <img
              src="/images/teamup/Tiny2.png"
              alt="Logo"
              width={220}
              height={220}
              className="hidden md:flex"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
