import type { ReactNode } from 'react'
import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import { Panel } from '../Panel'

export const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

interface ModalProps {
  children?: ReactNode[] | ReactNode
  isShowing: boolean
  title: string
  description?: ReactNode
  onClose?: (e: MouseEvent) => void
}

export const Modal = ({ children, ...props }: ModalProps) => {
  let buttons: ReactNode[] = []

  //@ts-ignore
  if (props.children?.length) {
    buttons = children as ReactNode[]
  } else {
    buttons.push(children)
  }

  return (
    <Transition appear show={props.isShowing} as={Fragment}>
      <Dialog
        className="relative z-10"
        onClose={() => {
          return
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-6 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel>
                <Panel header={props.title} className="p-6">
                  {props.description}

                  <div className="mt-4 flex justify-end gap-x-4">{buttons}</div>
                </Panel>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
