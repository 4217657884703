import React, { lazy } from 'react'
import * as Sentry from '@sentry/react'
import { createBrowserRouter, Navigate, useParams } from 'react-router-dom'

import DefaultErrorComponent from '~/components/ErrorComponent'
import { UserProvider } from '~/context/useUser'
import {
  AdminLayout,
  Sessions as AdminSessions,
  Companies,
  CompaniesDetail,
  Gameservers,
  Users,
} from '~/routes/dashboard/admin'
import { Challenges } from '~/routes/dashboard/admin/challenges'
import { DashboardClient } from '~/routes/dashboard/dashboard'
import WikiItemPage from '~/routes/dashboard/help/help_page'
import WikiLayout from '~/routes/dashboard/help/layout'
import HelpLayout from '~/routes/dashboard/help/page'
import ProfilePage from '~/routes/dashboard/profile'
import { Sessions } from '~/routes/dashboard/sessions/sessions'
import { ResetPassWd } from '~/routes/reset-passwd'
import TestGameLayout from '~/routes/test';

const GameLayout = lazy(() => import(`~/routes/game`))
const Dashboard = lazy(() => import(`~/routes/dashboard`))
const Session = lazy(() => import('~/routes/dashboard/sessions/session'))
const LoginLayout = lazy(() => import('~/routes/login'))
const Root = lazy(() => import('~/routes/root'))

function AdminTableGroup() {
  const { category } = useParams()

  switch (category) {
    case 'challenges':
      return <Challenges />
    case 'users':
      return <Users />
    case 'gameservers':
      return <Gameservers />
    case 'sessions':
      return <AdminSessions />
    case 'companies':
      return <Companies />
    default:
      return <div>Unknown category</div>
  }
}

function AdminTableGroupDetail() {
  const { category } = useParams()

  switch (category) {
    case 'companies':
      return <CompaniesDetail />
    default:
      return <div>Unknown category</div>
  }
}

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV7(createBrowserRouter)

export const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: (
      <UserProvider>
        <Root />
      </UserProvider>
    ),
    errorElement: <DefaultErrorComponent />,
    children: [
      {
        path: '',
        element: <Navigate to="/game" replace />,
      },
      {
        path: 'dashboard',
        element: <Dashboard />,
        children: [
          {
            path: '',
            element: <DashboardClient />,
            children: [],
          },

          {
            path: 'profile',
            element: <ProfilePage />,
            children: [],
          },
          {
            path: 'sessions',
            element: <Sessions />,
            children: [],
          },
          {
            path: 'sessions/:id',
            element: <Session />,
          },
          {
            path: 'help',
            element: <WikiLayout />,
            children: [
              {
                path: '',
                element: <HelpLayout />,
                children: [],
              },
              {
                path: ':id',
                element: <WikiItemPage />,
                children: [],
              },
            ],
          },
          {
            path: 'admin',
            element: <AdminLayout />,
          },
          {
            path: 'admin/:category',
            element: <AdminTableGroup />,
          },
          {
            path: 'admin/:category/:id',
            element: <AdminTableGroupDetail />,
          },
        ],
      },
      {
        path: 'game',
        element: <GameLayout />,
      },
      {
        path: 'test',
        element: <TestGameLayout />,
      },
      {
        path: 'login',
        element: <LoginLayout />,
      },
      { path: 'reset-password', element: <ResetPassWd /> },
    ],
  },
])
