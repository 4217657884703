import type { ClassValue } from 'clsx'
import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms))

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const getMasterAPI = () => {
  const hostname = window.location.hostname
  if (window.location.hostname.endsWith('.nl')) {
    return `https://api.${hostname}`
  } else {
    return 'http://localhost:3000'
  }
}

export const getMasterAPIWS = () => {
  const hostname = window.location.hostname
  if (window.location.protocol.startsWith('https')) {
    return `wss://api.${hostname}/trpc`
  } else {
    return 'ws://localhost:3000/trpc'
  }
}
