import type { Challenge } from '@teamup/db'

import { Dashboard } from '~/components/dashboard/Dashboard'
import { trpc } from '~/lib/trpc'

export const DashboardClient = () => {
  const { data: challenges } = trpc.challenge.list.useQuery({
    orderBy: { name: 'asc' },
  })

  const { data: activeSessions } = trpc.session.list.useQuery({
    where: {
      OR: [{ status: 'REQUESTED' }, { status: 'RUNNING' }],
    },
    include: {
      teams: true,
    },
  })

  const { data: upcomingSessions } = trpc.session.list.useQuery({
    where: {
      status: 'SCHEDULED',
    },
    include: {
      teams: true,
    },
  })

  if (!challenges || !activeSessions || !upcomingSessions)
    return <div>Loading...</div>

  return (
    <Dashboard
      challenges={challenges as Challenge[]}
      activeSessions={activeSessions}
      upcomingSessions={upcomingSessions}
    />
  )
}
