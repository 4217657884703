import type { ReactNode } from 'react'
import React from 'react'
import opentelemetry from '@opentelemetry/api'
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'
import { registerInstrumentations } from '@opentelemetry/instrumentation'
import { B3Propagator } from '@opentelemetry/propagator-b3'
import { Resource } from '@opentelemetry/resources'
import {
  BatchSpanProcessor,
  WebTracerProvider,
} from '@opentelemetry/sdk-trace-web'
import {
  ATTR_SERVICE_NAME,
  ATTR_SERVICE_VERSION,
} from '@opentelemetry/semantic-conventions'
import * as Sentry from '@sentry/react'
import { parseURL } from 'whatwg-url'

const traceUrl =
  import.meta.env.VITE_ENVIRONMENT === 'local'
    ? 'http://localhost:4318/v1/traces'
    : 'https://otel.schuur.thebarngames.nl/v1/traces'

Sentry.init({
  dsn: 'https://8bcb1b6cd9594583b8d072b76fd714eb@o4504927283249152.ingest.us.sentry.io/4504927293472768',
  maxBreadcrumbs: 50,
  integrations: [],
  tracesSampleRate: 0,
  // debug: true,
  enabled: true,
  release: 'develop',
  // enabled: import.meta.env.VITE_ENVIRONMENT !== 'local',
  environment: import.meta.env.VITE_ENVIRONMENT,
})

const provider = new WebTracerProvider({
  resource: new Resource({
    [ATTR_SERVICE_NAME]: 'teamup-frontend',
    ['deployment.environment.name']: import.meta.env.VITE_ENVIRONMENT,
    [ATTR_SERVICE_VERSION]: import.meta.env.VITE_VERSION,
  }),

  spanProcessors: [
    new BatchSpanProcessor(
      new OTLPTraceExporter({
        url: traceUrl,
        headers: {
          'connect-src': traceUrl,
          // 'Content-Type': 'application/json',
        },
      })
    ),
  ],
})

provider.register({
  propagator: new B3Propagator(),
})

const trpcRegex = new RegExp('(/trpc/)')

// Registering instrumentations
registerInstrumentations({
  instrumentations: [
    getWebAutoInstrumentations({
      // '@opentelemetry/instrumentation-xml-http-request': {
      //     ignoreUrls: [new RegExp('(socket.io)+')],
      //     propagateTraceHeaderCorsUrls: [new RegExp(getMasterAPI())],
      //     clearTimingResources: true,
      // },
      '@opentelemetry/instrumentation-fetch': {
        ignoreNetworkEvents: true,
        ignoreUrls: [new RegExp('(socket.io)+')],
        propagateTraceHeaderCorsUrls: [
          new RegExp('localhost'),
          new RegExp('tstest.thebarngames.nl'),
          new RegExp('teamup.thebarngames.nl'),
        ],
        clearTimingResources: true,
        applyCustomAttributesOnSpan(span, req, res) {
          //if (res.url.startsWith('http://localhost:3003/trpc')) {
          if (res instanceof Response) {
            if (trpcRegex.test(res.url)) {
              const hostinfo = parseURL(res.url)
              // @ts-ignore
              span.updateName(hostinfo.path.join('/'))
            }
          }
        },
      },
      '@opentelemetry/instrumentation-user-interaction': {
        enabled: false,
      },
      '@opentelemetry/instrumentation-document-load': {
        enabled: false,
      },
    }),
  ],
  tracerProvider: provider,
})

export const tracer = opentelemetry.trace.getTracer('teamup-frontend')

export default function TraceProvider({ children }: { children: ReactNode }) {
  return <>{children}</>
}
