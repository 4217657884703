import type { ComponentPropsWithoutRef } from 'react'
import { forwardRef, Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { MdCheck, MdExpandLess, MdExpandMore } from 'react-icons/md'

import { InputField } from './InputField'

interface ListboxProps extends ComponentPropsWithoutRef<'div'> {
  label: string
  value: unknown
  onChange: (value: unknown) => void
  options: unknown[]
  toString?: (obj) => string
}

export const MyListbox = forwardRef<HTMLDivElement, ListboxProps>(
  (
    {
      value,
      onChange,
      options,
      toString = (value) => value.toString(),
    }: ListboxProps,
    ref
  ) => {
    return (
      <div ref={ref}>
        <Listbox value={value} onChange={onChange} ref={ref}>
          {({ open }) => (
            <div className="relative mt-1">
              <Listbox.Button className="w-full capitalize">
                <InputField
                  type="text"
                  icon={open ? <MdExpandLess /> : <MdExpandMore />}
                  value={toString(value)}
                  readOnly
                />
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-20 mt-1 w-full rounded border border-gray-light bg-white shadow-lg">
                  {options.map((option, personIdx) => (
                    <Listbox.Option
                      key={personIdx}
                      //active = hover
                      className={clsx(
                        'h-10 cursor-pointer bg-white px-4 py-2 text-left text-black hover:bg-white-off active:bg-gray-light disabled:bg-white-off/50'
                      )}
                      value={option}
                    >
                      {({ selected }) => (
                        <p
                          className={clsx(
                            'flex gap-x-1',
                            selected ? 'text-blue' : ''
                          )}
                        >
                          {selected && (
                            <MdCheck className="size-6 items-center justify-items-center" />
                          )}
                          <div className="capitalize">{toString(option)}</div>
                        </p>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          )}
        </Listbox>
      </div>
    )
  }
)
