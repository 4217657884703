import type { ReactNode } from 'react'
import type { Socket } from 'socket.io-client'
import { createContext, useContext, useMemo, useState } from 'react'
import { io } from 'socket.io-client'
import { v4 as uuidv4 } from 'uuid'

import type { TeamStatusType } from '@teamup/db'

export type GameConfig = {
  team: string
  slot: number
  uuid: string
  status: TeamStatusType
}

interface SocketContextType {
  connect: (ns: string, config: GameConfig | null) => void
  sendWSMessage: (
    ev: string,
    data: unknown,
    callback?: (callback: unknown) => void
  ) => void
  state: unknown
  socket: Socket | undefined
  sessionID: string
}

export function WebsocketsProvider({ children }: { children: ReactNode }) {
  const [socket, setSocket] = useState<Socket>()
  const [state, setState] = useState<boolean>(false)

  const [sessionID] = useState(uuidv4())

  const sendWSMessage = async (
    ev: string,
    data: unknown,
    db?: (callback: unknown) => void
  ) => {
    socket?.emit(ev, data, (callback: unknown) => {
      if (db) db(callback)
    })
  }

  const connect = async (ns: string) => {
    const path = ns === 'facilitator' ? '/facilitator' : '/player'

    const apiUrl = location.hostname.endsWith('.nl')
      ? location.protocol + '//api.' + location.hostname
      : import.meta.env.VITE_API_URL

    const sock = io(apiUrl + path, { auth: { uuid: sessionID } })

    sock.on('connect', () => {
      setState(true)
    })

    sock.on('disconnect', () => {
      console.warn('Disconnected from master server!')
      setState(false)
      //window.location.reload();
    })

    setSocket(sock)
  }

  const memo = useMemo(
    () => ({
      socket,
      state,
      sendWSMessage,
      connect,
      sessionID,
    }),
    [state, sessionID, socket]
  )

  return (
    <WebsocketContext.Provider value={memo}>
      {children}
    </WebsocketContext.Provider>
  )
}

export const WebsocketContext = createContext<SocketContextType>(
  {} as SocketContextType
)

export default function useWebsockets() {
  return useContext(WebsocketContext)
}
