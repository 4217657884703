import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { motion } from 'framer-motion'

type LoadingPanelProps = {
  isOpen: boolean
  getProgress: () => string
  text: string
}

export const LoadingPanel = ({
  isOpen,
  getProgress,
  text,
}: LoadingPanelProps) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          return
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="flex h-[272px] w-[448px] flex-col bg-blue">
                <div className="mt-[96px] flex flex-col items-center">
                  <img src="/logonoline.svg" width={276} height={64} alt="" />
                </div>

                <div className="flex items-center justify-center">
                  <div className="h-2 w-full bg-blue-light">
                    <motion.div
                      className="h-2 bg-white-off"
                      initial={{ width: 0 }}
                      animate={{
                        width: getProgress(),
                        transition: { duration: 0.5 },
                      }}
                    />
                  </div>
                </div>

                <div className="grow"></div>

                <div className="mb-6">
                  <p>{text}</p>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default LoadingPanel
