import { Fragment, useEffect, useRef, useState } from 'react'
import { Unity, useUnityContext } from 'react-unity-webgl'

import { PlayerCommands } from '@teamup/db'

import type { GameConfig } from '~/context/useWebsockets'
import LoadingPanel from '~/components/game/Loading'
import useWebsockets from '../../context/useWebsockets'

type UnityGameProps = {
  token: string
}

export const getHostLocation = () => {
  if (import.meta.env.VITE_ENVIRONMENT === 'local') {
    return `/unity/`
  } else {
    const env = import.meta.env.VITE_ENVIRONMENT || 'develop'
    return `https://teamup2.s3.eu-central-1.amazonaws.com/${env}/webgl/`
  }
}

export const getBuildPath = () => {
  if (import.meta.env.VITE_ENVIRONMENT === 'local') {
    return getHostLocation() + 'Build/'
  } else {
    return getHostLocation()
  }
}

export const getStreamingAssets = () => {
  if (import.meta.env.VITE_ENVIRONMENT === 'local') {
    return getHostLocation() + 'StreamingAssets'
  } else {
    return getHostLocation() + 'assets'
  }
}

export const getBuildExt = () => {
  return import.meta.env.VITE_ENVIRONMENT === 'production' ? '.br' : ''
}

export const UnityGame = ({ token }: UnityGameProps) => {
  const [socketConnected, setSocketConnected] = useState(false)
  const [loadingText, setLoadingText] = useState('Connecting to server...')
  const [isOpen, setIsOpen] = useState(true)

  const { connect, sendWSMessage, socket, sessionID: uuid } = useWebsockets()
  const config = useRef<GameConfig>({
    slot: -1,
    team: '',
    uuid: '',
    status: 'SCHEDULED',
  })

  const {
    unityProvider,
    loadingProgression,
    initialisationError: error,
    isLoaded,
    addEventListener,
    sendMessage: toUnity,
  } = useUnityContext({
    loaderUrl: getBuildPath() + `webgl.loader.js`,
    dataUrl: getBuildPath() + 'webgl.data' + getBuildExt(),
    frameworkUrl: getBuildPath() + 'webgl.framework.js' + getBuildExt(),
    codeUrl: getBuildPath() + 'webgl.wasm' + getBuildExt(),
    companyName: 'The Barn',
    productName: 'TeamUp',
    productVersion: import.meta.env.VITE_VERSION,
    streamingAssetsUrl: getStreamingAssets(),
  })

  useEffect(() => {
    if (error) {
      socket?.disconnect()
      console.error(error)
    }
  }, [error])

  function kick() {
    console.warn('I got KICKED!')
    // unloadUnity();
    // socket.disconnect();
    window.location.reload()
  }

  useEffect(() => {
    if (socket && isLoaded) {
      socket.onAny((ev, msg) => {
        switch (ev) {
          case 'start-team':
          case 'pause':
          case 'resume':
            toUnity(
              'Logger',
              'FromReact',
              JSON.stringify({ id: ev, message: msg })
            )
            break
          default:
            console.warn('Unhandled event:', ev)
        }
      })

      addEventListener('RegisterUser', () => {
        // Fetch the latest status of the team, and push that config.
        sendWSMessage(
          PlayerCommands.READY,
          { ...config.current, uuid },
          (resp: any) => {
            config.current.status = resp.status
            SendMessageToUnity({
              Id: 'config',
              Message: {
                Team: config.current.team,
                Slot: config.current.slot,
                Status: config.current.status,
              },
            })
          }
        )
      })
    }
  }, [socket, isLoaded])

  useEffect(() => {
    setLoadingText('Connecting to TeamUp...')
    connect('/player', null)
  }, [])

  const SendMessageToUnity = (data: unknown) => {
    toUnity('Logger', 'FromReact', JSON.stringify(data))
  }

  useEffect(() => {
    if (socketConnected) {
      setLoadingText('Connecting to team...')

      if (token.length === 4) {
        socket?.emit(
          PlayerCommands.GET_CONNECTION_INFO,
          { token, uuid },
          (conf: any) => {
            if (conf.slot === -1) {
              setLoadingText('No spot available')
            } else {
              config.current = conf
              setLoadingText('Loading...')
              sendWSMessage(PlayerCommands.CLAIM_SPOT, {
                team: conf.team,
                slot: conf.slot,
                uuid: uuid,
              })
            }
          }
        )
      } else if (token.length === 8) {
        socket?.emit(
          PlayerCommands.GET_SPECTATOR_INFO,
          { token },
          (conf: GameConfig) => {
            config.current = conf
            setLoadingText('Loading...')
          }
        )
      }
    }
  }, [socketConnected, config])

  // Setup websocket connection
  useEffect(() => {
    if (socket) {
      if (socket.connected) setSocketConnected(true)
      socket.on('connect', () => {
        setSocketConnected(true)
      })

      socket.onAny((ev, msg) => {
        switch (ev) {
          case 'kick-player':
            if (msg.slot === config.current.slot) kick()
            break
        }
      })
    }
    return () => {
      if (socket) {
        socket.close()
      }
    }
  }, [socket, config])

  const getProgress = () => {
    return loadingProgression * 100 + '%'
  }

  useEffect(() => {
    if (isLoaded) {
      // SendMessageToUnity({ id: 'webgl_loaded', message: config.current });
      setIsOpen(false)
      addEventListener('RegisterUser', () => {
        if (config.current.slot > 0)
          sendWSMessage(PlayerCommands.READY, {
            team: config.current.team,
            slot: config.current.slot,
            uuid,
          })
        toUnity(
          'Logger',
          'FromReact',
          JSON.stringify({ id: 'config', message: config.current })
        )
      })
    }
    return () => {
      return
    }
  }, [isLoaded])

  return (
    <Fragment>
      <LoadingPanel
        isOpen={isOpen}
        getProgress={() => getProgress()}
        text={loadingText}
      />

      {config.current.slot > -1 && (
        <div className="h-screen w-screen">
          {config.current.slot > -1 && (
            <Unity
              devicePixelRatio={window.devicePixelRatio}
              unityProvider={unityProvider}
              style={{
                visibility: isLoaded ? 'visible' : 'hidden',
                width: '100%',
                height: '100%',
              }}
            />
          )}
        </div>
      )}
    </Fragment>
  )
}

export default UnityGame
