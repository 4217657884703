import { Controller, useFormContext } from 'react-hook-form'

import { Slider } from '~/components/ui/slider'

export const TimePicker = () => {
  const { control } = useFormContext() // retrieve all hook methods

  const getDefault = () => {
    const start = new Date()
    start.setMinutes(0, 0, 0)
    const end = new Date()
    if (start.getHours() > 14) {
      end.setHours(23, 0, 0, 0)
    } else {
      end.setHours(18, 0, 0, 0)
    }

    return { start, end }
  }

  return (
    <div className="w-full">
      <label htmlFor="timing" className="block text-white-off">
        Time
      </label>
      <div className="mt-1 sm:col-span-2 sm:mt-0">
        <Controller
          control={control}
          name="timing"
          defaultValue={getDefault()}
          render={({ field: { onChange, value } }) => (
            <div className="flex h-9 w-full items-center">
              <Slider
                min={1}
                max={23}
                minStepsBetweenThumbs={2}
                className={'flex w-full items-center'}
                value={[value.start.getHours(), value.end.getHours()]}
                onValueChange={(vale) => {
                  const newVal = value
                  newVal['start'].setHours(vale[0])
                  newVal['end'].setHours(vale[1])
                  onChange(newVal)
                }}
              />
            </div>
          )}
        />
      </div>
    </div>
  )
}
