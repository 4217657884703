import type { ReactNode } from 'react'
import React, { useState } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import {
  httpLink,
  loggerLink,
  splitLink,
  unstable_httpSubscriptionLink,
} from '@trpc/client'
import { EventSourcePolyfill } from 'event-source-polyfill'
import superjson from 'superjson'
import { useLocalStorage } from 'usehooks-ts'

import { AuthProvider } from '~/context/useAuth'
import { trpc } from '~/lib/trpc'
import { getMasterAPI } from '~/lib/utils'

interface TRPCProviderProps {
  children?: ReactNode
}

export const getAuthCookie = () => {
  return localStorage.getItem('token') ?? ''
}

export const setAuthCookie = (token: string) => {
  localStorage.setItem('token', token)
}

export const removeAuthCookie = () => {
  localStorage.removeItem('token')
}

export function TRPCProvider({ children }: TRPCProviderProps) {
  const [queryClient] = useState(() => new QueryClient())
  const [client] = useState(() =>
    trpc.createClient({
      links: [
        // loggerLink(),
        splitLink({
          // uses the httpSubscriptionLink for subscriptions
          condition: (op) => op.type === 'subscription',
          true: unstable_httpSubscriptionLink({
            transformer: superjson,
            url: getMasterAPI() + '/trpc',
            EventSource: EventSourcePolyfill,
            eventSourceOptions: async () => {
              return {
                headers: {
                  'x-access-token': getAuthCookie(),
                },
                withCredentials: true,
              }
            },
          }),
          false: httpLink({
            transformer: superjson,
            url: getMasterAPI() + '/trpc',
            async headers() {
              return {
                'x-access-token': getAuthCookie(),
              }
            },
          }),
        }),
      ],
    })
  )

  return (
    <trpc.Provider client={client} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>{children}</AuthProvider>
      </QueryClientProvider>
    </trpc.Provider>
  )
}
