import { z } from 'zod';
import { Prisma } from '@prisma/client';
import { PlayerStatus } from '../enums'

/////////////////////////////////////////
// HELPER FUNCTIONS
/////////////////////////////////////////

// JSON
//------------------------------------------------------

export type NullableJsonInput = Prisma.JsonValue | null | 'JsonNull' | 'DbNull' | Prisma.NullTypes.DbNull | Prisma.NullTypes.JsonNull;

export const transformJsonNull = (v?: NullableJsonInput) => {
  if (!v || v === 'DbNull') return Prisma.DbNull;
  if (v === 'JsonNull') return Prisma.JsonNull;
  return v;
};

export const JsonValueSchema: z.ZodType<Prisma.JsonValue> = z.lazy(() =>
  z.union([
    z.string(),
    z.number(),
    z.boolean(),
    z.literal(null),
    z.record(z.lazy(() => JsonValueSchema.optional())),
    z.array(z.lazy(() => JsonValueSchema)),
  ])
);

export type JsonValueType = z.infer<typeof JsonValueSchema>;

export const NullableJsonValue = z
  .union([JsonValueSchema, z.literal('DbNull'), z.literal('JsonNull')])
  .nullable()
  .transform((v) => transformJsonNull(v));

export type NullableJsonValueType = z.infer<typeof NullableJsonValue>;

export const InputJsonValueSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    z.string(),
    z.number(),
    z.boolean(),
    z.object({ toJSON: z.function(z.tuple([]), z.any()) }),
    z.record(z.lazy(() => z.union([InputJsonValueSchema, z.literal(null)]))),
    z.array(z.lazy(() => z.union([InputJsonValueSchema, z.literal(null)]))),
  ])
);

export type InputJsonValueType = z.infer<typeof InputJsonValueSchema>;


/////////////////////////////////////////
// ENUMS
/////////////////////////////////////////

export const TransactionIsolationLevelSchema = z.enum(['ReadUncommitted','ReadCommitted','RepeatableRead','Serializable']);

export const PositionScalarFieldEnumSchema = z.enum(['id','locations','teamId']);

export const EventScalarFieldEnumSchema = z.enum(['id','LogType','Parameter','Player','TriggeredObject','timestamp','teamId']);

export const ChallengeScalarFieldEnumSchema = z.enum(['id','display','image','name','order','createdAt','updatedAt']);

export const CompanyScalarFieldEnumSchema = z.enum(['id','uuid','name','createdAt','updatedAt']);

export const GameserverScalarFieldEnumSchema = z.enum(['id','uuid','capacity','hostname','provider','state','instanceId','socket','createdAt','updatedAt']);

export const PasswordresetsScalarFieldEnumSchema = z.enum(['id','userId','uuid','token']);

export const SessionScalarFieldEnumSchema = z.enum(['id','uuid','challenges','companyId','name','status','time_limit','start','end','createdAt','updatedAt']);

export const TeamScalarFieldEnumSchema = z.enum(['id','uuid','connected','currentChallenge','challengeStatus','licenceUsed','max_players','players','status','token','spectoken','gameserversId','sessionsId','createdAt','updatedAt']);

export const UserScalarFieldEnumSchema = z.enum(['id','uuid','companyId','email','hash','name','role','salt','createdAt','updatedAt']);

export const SortOrderSchema = z.enum(['asc','desc']);

export const JsonNullValueInputSchema = z.enum(['JsonNull',]).transform((value) => (value === 'JsonNull' ? Prisma.JsonNull : value));

export const JsonNullValueFilterSchema = z.enum(['DbNull','JsonNull','AnyNull',]).transform((value) => value === 'JsonNull' ? Prisma.JsonNull : value === 'DbNull' ? Prisma.JsonNull : value === 'AnyNull' ? Prisma.AnyNull : value);

export const QueryModeSchema = z.enum(['default','insensitive']);

export const NullsOrderSchema = z.enum(['first','last']);

export const GameserverProviderSchema = z.enum(['LOCAL','AWS']);

export type GameserverProviderType = `${z.infer<typeof GameserverProviderSchema>}`

export const InstanceStateSchema = z.enum(['PENDING','ONLINE','OFFLINE','SHUTTING_DOWN','TERMINATED']);

export type InstanceStateType = `${z.infer<typeof InstanceStateSchema>}`

export const ChallengeStatusSchema = z.enum(['LOADING','WAITINGFORPLAYERS','STARTED','COMPLETED']);

export type ChallengeStatusType = `${z.infer<typeof ChallengeStatusSchema>}`

export const SessionStatusSchema = z.enum(['SCHEDULED','REQUESTED','RUNNING','CLOSED']);

export type SessionStatusType = `${z.infer<typeof SessionStatusSchema>}`

export const TeamStatusSchema = z.enum(['SCHEDULED','WAITING','PLAYING','PAUSED','FINISHED','REQUESTED','COMPLETED']);

export type TeamStatusType = `${z.infer<typeof TeamStatusSchema>}`

export const RoleSchema = z.enum(['USER','ADMIN','TRAINER']);

export type RoleType = `${z.infer<typeof RoleSchema>}`

/////////////////////////////////////////
// MODELS
/////////////////////////////////////////

/////////////////////////////////////////
// POSITION SCHEMA
/////////////////////////////////////////

export const PositionSchema = z.object({
  id: z.number().int(),
  locations: z.object({slot: z.number(), location: z.number().array().length(3)}).array(),
  teamId: z.number().int(),
})

export type Position = z.infer<typeof PositionSchema>

/////////////////////////////////////////
// POSITION PARTIAL SCHEMA
/////////////////////////////////////////

export const PositionPartialSchema = PositionSchema.partial()

export type PositionPartial = z.infer<typeof PositionPartialSchema>

// POSITION OPTIONAL DEFAULTS SCHEMA
//------------------------------------------------------

export const PositionOptionalDefaultsSchema = PositionSchema.merge(z.object({
  id: z.number().int().optional(),
}))

export type PositionOptionalDefaults = z.infer<typeof PositionOptionalDefaultsSchema>

// POSITION RELATION SCHEMA
//------------------------------------------------------

export type PositionRelations = {
  team: TeamWithRelations;
};

export type PositionWithRelations = z.infer<typeof PositionSchema> & PositionRelations

export const PositionWithRelationsSchema: z.ZodType<PositionWithRelations> = PositionSchema.merge(z.object({
  team: z.lazy(() => TeamWithRelationsSchema),
}))

// POSITION OPTIONAL DEFAULTS RELATION SCHEMA
//------------------------------------------------------

export type PositionOptionalDefaultsRelations = {
  team: TeamOptionalDefaultsWithRelations;
};

export type PositionOptionalDefaultsWithRelations = z.infer<typeof PositionOptionalDefaultsSchema> & PositionOptionalDefaultsRelations

export const PositionOptionalDefaultsWithRelationsSchema: z.ZodType<PositionOptionalDefaultsWithRelations> = PositionOptionalDefaultsSchema.merge(z.object({
  team: z.lazy(() => TeamOptionalDefaultsWithRelationsSchema),
}))

// POSITION PARTIAL RELATION SCHEMA
//------------------------------------------------------

export type PositionPartialRelations = {
  team?: TeamPartialWithRelations;
};

export type PositionPartialWithRelations = z.infer<typeof PositionPartialSchema> & PositionPartialRelations

export const PositionPartialWithRelationsSchema: z.ZodType<PositionPartialWithRelations> = PositionPartialSchema.merge(z.object({
  team: z.lazy(() => TeamPartialWithRelationsSchema),
})).partial()

export type PositionOptionalDefaultsWithPartialRelations = z.infer<typeof PositionOptionalDefaultsSchema> & PositionPartialRelations

export const PositionOptionalDefaultsWithPartialRelationsSchema: z.ZodType<PositionOptionalDefaultsWithPartialRelations> = PositionOptionalDefaultsSchema.merge(z.object({
  team: z.lazy(() => TeamPartialWithRelationsSchema),
}).partial())

export type PositionWithPartialRelations = z.infer<typeof PositionSchema> & PositionPartialRelations

export const PositionWithPartialRelationsSchema: z.ZodType<PositionWithPartialRelations> = PositionSchema.merge(z.object({
  team: z.lazy(() => TeamPartialWithRelationsSchema),
}).partial())

/////////////////////////////////////////
// EVENT SCHEMA
/////////////////////////////////////////

export const EventSchema = z.object({
  id: z.number().int(),
  LogType: z.string(),
  Parameter: z.string(),
  Player: z.number().int(),
  TriggeredObject: z.string(),
  timestamp: z.coerce.date(),
  teamId: z.number().int(),
})

export type Event = z.infer<typeof EventSchema>

/////////////////////////////////////////
// EVENT PARTIAL SCHEMA
/////////////////////////////////////////

export const EventPartialSchema = EventSchema.partial()

export type EventPartial = z.infer<typeof EventPartialSchema>

// EVENT OPTIONAL DEFAULTS SCHEMA
//------------------------------------------------------

export const EventOptionalDefaultsSchema = EventSchema.merge(z.object({
  id: z.number().int().optional(),
}))

export type EventOptionalDefaults = z.infer<typeof EventOptionalDefaultsSchema>

// EVENT RELATION SCHEMA
//------------------------------------------------------

export type EventRelations = {
  team: TeamWithRelations;
};

export type EventWithRelations = z.infer<typeof EventSchema> & EventRelations

export const EventWithRelationsSchema: z.ZodType<EventWithRelations> = EventSchema.merge(z.object({
  team: z.lazy(() => TeamWithRelationsSchema),
}))

// EVENT OPTIONAL DEFAULTS RELATION SCHEMA
//------------------------------------------------------

export type EventOptionalDefaultsRelations = {
  team: TeamOptionalDefaultsWithRelations;
};

export type EventOptionalDefaultsWithRelations = z.infer<typeof EventOptionalDefaultsSchema> & EventOptionalDefaultsRelations

export const EventOptionalDefaultsWithRelationsSchema: z.ZodType<EventOptionalDefaultsWithRelations> = EventOptionalDefaultsSchema.merge(z.object({
  team: z.lazy(() => TeamOptionalDefaultsWithRelationsSchema),
}))

// EVENT PARTIAL RELATION SCHEMA
//------------------------------------------------------

export type EventPartialRelations = {
  team?: TeamPartialWithRelations;
};

export type EventPartialWithRelations = z.infer<typeof EventPartialSchema> & EventPartialRelations

export const EventPartialWithRelationsSchema: z.ZodType<EventPartialWithRelations> = EventPartialSchema.merge(z.object({
  team: z.lazy(() => TeamPartialWithRelationsSchema),
})).partial()

export type EventOptionalDefaultsWithPartialRelations = z.infer<typeof EventOptionalDefaultsSchema> & EventPartialRelations

export const EventOptionalDefaultsWithPartialRelationsSchema: z.ZodType<EventOptionalDefaultsWithPartialRelations> = EventOptionalDefaultsSchema.merge(z.object({
  team: z.lazy(() => TeamPartialWithRelationsSchema),
}).partial())

export type EventWithPartialRelations = z.infer<typeof EventSchema> & EventPartialRelations

export const EventWithPartialRelationsSchema: z.ZodType<EventWithPartialRelations> = EventSchema.merge(z.object({
  team: z.lazy(() => TeamPartialWithRelationsSchema),
}).partial())

/////////////////////////////////////////
// CHALLENGE SCHEMA
/////////////////////////////////////////

export const ChallengeSchema = z.object({
  id: z.number().int(),
  display: z.string(),
  image: z.string(),
  name: z.string(),
  order: z.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Challenge = z.infer<typeof ChallengeSchema>

/////////////////////////////////////////
// CHALLENGE PARTIAL SCHEMA
/////////////////////////////////////////

export const ChallengePartialSchema = ChallengeSchema.partial()

export type ChallengePartial = z.infer<typeof ChallengePartialSchema>

// CHALLENGE OPTIONAL DEFAULTS SCHEMA
//------------------------------------------------------

export const ChallengeOptionalDefaultsSchema = ChallengeSchema.merge(z.object({
  id: z.number().int().optional(),
  order: z.number().int().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
}))

export type ChallengeOptionalDefaults = z.infer<typeof ChallengeOptionalDefaultsSchema>

/////////////////////////////////////////
// COMPANY SCHEMA
/////////////////////////////////////////

export const CompanySchema = z.object({
  id: z.number().int(),
  uuid: z.string(),
  name: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Company = z.infer<typeof CompanySchema>

/////////////////////////////////////////
// COMPANY PARTIAL SCHEMA
/////////////////////////////////////////

export const CompanyPartialSchema = CompanySchema.partial()

export type CompanyPartial = z.infer<typeof CompanyPartialSchema>

// COMPANY OPTIONAL DEFAULTS SCHEMA
//------------------------------------------------------

export const CompanyOptionalDefaultsSchema = CompanySchema.merge(z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
}))

export type CompanyOptionalDefaults = z.infer<typeof CompanyOptionalDefaultsSchema>

// COMPANY RELATION SCHEMA
//------------------------------------------------------

export type CompanyRelations = {
  users: UserWithRelations[];
  sessions: SessionWithRelations[];
};

export type CompanyWithRelations = z.infer<typeof CompanySchema> & CompanyRelations

export const CompanyWithRelationsSchema: z.ZodType<CompanyWithRelations> = CompanySchema.merge(z.object({
  users: z.lazy(() => UserWithRelationsSchema).array(),
  sessions: z.lazy(() => SessionWithRelationsSchema).array(),
}))

// COMPANY OPTIONAL DEFAULTS RELATION SCHEMA
//------------------------------------------------------

export type CompanyOptionalDefaultsRelations = {
  users: UserOptionalDefaultsWithRelations[];
  sessions: SessionOptionalDefaultsWithRelations[];
};

export type CompanyOptionalDefaultsWithRelations = z.infer<typeof CompanyOptionalDefaultsSchema> & CompanyOptionalDefaultsRelations

export const CompanyOptionalDefaultsWithRelationsSchema: z.ZodType<CompanyOptionalDefaultsWithRelations> = CompanyOptionalDefaultsSchema.merge(z.object({
  users: z.lazy(() => UserOptionalDefaultsWithRelationsSchema).array(),
  sessions: z.lazy(() => SessionOptionalDefaultsWithRelationsSchema).array(),
}))

// COMPANY PARTIAL RELATION SCHEMA
//------------------------------------------------------

export type CompanyPartialRelations = {
  users?: UserPartialWithRelations[];
  sessions?: SessionPartialWithRelations[];
};

export type CompanyPartialWithRelations = z.infer<typeof CompanyPartialSchema> & CompanyPartialRelations

export const CompanyPartialWithRelationsSchema: z.ZodType<CompanyPartialWithRelations> = CompanyPartialSchema.merge(z.object({
  users: z.lazy(() => UserPartialWithRelationsSchema).array(),
  sessions: z.lazy(() => SessionPartialWithRelationsSchema).array(),
})).partial()

export type CompanyOptionalDefaultsWithPartialRelations = z.infer<typeof CompanyOptionalDefaultsSchema> & CompanyPartialRelations

export const CompanyOptionalDefaultsWithPartialRelationsSchema: z.ZodType<CompanyOptionalDefaultsWithPartialRelations> = CompanyOptionalDefaultsSchema.merge(z.object({
  users: z.lazy(() => UserPartialWithRelationsSchema).array(),
  sessions: z.lazy(() => SessionPartialWithRelationsSchema).array(),
}).partial())

export type CompanyWithPartialRelations = z.infer<typeof CompanySchema> & CompanyPartialRelations

export const CompanyWithPartialRelationsSchema: z.ZodType<CompanyWithPartialRelations> = CompanySchema.merge(z.object({
  users: z.lazy(() => UserPartialWithRelationsSchema).array(),
  sessions: z.lazy(() => SessionPartialWithRelationsSchema).array(),
}).partial())

/////////////////////////////////////////
// GAMESERVER SCHEMA
/////////////////////////////////////////

export const GameserverSchema = z.object({
  provider: GameserverProviderSchema,
  state: InstanceStateSchema,
  id: z.number().int(),
  uuid: z.string(),
  capacity: z.number().int(),
  hostname: z.string().nullish(),
  instanceId: z.string().nullish(),
  socket: z.string().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Gameserver = z.infer<typeof GameserverSchema>

/////////////////////////////////////////
// GAMESERVER PARTIAL SCHEMA
/////////////////////////////////////////

export const GameserverPartialSchema = GameserverSchema.partial()

export type GameserverPartial = z.infer<typeof GameserverPartialSchema>

// GAMESERVER OPTIONAL DEFAULTS SCHEMA
//------------------------------------------------------

export const GameserverOptionalDefaultsSchema = GameserverSchema.merge(z.object({
  provider: GameserverProviderSchema.optional(),
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
}))

export type GameserverOptionalDefaults = z.infer<typeof GameserverOptionalDefaultsSchema>

// GAMESERVER RELATION SCHEMA
//------------------------------------------------------

export type GameserverRelations = {
  teams: TeamWithRelations[];
};

export type GameserverWithRelations = z.infer<typeof GameserverSchema> & GameserverRelations

export const GameserverWithRelationsSchema: z.ZodType<GameserverWithRelations> = GameserverSchema.merge(z.object({
  teams: z.lazy(() => TeamWithRelationsSchema).array(),
}))

// GAMESERVER OPTIONAL DEFAULTS RELATION SCHEMA
//------------------------------------------------------

export type GameserverOptionalDefaultsRelations = {
  teams: TeamOptionalDefaultsWithRelations[];
};

export type GameserverOptionalDefaultsWithRelations = z.infer<typeof GameserverOptionalDefaultsSchema> & GameserverOptionalDefaultsRelations

export const GameserverOptionalDefaultsWithRelationsSchema: z.ZodType<GameserverOptionalDefaultsWithRelations> = GameserverOptionalDefaultsSchema.merge(z.object({
  teams: z.lazy(() => TeamOptionalDefaultsWithRelationsSchema).array(),
}))

// GAMESERVER PARTIAL RELATION SCHEMA
//------------------------------------------------------

export type GameserverPartialRelations = {
  teams?: TeamPartialWithRelations[];
};

export type GameserverPartialWithRelations = z.infer<typeof GameserverPartialSchema> & GameserverPartialRelations

export const GameserverPartialWithRelationsSchema: z.ZodType<GameserverPartialWithRelations> = GameserverPartialSchema.merge(z.object({
  teams: z.lazy(() => TeamPartialWithRelationsSchema).array(),
})).partial()

export type GameserverOptionalDefaultsWithPartialRelations = z.infer<typeof GameserverOptionalDefaultsSchema> & GameserverPartialRelations

export const GameserverOptionalDefaultsWithPartialRelationsSchema: z.ZodType<GameserverOptionalDefaultsWithPartialRelations> = GameserverOptionalDefaultsSchema.merge(z.object({
  teams: z.lazy(() => TeamPartialWithRelationsSchema).array(),
}).partial())

export type GameserverWithPartialRelations = z.infer<typeof GameserverSchema> & GameserverPartialRelations

export const GameserverWithPartialRelationsSchema: z.ZodType<GameserverWithPartialRelations> = GameserverSchema.merge(z.object({
  teams: z.lazy(() => TeamPartialWithRelationsSchema).array(),
}).partial())

/////////////////////////////////////////
// PASSWORDRESETS SCHEMA
/////////////////////////////////////////

export const PasswordresetsSchema = z.object({
  id: z.number().int(),
  userId: z.string(),
  uuid: z.string(),
  token: z.string(),
})

export type Passwordresets = z.infer<typeof PasswordresetsSchema>

/////////////////////////////////////////
// PASSWORDRESETS PARTIAL SCHEMA
/////////////////////////////////////////

export const PasswordresetsPartialSchema = PasswordresetsSchema.partial()

export type PasswordresetsPartial = z.infer<typeof PasswordresetsPartialSchema>

// PASSWORDRESETS OPTIONAL DEFAULTS SCHEMA
//------------------------------------------------------

export const PasswordresetsOptionalDefaultsSchema = PasswordresetsSchema.merge(z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  token: z.string().optional(),
}))

export type PasswordresetsOptionalDefaults = z.infer<typeof PasswordresetsOptionalDefaultsSchema>

/////////////////////////////////////////
// SESSION SCHEMA
/////////////////////////////////////////

export const SessionSchema = z.object({
  status: SessionStatusSchema,
  id: z.number().int(),
  uuid: z.string(),
  challenges: z.string().array(),
  companyId: z.number().int(),
  name: z.string(),
  time_limit: z.number().int().nullish(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Session = z.infer<typeof SessionSchema>

/////////////////////////////////////////
// SESSION PARTIAL SCHEMA
/////////////////////////////////////////

export const SessionPartialSchema = SessionSchema.partial()

export type SessionPartial = z.infer<typeof SessionPartialSchema>

// SESSION OPTIONAL DEFAULTS SCHEMA
//------------------------------------------------------

export const SessionOptionalDefaultsSchema = SessionSchema.merge(z.object({
  status: SessionStatusSchema.optional(),
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
}))

export type SessionOptionalDefaults = z.infer<typeof SessionOptionalDefaultsSchema>

// SESSION RELATION SCHEMA
//------------------------------------------------------

export type SessionRelations = {
  company: CompanyWithRelations;
  teams: TeamWithRelations[];
};

export type SessionWithRelations = z.infer<typeof SessionSchema> & SessionRelations

export const SessionWithRelationsSchema: z.ZodType<SessionWithRelations> = SessionSchema.merge(z.object({
  company: z.lazy(() => CompanyWithRelationsSchema),
  teams: z.lazy(() => TeamWithRelationsSchema).array(),
}))

// SESSION OPTIONAL DEFAULTS RELATION SCHEMA
//------------------------------------------------------

export type SessionOptionalDefaultsRelations = {
  company: CompanyOptionalDefaultsWithRelations;
  teams: TeamOptionalDefaultsWithRelations[];
};

export type SessionOptionalDefaultsWithRelations = z.infer<typeof SessionOptionalDefaultsSchema> & SessionOptionalDefaultsRelations

export const SessionOptionalDefaultsWithRelationsSchema: z.ZodType<SessionOptionalDefaultsWithRelations> = SessionOptionalDefaultsSchema.merge(z.object({
  company: z.lazy(() => CompanyOptionalDefaultsWithRelationsSchema),
  teams: z.lazy(() => TeamOptionalDefaultsWithRelationsSchema).array(),
}))

// SESSION PARTIAL RELATION SCHEMA
//------------------------------------------------------

export type SessionPartialRelations = {
  company?: CompanyPartialWithRelations;
  teams?: TeamPartialWithRelations[];
};

export type SessionPartialWithRelations = z.infer<typeof SessionPartialSchema> & SessionPartialRelations

export const SessionPartialWithRelationsSchema: z.ZodType<SessionPartialWithRelations> = SessionPartialSchema.merge(z.object({
  company: z.lazy(() => CompanyPartialWithRelationsSchema),
  teams: z.lazy(() => TeamPartialWithRelationsSchema).array(),
})).partial()

export type SessionOptionalDefaultsWithPartialRelations = z.infer<typeof SessionOptionalDefaultsSchema> & SessionPartialRelations

export const SessionOptionalDefaultsWithPartialRelationsSchema: z.ZodType<SessionOptionalDefaultsWithPartialRelations> = SessionOptionalDefaultsSchema.merge(z.object({
  company: z.lazy(() => CompanyPartialWithRelationsSchema),
  teams: z.lazy(() => TeamPartialWithRelationsSchema).array(),
}).partial())

export type SessionWithPartialRelations = z.infer<typeof SessionSchema> & SessionPartialRelations

export const SessionWithPartialRelationsSchema: z.ZodType<SessionWithPartialRelations> = SessionSchema.merge(z.object({
  company: z.lazy(() => CompanyPartialWithRelationsSchema),
  teams: z.lazy(() => TeamPartialWithRelationsSchema).array(),
}).partial())

/////////////////////////////////////////
// TEAM SCHEMA
/////////////////////////////////////////

export const TeamSchema = z.object({
  challengeStatus: ChallengeStatusSchema,
  status: TeamStatusSchema,
  id: z.number().int(),
  uuid: z.string(),
  connected: z.boolean(),
  currentChallenge: z.number().int(),
  licenceUsed: z.number().int(),
  max_players: z.number().int(),
  players: z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array(),
  token: z.string(),
  spectoken: z.string(),
  gameserversId: z.number().int().nullish(),
  sessionsId: z.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Team = z.infer<typeof TeamSchema>

/////////////////////////////////////////
// TEAM CUSTOM VALIDATORS SCHEMA
/////////////////////////////////////////

export const TeamCustomValidatorsSchema = TeamSchema

export type TeamCustomValidators = z.infer<typeof TeamCustomValidatorsSchema>

/////////////////////////////////////////
// TEAM PARTIAL SCHEMA
/////////////////////////////////////////

export const TeamPartialSchema = TeamSchema.partial()

export type TeamPartial = z.infer<typeof TeamPartialSchema>

// TEAM OPTIONAL DEFAULTS SCHEMA
//------------------------------------------------------

export const TeamOptionalDefaultsSchema = TeamSchema.merge(z.object({
  challengeStatus: ChallengeStatusSchema.optional(),
  status: TeamStatusSchema.optional(),
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  connected: z.boolean().optional(),
  currentChallenge: z.number().int().optional(),
  licenceUsed: z.number().int().optional(),
  max_players: z.number().int().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
}))

export type TeamOptionalDefaults = z.infer<typeof TeamOptionalDefaultsSchema>

// TEAM RELATION SCHEMA
//------------------------------------------------------

export type TeamRelations = {
  events: EventWithRelations[];
  positions: PositionWithRelations[];
  gameservers?: GameserverWithRelations | null;
  sessions: SessionWithRelations;
};

export type TeamWithRelations = z.infer<typeof TeamSchema> & TeamRelations

export const TeamWithRelationsSchema: z.ZodType<TeamWithRelations> = TeamSchema.merge(z.object({
  events: z.lazy(() => EventWithRelationsSchema).array(),
  positions: z.lazy(() => PositionWithRelationsSchema).array(),
  gameservers: z.lazy(() => GameserverWithRelationsSchema).nullish(),
  sessions: z.lazy(() => SessionWithRelationsSchema),
}))

// TEAM OPTIONAL DEFAULTS RELATION SCHEMA
//------------------------------------------------------

export type TeamOptionalDefaultsRelations = {
  events: EventOptionalDefaultsWithRelations[];
  positions: PositionOptionalDefaultsWithRelations[];
  gameservers?: GameserverOptionalDefaultsWithRelations | null;
  sessions: SessionOptionalDefaultsWithRelations;
};

export type TeamOptionalDefaultsWithRelations = z.infer<typeof TeamOptionalDefaultsSchema> & TeamOptionalDefaultsRelations

export const TeamOptionalDefaultsWithRelationsSchema: z.ZodType<TeamOptionalDefaultsWithRelations> = TeamOptionalDefaultsSchema.merge(z.object({
  events: z.lazy(() => EventOptionalDefaultsWithRelationsSchema).array(),
  positions: z.lazy(() => PositionOptionalDefaultsWithRelationsSchema).array(),
  gameservers: z.lazy(() => GameserverOptionalDefaultsWithRelationsSchema).nullish(),
  sessions: z.lazy(() => SessionOptionalDefaultsWithRelationsSchema),
}))

// TEAM PARTIAL RELATION SCHEMA
//------------------------------------------------------

export type TeamPartialRelations = {
  events?: EventPartialWithRelations[];
  positions?: PositionPartialWithRelations[];
  gameservers?: GameserverPartialWithRelations | null;
  sessions?: SessionPartialWithRelations;
};

export type TeamPartialWithRelations = z.infer<typeof TeamPartialSchema> & TeamPartialRelations

export const TeamPartialWithRelationsSchema: z.ZodType<TeamPartialWithRelations> = TeamPartialSchema.merge(z.object({
  events: z.lazy(() => EventPartialWithRelationsSchema).array(),
  positions: z.lazy(() => PositionPartialWithRelationsSchema).array(),
  gameservers: z.lazy(() => GameserverPartialWithRelationsSchema).nullish(),
  sessions: z.lazy(() => SessionPartialWithRelationsSchema),
})).partial()

export type TeamOptionalDefaultsWithPartialRelations = z.infer<typeof TeamOptionalDefaultsSchema> & TeamPartialRelations

export const TeamOptionalDefaultsWithPartialRelationsSchema: z.ZodType<TeamOptionalDefaultsWithPartialRelations> = TeamOptionalDefaultsSchema.merge(z.object({
  events: z.lazy(() => EventPartialWithRelationsSchema).array(),
  positions: z.lazy(() => PositionPartialWithRelationsSchema).array(),
  gameservers: z.lazy(() => GameserverPartialWithRelationsSchema).nullish(),
  sessions: z.lazy(() => SessionPartialWithRelationsSchema),
}).partial())

export type TeamWithPartialRelations = z.infer<typeof TeamSchema> & TeamPartialRelations

export const TeamWithPartialRelationsSchema: z.ZodType<TeamWithPartialRelations> = TeamSchema.merge(z.object({
  events: z.lazy(() => EventPartialWithRelationsSchema).array(),
  positions: z.lazy(() => PositionPartialWithRelationsSchema).array(),
  gameservers: z.lazy(() => GameserverPartialWithRelationsSchema).nullish(),
  sessions: z.lazy(() => SessionPartialWithRelationsSchema),
}).partial())

/////////////////////////////////////////
// USER SCHEMA
/////////////////////////////////////////

export const UserSchema = z.object({
  role: RoleSchema,
  id: z.number().int(),
  uuid: z.string(),
  companyId: z.number().int(),
  email: z.string(),
  hash: z.string(),
  name: z.string(),
  salt: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type User = z.infer<typeof UserSchema>

/////////////////////////////////////////
// USER PARTIAL SCHEMA
/////////////////////////////////////////

export const UserPartialSchema = UserSchema.partial()

export type UserPartial = z.infer<typeof UserPartialSchema>

// USER OPTIONAL DEFAULTS SCHEMA
//------------------------------------------------------

export const UserOptionalDefaultsSchema = UserSchema.merge(z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
}))

export type UserOptionalDefaults = z.infer<typeof UserOptionalDefaultsSchema>

// USER RELATION SCHEMA
//------------------------------------------------------

export type UserRelations = {
  company: CompanyWithRelations;
};

export type UserWithRelations = z.infer<typeof UserSchema> & UserRelations

export const UserWithRelationsSchema: z.ZodType<UserWithRelations> = UserSchema.merge(z.object({
  company: z.lazy(() => CompanyWithRelationsSchema),
}))

// USER OPTIONAL DEFAULTS RELATION SCHEMA
//------------------------------------------------------

export type UserOptionalDefaultsRelations = {
  company: CompanyOptionalDefaultsWithRelations;
};

export type UserOptionalDefaultsWithRelations = z.infer<typeof UserOptionalDefaultsSchema> & UserOptionalDefaultsRelations

export const UserOptionalDefaultsWithRelationsSchema: z.ZodType<UserOptionalDefaultsWithRelations> = UserOptionalDefaultsSchema.merge(z.object({
  company: z.lazy(() => CompanyOptionalDefaultsWithRelationsSchema),
}))

// USER PARTIAL RELATION SCHEMA
//------------------------------------------------------

export type UserPartialRelations = {
  company?: CompanyPartialWithRelations;
};

export type UserPartialWithRelations = z.infer<typeof UserPartialSchema> & UserPartialRelations

export const UserPartialWithRelationsSchema: z.ZodType<UserPartialWithRelations> = UserPartialSchema.merge(z.object({
  company: z.lazy(() => CompanyPartialWithRelationsSchema),
})).partial()

export type UserOptionalDefaultsWithPartialRelations = z.infer<typeof UserOptionalDefaultsSchema> & UserPartialRelations

export const UserOptionalDefaultsWithPartialRelationsSchema: z.ZodType<UserOptionalDefaultsWithPartialRelations> = UserOptionalDefaultsSchema.merge(z.object({
  company: z.lazy(() => CompanyPartialWithRelationsSchema),
}).partial())

export type UserWithPartialRelations = z.infer<typeof UserSchema> & UserPartialRelations

export const UserWithPartialRelationsSchema: z.ZodType<UserWithPartialRelations> = UserSchema.merge(z.object({
  company: z.lazy(() => CompanyPartialWithRelationsSchema),
}).partial())

/////////////////////////////////////////
// SELECT & INCLUDE
/////////////////////////////////////////

// POSITION
//------------------------------------------------------

export const PositionIncludeSchema: z.ZodType<Prisma.PositionInclude> = z.object({
  team: z.union([z.boolean(),z.lazy(() => TeamArgsSchema)]).optional(),
}).strict()

export const PositionArgsSchema: z.ZodType<Prisma.PositionDefaultArgs> = z.object({
  select: z.lazy(() => PositionSelectSchema).optional(),
  include: z.lazy(() => PositionIncludeSchema).optional(),
}).strict();

export const PositionSelectSchema: z.ZodType<Prisma.PositionSelect> = z.object({
  id: z.boolean().optional(),
  locations: z.boolean().optional(),
  teamId: z.boolean().optional(),
  team: z.union([z.boolean(),z.lazy(() => TeamArgsSchema)]).optional(),
}).strict()

// EVENT
//------------------------------------------------------

export const EventIncludeSchema: z.ZodType<Prisma.EventInclude> = z.object({
  team: z.union([z.boolean(),z.lazy(() => TeamArgsSchema)]).optional(),
}).strict()

export const EventArgsSchema: z.ZodType<Prisma.EventDefaultArgs> = z.object({
  select: z.lazy(() => EventSelectSchema).optional(),
  include: z.lazy(() => EventIncludeSchema).optional(),
}).strict();

export const EventSelectSchema: z.ZodType<Prisma.EventSelect> = z.object({
  id: z.boolean().optional(),
  LogType: z.boolean().optional(),
  Parameter: z.boolean().optional(),
  Player: z.boolean().optional(),
  TriggeredObject: z.boolean().optional(),
  timestamp: z.boolean().optional(),
  teamId: z.boolean().optional(),
  team: z.union([z.boolean(),z.lazy(() => TeamArgsSchema)]).optional(),
}).strict()

// CHALLENGE
//------------------------------------------------------

export const ChallengeSelectSchema: z.ZodType<Prisma.ChallengeSelect> = z.object({
  id: z.boolean().optional(),
  display: z.boolean().optional(),
  image: z.boolean().optional(),
  name: z.boolean().optional(),
  order: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
}).strict()

// COMPANY
//------------------------------------------------------

export const CompanyIncludeSchema: z.ZodType<Prisma.CompanyInclude> = z.object({
  users: z.union([z.boolean(),z.lazy(() => UserFindManyArgsSchema)]).optional(),
  sessions: z.union([z.boolean(),z.lazy(() => SessionFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => CompanyCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const CompanyArgsSchema: z.ZodType<Prisma.CompanyDefaultArgs> = z.object({
  select: z.lazy(() => CompanySelectSchema).optional(),
  include: z.lazy(() => CompanyIncludeSchema).optional(),
}).strict();

export const CompanyCountOutputTypeArgsSchema: z.ZodType<Prisma.CompanyCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => CompanyCountOutputTypeSelectSchema).nullish(),
}).strict();

export const CompanyCountOutputTypeSelectSchema: z.ZodType<Prisma.CompanyCountOutputTypeSelect> = z.object({
  users: z.boolean().optional(),
  sessions: z.boolean().optional(),
}).strict();

export const CompanySelectSchema: z.ZodType<Prisma.CompanySelect> = z.object({
  id: z.boolean().optional(),
  uuid: z.boolean().optional(),
  name: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  users: z.union([z.boolean(),z.lazy(() => UserFindManyArgsSchema)]).optional(),
  sessions: z.union([z.boolean(),z.lazy(() => SessionFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => CompanyCountOutputTypeArgsSchema)]).optional(),
}).strict()

// GAMESERVER
//------------------------------------------------------

export const GameserverIncludeSchema: z.ZodType<Prisma.GameserverInclude> = z.object({
  teams: z.union([z.boolean(),z.lazy(() => TeamFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => GameserverCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const GameserverArgsSchema: z.ZodType<Prisma.GameserverDefaultArgs> = z.object({
  select: z.lazy(() => GameserverSelectSchema).optional(),
  include: z.lazy(() => GameserverIncludeSchema).optional(),
}).strict();

export const GameserverCountOutputTypeArgsSchema: z.ZodType<Prisma.GameserverCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => GameserverCountOutputTypeSelectSchema).nullish(),
}).strict();

export const GameserverCountOutputTypeSelectSchema: z.ZodType<Prisma.GameserverCountOutputTypeSelect> = z.object({
  teams: z.boolean().optional(),
}).strict();

export const GameserverSelectSchema: z.ZodType<Prisma.GameserverSelect> = z.object({
  id: z.boolean().optional(),
  uuid: z.boolean().optional(),
  capacity: z.boolean().optional(),
  hostname: z.boolean().optional(),
  provider: z.boolean().optional(),
  state: z.boolean().optional(),
  instanceId: z.boolean().optional(),
  socket: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  teams: z.union([z.boolean(),z.lazy(() => TeamFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => GameserverCountOutputTypeArgsSchema)]).optional(),
}).strict()

// PASSWORDRESETS
//------------------------------------------------------

export const PasswordresetsSelectSchema: z.ZodType<Prisma.PasswordresetsSelect> = z.object({
  id: z.boolean().optional(),
  userId: z.boolean().optional(),
  uuid: z.boolean().optional(),
  token: z.boolean().optional(),
}).strict()

// SESSION
//------------------------------------------------------

export const SessionIncludeSchema: z.ZodType<Prisma.SessionInclude> = z.object({
  company: z.union([z.boolean(),z.lazy(() => CompanyArgsSchema)]).optional(),
  teams: z.union([z.boolean(),z.lazy(() => TeamFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => SessionCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const SessionArgsSchema: z.ZodType<Prisma.SessionDefaultArgs> = z.object({
  select: z.lazy(() => SessionSelectSchema).optional(),
  include: z.lazy(() => SessionIncludeSchema).optional(),
}).strict();

export const SessionCountOutputTypeArgsSchema: z.ZodType<Prisma.SessionCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => SessionCountOutputTypeSelectSchema).nullish(),
}).strict();

export const SessionCountOutputTypeSelectSchema: z.ZodType<Prisma.SessionCountOutputTypeSelect> = z.object({
  teams: z.boolean().optional(),
}).strict();

export const SessionSelectSchema: z.ZodType<Prisma.SessionSelect> = z.object({
  id: z.boolean().optional(),
  uuid: z.boolean().optional(),
  challenges: z.boolean().optional(),
  companyId: z.boolean().optional(),
  name: z.boolean().optional(),
  status: z.boolean().optional(),
  time_limit: z.boolean().optional(),
  start: z.boolean().optional(),
  end: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  company: z.union([z.boolean(),z.lazy(() => CompanyArgsSchema)]).optional(),
  teams: z.union([z.boolean(),z.lazy(() => TeamFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => SessionCountOutputTypeArgsSchema)]).optional(),
}).strict()

// TEAM
//------------------------------------------------------

export const TeamIncludeSchema: z.ZodType<Prisma.TeamInclude> = z.object({
  events: z.union([z.boolean(),z.lazy(() => EventFindManyArgsSchema)]).optional(),
  positions: z.union([z.boolean(),z.lazy(() => PositionFindManyArgsSchema)]).optional(),
  gameservers: z.union([z.boolean(),z.lazy(() => GameserverArgsSchema)]).optional(),
  sessions: z.union([z.boolean(),z.lazy(() => SessionArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => TeamCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const TeamArgsSchema: z.ZodType<Prisma.TeamDefaultArgs> = z.object({
  select: z.lazy(() => TeamSelectSchema).optional(),
  include: z.lazy(() => TeamIncludeSchema).optional(),
}).strict();

export const TeamCountOutputTypeArgsSchema: z.ZodType<Prisma.TeamCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => TeamCountOutputTypeSelectSchema).nullish(),
}).strict();

export const TeamCountOutputTypeSelectSchema: z.ZodType<Prisma.TeamCountOutputTypeSelect> = z.object({
  events: z.boolean().optional(),
  positions: z.boolean().optional(),
}).strict();

export const TeamSelectSchema: z.ZodType<Prisma.TeamSelect> = z.object({
  id: z.boolean().optional(),
  uuid: z.boolean().optional(),
  connected: z.boolean().optional(),
  currentChallenge: z.boolean().optional(),
  challengeStatus: z.boolean().optional(),
  licenceUsed: z.boolean().optional(),
  max_players: z.boolean().optional(),
  players: z.boolean().optional(),
  status: z.boolean().optional(),
  token: z.boolean().optional(),
  spectoken: z.boolean().optional(),
  gameserversId: z.boolean().optional(),
  sessionsId: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  events: z.union([z.boolean(),z.lazy(() => EventFindManyArgsSchema)]).optional(),
  positions: z.union([z.boolean(),z.lazy(() => PositionFindManyArgsSchema)]).optional(),
  gameservers: z.union([z.boolean(),z.lazy(() => GameserverArgsSchema)]).optional(),
  sessions: z.union([z.boolean(),z.lazy(() => SessionArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => TeamCountOutputTypeArgsSchema)]).optional(),
}).strict()

// USER
//------------------------------------------------------

export const UserIncludeSchema: z.ZodType<Prisma.UserInclude> = z.object({
  company: z.union([z.boolean(),z.lazy(() => CompanyArgsSchema)]).optional(),
}).strict()

export const UserArgsSchema: z.ZodType<Prisma.UserDefaultArgs> = z.object({
  select: z.lazy(() => UserSelectSchema).optional(),
  include: z.lazy(() => UserIncludeSchema).optional(),
}).strict();

export const UserSelectSchema: z.ZodType<Prisma.UserSelect> = z.object({
  id: z.boolean().optional(),
  uuid: z.boolean().optional(),
  companyId: z.boolean().optional(),
  email: z.boolean().optional(),
  hash: z.boolean().optional(),
  name: z.boolean().optional(),
  role: z.boolean().optional(),
  salt: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  company: z.union([z.boolean(),z.lazy(() => CompanyArgsSchema)]).optional(),
}).strict()


/////////////////////////////////////////
// INPUT TYPES
/////////////////////////////////////////

export const PositionWhereInputSchema: z.ZodType<Prisma.PositionWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PositionWhereInputSchema),z.lazy(() => PositionWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PositionWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PositionWhereInputSchema),z.lazy(() => PositionWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  locations: z.lazy(() => JsonFilterSchema).optional(),
  teamId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  team: z.union([ z.lazy(() => TeamRelationFilterSchema),z.lazy(() => TeamWhereInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.PositionWhereInput>;

export const PositionOrderByWithRelationInputSchema: z.ZodType<Prisma.PositionOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  locations: z.lazy(() => SortOrderSchema).optional(),
  teamId: z.lazy(() => SortOrderSchema).optional(),
  team: z.lazy(() => TeamOrderByWithRelationInputSchema).optional()
}).strict() as z.ZodType<Prisma.PositionOrderByWithRelationInput>;

export const PositionWhereUniqueInputSchema: z.ZodType<Prisma.PositionWhereUniqueInput> = z.object({
  id: z.number().int()
})
.and(z.object({
  id: z.number().int().optional(),
  AND: z.union([ z.lazy(() => PositionWhereInputSchema),z.lazy(() => PositionWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PositionWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PositionWhereInputSchema),z.lazy(() => PositionWhereInputSchema).array() ]).optional(),
  locations: z.lazy(() => JsonFilterSchema).optional(),
  teamId: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  team: z.union([ z.lazy(() => TeamRelationFilterSchema),z.lazy(() => TeamWhereInputSchema) ]).optional(),
}).strict()) as z.ZodType<Prisma.PositionWhereUniqueInput>;

export const PositionOrderByWithAggregationInputSchema: z.ZodType<Prisma.PositionOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  locations: z.lazy(() => SortOrderSchema).optional(),
  teamId: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => PositionCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => PositionAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => PositionMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => PositionMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => PositionSumOrderByAggregateInputSchema).optional()
}).strict() as z.ZodType<Prisma.PositionOrderByWithAggregationInput>;

export const PositionScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.PositionScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => PositionScalarWhereWithAggregatesInputSchema),z.lazy(() => PositionScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => PositionScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PositionScalarWhereWithAggregatesInputSchema),z.lazy(() => PositionScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  locations: z.lazy(() => JsonWithAggregatesFilterSchema).optional(),
  teamId: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
}).strict() as z.ZodType<Prisma.PositionScalarWhereWithAggregatesInput>;

export const EventWhereInputSchema: z.ZodType<Prisma.EventWhereInput> = z.object({
  AND: z.union([ z.lazy(() => EventWhereInputSchema),z.lazy(() => EventWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => EventWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => EventWhereInputSchema),z.lazy(() => EventWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  LogType: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  Parameter: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  Player: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  TriggeredObject: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  timestamp: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  teamId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  team: z.union([ z.lazy(() => TeamRelationFilterSchema),z.lazy(() => TeamWhereInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.EventWhereInput>;

export const EventOrderByWithRelationInputSchema: z.ZodType<Prisma.EventOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  LogType: z.lazy(() => SortOrderSchema).optional(),
  Parameter: z.lazy(() => SortOrderSchema).optional(),
  Player: z.lazy(() => SortOrderSchema).optional(),
  TriggeredObject: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  teamId: z.lazy(() => SortOrderSchema).optional(),
  team: z.lazy(() => TeamOrderByWithRelationInputSchema).optional()
}).strict() as z.ZodType<Prisma.EventOrderByWithRelationInput>;

export const EventWhereUniqueInputSchema: z.ZodType<Prisma.EventWhereUniqueInput> = z.object({
  id: z.number().int()
})
.and(z.object({
  id: z.number().int().optional(),
  AND: z.union([ z.lazy(() => EventWhereInputSchema),z.lazy(() => EventWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => EventWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => EventWhereInputSchema),z.lazy(() => EventWhereInputSchema).array() ]).optional(),
  LogType: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  Parameter: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  Player: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  TriggeredObject: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  timestamp: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  teamId: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  team: z.union([ z.lazy(() => TeamRelationFilterSchema),z.lazy(() => TeamWhereInputSchema) ]).optional(),
}).strict()) as z.ZodType<Prisma.EventWhereUniqueInput>;

export const EventOrderByWithAggregationInputSchema: z.ZodType<Prisma.EventOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  LogType: z.lazy(() => SortOrderSchema).optional(),
  Parameter: z.lazy(() => SortOrderSchema).optional(),
  Player: z.lazy(() => SortOrderSchema).optional(),
  TriggeredObject: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  teamId: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => EventCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => EventAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => EventMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => EventMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => EventSumOrderByAggregateInputSchema).optional()
}).strict() as z.ZodType<Prisma.EventOrderByWithAggregationInput>;

export const EventScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.EventScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => EventScalarWhereWithAggregatesInputSchema),z.lazy(() => EventScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => EventScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => EventScalarWhereWithAggregatesInputSchema),z.lazy(() => EventScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  LogType: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  Parameter: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  Player: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  TriggeredObject: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  timestamp: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  teamId: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
}).strict() as z.ZodType<Prisma.EventScalarWhereWithAggregatesInput>;

export const ChallengeWhereInputSchema: z.ZodType<Prisma.ChallengeWhereInput> = z.object({
  AND: z.union([ z.lazy(() => ChallengeWhereInputSchema),z.lazy(() => ChallengeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => ChallengeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ChallengeWhereInputSchema),z.lazy(() => ChallengeWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  display: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  image: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  order: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict() as z.ZodType<Prisma.ChallengeWhereInput>;

export const ChallengeOrderByWithRelationInputSchema: z.ZodType<Prisma.ChallengeOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  display: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  order: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.ChallengeOrderByWithRelationInput>;

export const ChallengeWhereUniqueInputSchema: z.ZodType<Prisma.ChallengeWhereUniqueInput> = z.union([
  z.object({
    id: z.number().int(),
    name: z.string()
  }),
  z.object({
    id: z.number().int(),
  }),
  z.object({
    name: z.string(),
  }),
])
.and(z.object({
  id: z.number().int().optional(),
  name: z.string().optional(),
  AND: z.union([ z.lazy(() => ChallengeWhereInputSchema),z.lazy(() => ChallengeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => ChallengeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ChallengeWhereInputSchema),z.lazy(() => ChallengeWhereInputSchema).array() ]).optional(),
  display: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  image: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  order: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict()) as z.ZodType<Prisma.ChallengeWhereUniqueInput>;

export const ChallengeOrderByWithAggregationInputSchema: z.ZodType<Prisma.ChallengeOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  display: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  order: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => ChallengeCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => ChallengeAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => ChallengeMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => ChallengeMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => ChallengeSumOrderByAggregateInputSchema).optional()
}).strict() as z.ZodType<Prisma.ChallengeOrderByWithAggregationInput>;

export const ChallengeScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.ChallengeScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => ChallengeScalarWhereWithAggregatesInputSchema),z.lazy(() => ChallengeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => ChallengeScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ChallengeScalarWhereWithAggregatesInputSchema),z.lazy(() => ChallengeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  display: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  image: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  order: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict() as z.ZodType<Prisma.ChallengeScalarWhereWithAggregatesInput>;

export const CompanyWhereInputSchema: z.ZodType<Prisma.CompanyWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CompanyWhereInputSchema),z.lazy(() => CompanyWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CompanyWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CompanyWhereInputSchema),z.lazy(() => CompanyWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  uuid: z.union([ z.lazy(() => UuidFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  users: z.lazy(() => UserListRelationFilterSchema).optional(),
  sessions: z.lazy(() => SessionListRelationFilterSchema).optional()
}).strict() as z.ZodType<Prisma.CompanyWhereInput>;

export const CompanyOrderByWithRelationInputSchema: z.ZodType<Prisma.CompanyOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  users: z.lazy(() => UserOrderByRelationAggregateInputSchema).optional(),
  sessions: z.lazy(() => SessionOrderByRelationAggregateInputSchema).optional()
}).strict() as z.ZodType<Prisma.CompanyOrderByWithRelationInput>;

export const CompanyWhereUniqueInputSchema: z.ZodType<Prisma.CompanyWhereUniqueInput> = z.union([
  z.object({
    id: z.number().int(),
    uuid: z.string()
  }),
  z.object({
    id: z.number().int(),
  }),
  z.object({
    uuid: z.string(),
  }),
])
.and(z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  AND: z.union([ z.lazy(() => CompanyWhereInputSchema),z.lazy(() => CompanyWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CompanyWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CompanyWhereInputSchema),z.lazy(() => CompanyWhereInputSchema).array() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  users: z.lazy(() => UserListRelationFilterSchema).optional(),
  sessions: z.lazy(() => SessionListRelationFilterSchema).optional()
}).strict()) as z.ZodType<Prisma.CompanyWhereUniqueInput>;

export const CompanyOrderByWithAggregationInputSchema: z.ZodType<Prisma.CompanyOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => CompanyCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => CompanyAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CompanyMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CompanyMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => CompanySumOrderByAggregateInputSchema).optional()
}).strict() as z.ZodType<Prisma.CompanyOrderByWithAggregationInput>;

export const CompanyScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CompanyScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CompanyScalarWhereWithAggregatesInputSchema),z.lazy(() => CompanyScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CompanyScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CompanyScalarWhereWithAggregatesInputSchema),z.lazy(() => CompanyScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  uuid: z.union([ z.lazy(() => UuidWithAggregatesFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict() as z.ZodType<Prisma.CompanyScalarWhereWithAggregatesInput>;

export const GameserverWhereInputSchema: z.ZodType<Prisma.GameserverWhereInput> = z.object({
  AND: z.union([ z.lazy(() => GameserverWhereInputSchema),z.lazy(() => GameserverWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => GameserverWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => GameserverWhereInputSchema),z.lazy(() => GameserverWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  uuid: z.union([ z.lazy(() => UuidFilterSchema),z.string() ]).optional(),
  capacity: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  hostname: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  provider: z.union([ z.lazy(() => EnumGameserverProviderFilterSchema),z.lazy(() => GameserverProviderSchema) ]).optional(),
  state: z.union([ z.lazy(() => EnumInstanceStateFilterSchema),z.lazy(() => InstanceStateSchema) ]).optional(),
  instanceId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  socket: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  teams: z.lazy(() => TeamListRelationFilterSchema).optional()
}).strict() as z.ZodType<Prisma.GameserverWhereInput>;

export const GameserverOrderByWithRelationInputSchema: z.ZodType<Prisma.GameserverOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  capacity: z.lazy(() => SortOrderSchema).optional(),
  hostname: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  provider: z.lazy(() => SortOrderSchema).optional(),
  state: z.lazy(() => SortOrderSchema).optional(),
  instanceId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  socket: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  teams: z.lazy(() => TeamOrderByRelationAggregateInputSchema).optional()
}).strict() as z.ZodType<Prisma.GameserverOrderByWithRelationInput>;

export const GameserverWhereUniqueInputSchema: z.ZodType<Prisma.GameserverWhereUniqueInput> = z.union([
  z.object({
    id: z.number().int(),
    uuid: z.string()
  }),
  z.object({
    id: z.number().int(),
  }),
  z.object({
    uuid: z.string(),
  }),
])
.and(z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  AND: z.union([ z.lazy(() => GameserverWhereInputSchema),z.lazy(() => GameserverWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => GameserverWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => GameserverWhereInputSchema),z.lazy(() => GameserverWhereInputSchema).array() ]).optional(),
  capacity: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  hostname: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  provider: z.union([ z.lazy(() => EnumGameserverProviderFilterSchema),z.lazy(() => GameserverProviderSchema) ]).optional(),
  state: z.union([ z.lazy(() => EnumInstanceStateFilterSchema),z.lazy(() => InstanceStateSchema) ]).optional(),
  instanceId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  socket: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  teams: z.lazy(() => TeamListRelationFilterSchema).optional()
}).strict()) as z.ZodType<Prisma.GameserverWhereUniqueInput>;

export const GameserverOrderByWithAggregationInputSchema: z.ZodType<Prisma.GameserverOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  capacity: z.lazy(() => SortOrderSchema).optional(),
  hostname: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  provider: z.lazy(() => SortOrderSchema).optional(),
  state: z.lazy(() => SortOrderSchema).optional(),
  instanceId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  socket: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => GameserverCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => GameserverAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => GameserverMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => GameserverMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => GameserverSumOrderByAggregateInputSchema).optional()
}).strict() as z.ZodType<Prisma.GameserverOrderByWithAggregationInput>;

export const GameserverScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.GameserverScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => GameserverScalarWhereWithAggregatesInputSchema),z.lazy(() => GameserverScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => GameserverScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => GameserverScalarWhereWithAggregatesInputSchema),z.lazy(() => GameserverScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  uuid: z.union([ z.lazy(() => UuidWithAggregatesFilterSchema),z.string() ]).optional(),
  capacity: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  hostname: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  provider: z.union([ z.lazy(() => EnumGameserverProviderWithAggregatesFilterSchema),z.lazy(() => GameserverProviderSchema) ]).optional(),
  state: z.union([ z.lazy(() => EnumInstanceStateWithAggregatesFilterSchema),z.lazy(() => InstanceStateSchema) ]).optional(),
  instanceId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  socket: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict() as z.ZodType<Prisma.GameserverScalarWhereWithAggregatesInput>;

export const PasswordresetsWhereInputSchema: z.ZodType<Prisma.PasswordresetsWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PasswordresetsWhereInputSchema),z.lazy(() => PasswordresetsWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PasswordresetsWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PasswordresetsWhereInputSchema),z.lazy(() => PasswordresetsWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  userId: z.union([ z.lazy(() => UuidFilterSchema),z.string() ]).optional(),
  uuid: z.union([ z.lazy(() => UuidFilterSchema),z.string() ]).optional(),
  token: z.union([ z.lazy(() => UuidFilterSchema),z.string() ]).optional(),
}).strict() as z.ZodType<Prisma.PasswordresetsWhereInput>;

export const PasswordresetsOrderByWithRelationInputSchema: z.ZodType<Prisma.PasswordresetsOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.PasswordresetsOrderByWithRelationInput>;

export const PasswordresetsWhereUniqueInputSchema: z.ZodType<Prisma.PasswordresetsWhereUniqueInput> = z.union([
  z.object({
    id: z.number().int(),
    uuid: z.string(),
    token: z.string()
  }),
  z.object({
    id: z.number().int(),
    uuid: z.string(),
  }),
  z.object({
    id: z.number().int(),
    token: z.string(),
  }),
  z.object({
    id: z.number().int(),
  }),
  z.object({
    uuid: z.string(),
    token: z.string(),
  }),
  z.object({
    uuid: z.string(),
  }),
  z.object({
    token: z.string(),
  }),
])
.and(z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  token: z.string().optional(),
  AND: z.union([ z.lazy(() => PasswordresetsWhereInputSchema),z.lazy(() => PasswordresetsWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PasswordresetsWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PasswordresetsWhereInputSchema),z.lazy(() => PasswordresetsWhereInputSchema).array() ]).optional(),
  userId: z.union([ z.lazy(() => UuidFilterSchema),z.string() ]).optional(),
}).strict()) as z.ZodType<Prisma.PasswordresetsWhereUniqueInput>;

export const PasswordresetsOrderByWithAggregationInputSchema: z.ZodType<Prisma.PasswordresetsOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => PasswordresetsCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => PasswordresetsAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => PasswordresetsMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => PasswordresetsMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => PasswordresetsSumOrderByAggregateInputSchema).optional()
}).strict() as z.ZodType<Prisma.PasswordresetsOrderByWithAggregationInput>;

export const PasswordresetsScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.PasswordresetsScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => PasswordresetsScalarWhereWithAggregatesInputSchema),z.lazy(() => PasswordresetsScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => PasswordresetsScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PasswordresetsScalarWhereWithAggregatesInputSchema),z.lazy(() => PasswordresetsScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  userId: z.union([ z.lazy(() => UuidWithAggregatesFilterSchema),z.string() ]).optional(),
  uuid: z.union([ z.lazy(() => UuidWithAggregatesFilterSchema),z.string() ]).optional(),
  token: z.union([ z.lazy(() => UuidWithAggregatesFilterSchema),z.string() ]).optional(),
}).strict() as z.ZodType<Prisma.PasswordresetsScalarWhereWithAggregatesInput>;

export const SessionWhereInputSchema: z.ZodType<Prisma.SessionWhereInput> = z.object({
  AND: z.union([ z.lazy(() => SessionWhereInputSchema),z.lazy(() => SessionWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SessionWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SessionWhereInputSchema),z.lazy(() => SessionWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  uuid: z.union([ z.lazy(() => UuidFilterSchema),z.string() ]).optional(),
  challenges: z.lazy(() => StringNullableListFilterSchema).optional(),
  companyId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => EnumSessionStatusFilterSchema),z.lazy(() => SessionStatusSchema) ]).optional(),
  time_limit: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  start: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  end: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  company: z.union([ z.lazy(() => CompanyRelationFilterSchema),z.lazy(() => CompanyWhereInputSchema) ]).optional(),
  teams: z.lazy(() => TeamListRelationFilterSchema).optional()
}).strict() as z.ZodType<Prisma.SessionWhereInput>;

export const SessionOrderByWithRelationInputSchema: z.ZodType<Prisma.SessionOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  challenges: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  time_limit: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  start: z.lazy(() => SortOrderSchema).optional(),
  end: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  company: z.lazy(() => CompanyOrderByWithRelationInputSchema).optional(),
  teams: z.lazy(() => TeamOrderByRelationAggregateInputSchema).optional()
}).strict() as z.ZodType<Prisma.SessionOrderByWithRelationInput>;

export const SessionWhereUniqueInputSchema: z.ZodType<Prisma.SessionWhereUniqueInput> = z.union([
  z.object({
    id: z.number().int(),
    uuid: z.string()
  }),
  z.object({
    id: z.number().int(),
  }),
  z.object({
    uuid: z.string(),
  }),
])
.and(z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  AND: z.union([ z.lazy(() => SessionWhereInputSchema),z.lazy(() => SessionWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SessionWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SessionWhereInputSchema),z.lazy(() => SessionWhereInputSchema).array() ]).optional(),
  challenges: z.lazy(() => StringNullableListFilterSchema).optional(),
  companyId: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => EnumSessionStatusFilterSchema),z.lazy(() => SessionStatusSchema) ]).optional(),
  time_limit: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  start: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  end: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  company: z.union([ z.lazy(() => CompanyRelationFilterSchema),z.lazy(() => CompanyWhereInputSchema) ]).optional(),
  teams: z.lazy(() => TeamListRelationFilterSchema).optional()
}).strict()) as z.ZodType<Prisma.SessionWhereUniqueInput>;

export const SessionOrderByWithAggregationInputSchema: z.ZodType<Prisma.SessionOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  challenges: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  time_limit: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  start: z.lazy(() => SortOrderSchema).optional(),
  end: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => SessionCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => SessionAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => SessionMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => SessionMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => SessionSumOrderByAggregateInputSchema).optional()
}).strict() as z.ZodType<Prisma.SessionOrderByWithAggregationInput>;

export const SessionScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.SessionScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => SessionScalarWhereWithAggregatesInputSchema),z.lazy(() => SessionScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => SessionScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SessionScalarWhereWithAggregatesInputSchema),z.lazy(() => SessionScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  uuid: z.union([ z.lazy(() => UuidWithAggregatesFilterSchema),z.string() ]).optional(),
  challenges: z.lazy(() => StringNullableListFilterSchema).optional(),
  companyId: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  name: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => EnumSessionStatusWithAggregatesFilterSchema),z.lazy(() => SessionStatusSchema) ]).optional(),
  time_limit: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  start: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  end: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict() as z.ZodType<Prisma.SessionScalarWhereWithAggregatesInput>;

export const TeamWhereInputSchema: z.ZodType<Prisma.TeamWhereInput> = z.object({
  AND: z.union([ z.lazy(() => TeamWhereInputSchema),z.lazy(() => TeamWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => TeamWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => TeamWhereInputSchema),z.lazy(() => TeamWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  uuid: z.union([ z.lazy(() => UuidFilterSchema),z.string() ]).optional(),
  connected: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  currentChallenge: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  challengeStatus: z.union([ z.lazy(() => EnumChallengeStatusFilterSchema),z.lazy(() => ChallengeStatusSchema) ]).optional(),
  licenceUsed: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  max_players: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  players: z.lazy(() => JsonNullableListFilterSchema).optional(),
  status: z.union([ z.lazy(() => EnumTeamStatusFilterSchema),z.lazy(() => TeamStatusSchema) ]).optional(),
  token: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  spectoken: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  gameserversId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  sessionsId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  events: z.lazy(() => EventListRelationFilterSchema).optional(),
  positions: z.lazy(() => PositionListRelationFilterSchema).optional(),
  gameservers: z.union([ z.lazy(() => GameserverNullableRelationFilterSchema),z.lazy(() => GameserverWhereInputSchema) ]).optional().nullable(),
  sessions: z.union([ z.lazy(() => SessionRelationFilterSchema),z.lazy(() => SessionWhereInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.TeamWhereInput>;

export const TeamOrderByWithRelationInputSchema: z.ZodType<Prisma.TeamOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  connected: z.lazy(() => SortOrderSchema).optional(),
  currentChallenge: z.lazy(() => SortOrderSchema).optional(),
  challengeStatus: z.lazy(() => SortOrderSchema).optional(),
  licenceUsed: z.lazy(() => SortOrderSchema).optional(),
  max_players: z.lazy(() => SortOrderSchema).optional(),
  players: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional(),
  spectoken: z.lazy(() => SortOrderSchema).optional(),
  gameserversId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  sessionsId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  events: z.lazy(() => EventOrderByRelationAggregateInputSchema).optional(),
  positions: z.lazy(() => PositionOrderByRelationAggregateInputSchema).optional(),
  gameservers: z.lazy(() => GameserverOrderByWithRelationInputSchema).optional(),
  sessions: z.lazy(() => SessionOrderByWithRelationInputSchema).optional()
}).strict() as z.ZodType<Prisma.TeamOrderByWithRelationInput>;

export const TeamWhereUniqueInputSchema: z.ZodType<Prisma.TeamWhereUniqueInput> = z.union([
  z.object({
    id: z.number().int(),
    uuid: z.string()
  }),
  z.object({
    id: z.number().int(),
  }),
  z.object({
    uuid: z.string(),
  }),
])
.and(z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  AND: z.union([ z.lazy(() => TeamWhereInputSchema),z.lazy(() => TeamWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => TeamWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => TeamWhereInputSchema),z.lazy(() => TeamWhereInputSchema).array() ]).optional(),
  connected: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  currentChallenge: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  challengeStatus: z.union([ z.lazy(() => EnumChallengeStatusFilterSchema),z.lazy(() => ChallengeStatusSchema) ]).optional(),
  licenceUsed: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  max_players: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  players: z.lazy(() => JsonNullableListFilterSchema).optional(),
  status: z.union([ z.lazy(() => EnumTeamStatusFilterSchema),z.lazy(() => TeamStatusSchema) ]).optional(),
  token: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  spectoken: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  gameserversId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  sessionsId: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  events: z.lazy(() => EventListRelationFilterSchema).optional(),
  positions: z.lazy(() => PositionListRelationFilterSchema).optional(),
  gameservers: z.union([ z.lazy(() => GameserverNullableRelationFilterSchema),z.lazy(() => GameserverWhereInputSchema) ]).optional().nullable(),
  sessions: z.union([ z.lazy(() => SessionRelationFilterSchema),z.lazy(() => SessionWhereInputSchema) ]).optional(),
}).strict()) as z.ZodType<Prisma.TeamWhereUniqueInput>;

export const TeamOrderByWithAggregationInputSchema: z.ZodType<Prisma.TeamOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  connected: z.lazy(() => SortOrderSchema).optional(),
  currentChallenge: z.lazy(() => SortOrderSchema).optional(),
  challengeStatus: z.lazy(() => SortOrderSchema).optional(),
  licenceUsed: z.lazy(() => SortOrderSchema).optional(),
  max_players: z.lazy(() => SortOrderSchema).optional(),
  players: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional(),
  spectoken: z.lazy(() => SortOrderSchema).optional(),
  gameserversId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  sessionsId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => TeamCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => TeamAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => TeamMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => TeamMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => TeamSumOrderByAggregateInputSchema).optional()
}).strict() as z.ZodType<Prisma.TeamOrderByWithAggregationInput>;

export const TeamScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.TeamScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => TeamScalarWhereWithAggregatesInputSchema),z.lazy(() => TeamScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => TeamScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => TeamScalarWhereWithAggregatesInputSchema),z.lazy(() => TeamScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  uuid: z.union([ z.lazy(() => UuidWithAggregatesFilterSchema),z.string() ]).optional(),
  connected: z.union([ z.lazy(() => BoolWithAggregatesFilterSchema),z.boolean() ]).optional(),
  currentChallenge: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  challengeStatus: z.union([ z.lazy(() => EnumChallengeStatusWithAggregatesFilterSchema),z.lazy(() => ChallengeStatusSchema) ]).optional(),
  licenceUsed: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  max_players: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  players: z.lazy(() => JsonNullableListFilterSchema).optional(),
  status: z.union([ z.lazy(() => EnumTeamStatusWithAggregatesFilterSchema),z.lazy(() => TeamStatusSchema) ]).optional(),
  token: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  spectoken: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  gameserversId: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  sessionsId: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict() as z.ZodType<Prisma.TeamScalarWhereWithAggregatesInput>;

export const UserWhereInputSchema: z.ZodType<Prisma.UserWhereInput> = z.object({
  AND: z.union([ z.lazy(() => UserWhereInputSchema),z.lazy(() => UserWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => UserWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => UserWhereInputSchema),z.lazy(() => UserWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  uuid: z.union([ z.lazy(() => UuidFilterSchema),z.string() ]).optional(),
  companyId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  email: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  hash: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  role: z.union([ z.lazy(() => EnumRoleFilterSchema),z.lazy(() => RoleSchema) ]).optional(),
  salt: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  company: z.union([ z.lazy(() => CompanyRelationFilterSchema),z.lazy(() => CompanyWhereInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.UserWhereInput>;

export const UserOrderByWithRelationInputSchema: z.ZodType<Prisma.UserOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  hash: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  role: z.lazy(() => SortOrderSchema).optional(),
  salt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  company: z.lazy(() => CompanyOrderByWithRelationInputSchema).optional()
}).strict() as z.ZodType<Prisma.UserOrderByWithRelationInput>;

export const UserWhereUniqueInputSchema: z.ZodType<Prisma.UserWhereUniqueInput> = z.union([
  z.object({
    id: z.number().int(),
    uuid: z.string(),
    email: z.string()
  }),
  z.object({
    id: z.number().int(),
    uuid: z.string(),
  }),
  z.object({
    id: z.number().int(),
    email: z.string(),
  }),
  z.object({
    id: z.number().int(),
  }),
  z.object({
    uuid: z.string(),
    email: z.string(),
  }),
  z.object({
    uuid: z.string(),
  }),
  z.object({
    email: z.string(),
  }),
])
.and(z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  email: z.string().optional(),
  AND: z.union([ z.lazy(() => UserWhereInputSchema),z.lazy(() => UserWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => UserWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => UserWhereInputSchema),z.lazy(() => UserWhereInputSchema).array() ]).optional(),
  companyId: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  hash: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  role: z.union([ z.lazy(() => EnumRoleFilterSchema),z.lazy(() => RoleSchema) ]).optional(),
  salt: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  company: z.union([ z.lazy(() => CompanyRelationFilterSchema),z.lazy(() => CompanyWhereInputSchema) ]).optional(),
}).strict()) as z.ZodType<Prisma.UserWhereUniqueInput>;

export const UserOrderByWithAggregationInputSchema: z.ZodType<Prisma.UserOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  hash: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  role: z.lazy(() => SortOrderSchema).optional(),
  salt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => UserCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => UserAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => UserMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => UserMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => UserSumOrderByAggregateInputSchema).optional()
}).strict() as z.ZodType<Prisma.UserOrderByWithAggregationInput>;

export const UserScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.UserScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => UserScalarWhereWithAggregatesInputSchema),z.lazy(() => UserScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => UserScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => UserScalarWhereWithAggregatesInputSchema),z.lazy(() => UserScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  uuid: z.union([ z.lazy(() => UuidWithAggregatesFilterSchema),z.string() ]).optional(),
  companyId: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  email: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  hash: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  role: z.union([ z.lazy(() => EnumRoleWithAggregatesFilterSchema),z.lazy(() => RoleSchema) ]).optional(),
  salt: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict() as z.ZodType<Prisma.UserScalarWhereWithAggregatesInput>;

export const PositionCreateInputSchema: z.ZodType<Prisma.PositionCreateInput> = z.object({
  locations: z.union([ z.lazy(() => JsonNullValueInputSchema),z.object({slot: z.number(), location: z.number().array().length(3)}).array() ]),
  team: z.lazy(() => TeamCreateNestedOneWithoutPositionsInputSchema)
}).strict() as z.ZodType<Prisma.PositionCreateInput>;

export const PositionUncheckedCreateInputSchema: z.ZodType<Prisma.PositionUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  locations: z.union([ z.lazy(() => JsonNullValueInputSchema),z.object({slot: z.number(), location: z.number().array().length(3)}).array() ]),
  teamId: z.number().int()
}).strict() as z.ZodType<Prisma.PositionUncheckedCreateInput>;

export const PositionUpdateInputSchema: z.ZodType<Prisma.PositionUpdateInput> = z.object({
  locations: z.union([ z.lazy(() => JsonNullValueInputSchema),z.object({slot: z.number(), location: z.number().array().length(3)}).array() ]).optional(),
  team: z.lazy(() => TeamUpdateOneRequiredWithoutPositionsNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.PositionUpdateInput>;

export const PositionUncheckedUpdateInputSchema: z.ZodType<Prisma.PositionUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  locations: z.union([ z.lazy(() => JsonNullValueInputSchema),z.object({slot: z.number(), location: z.number().array().length(3)}).array() ]).optional(),
  teamId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.PositionUncheckedUpdateInput>;

export const PositionCreateManyInputSchema: z.ZodType<Prisma.PositionCreateManyInput> = z.object({
  id: z.number().int().optional(),
  locations: z.union([ z.lazy(() => JsonNullValueInputSchema),z.object({slot: z.number(), location: z.number().array().length(3)}).array() ]),
  teamId: z.number().int()
}).strict() as z.ZodType<Prisma.PositionCreateManyInput>;

export const PositionUpdateManyMutationInputSchema: z.ZodType<Prisma.PositionUpdateManyMutationInput> = z.object({
  locations: z.union([ z.lazy(() => JsonNullValueInputSchema),z.object({slot: z.number(), location: z.number().array().length(3)}).array() ]).optional(),
}).strict() as z.ZodType<Prisma.PositionUpdateManyMutationInput>;

export const PositionUncheckedUpdateManyInputSchema: z.ZodType<Prisma.PositionUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  locations: z.union([ z.lazy(() => JsonNullValueInputSchema),z.object({slot: z.number(), location: z.number().array().length(3)}).array() ]).optional(),
  teamId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.PositionUncheckedUpdateManyInput>;

export const EventCreateInputSchema: z.ZodType<Prisma.EventCreateInput> = z.object({
  LogType: z.string(),
  Parameter: z.string(),
  Player: z.number().int(),
  TriggeredObject: z.string(),
  timestamp: z.coerce.date(),
  team: z.lazy(() => TeamCreateNestedOneWithoutEventsInputSchema)
}).strict() as z.ZodType<Prisma.EventCreateInput>;

export const EventUncheckedCreateInputSchema: z.ZodType<Prisma.EventUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  LogType: z.string(),
  Parameter: z.string(),
  Player: z.number().int(),
  TriggeredObject: z.string(),
  timestamp: z.coerce.date(),
  teamId: z.number().int()
}).strict() as z.ZodType<Prisma.EventUncheckedCreateInput>;

export const EventUpdateInputSchema: z.ZodType<Prisma.EventUpdateInput> = z.object({
  LogType: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  Parameter: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  Player: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  TriggeredObject: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  team: z.lazy(() => TeamUpdateOneRequiredWithoutEventsNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.EventUpdateInput>;

export const EventUncheckedUpdateInputSchema: z.ZodType<Prisma.EventUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  LogType: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  Parameter: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  Player: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  TriggeredObject: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  teamId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.EventUncheckedUpdateInput>;

export const EventCreateManyInputSchema: z.ZodType<Prisma.EventCreateManyInput> = z.object({
  id: z.number().int().optional(),
  LogType: z.string(),
  Parameter: z.string(),
  Player: z.number().int(),
  TriggeredObject: z.string(),
  timestamp: z.coerce.date(),
  teamId: z.number().int()
}).strict() as z.ZodType<Prisma.EventCreateManyInput>;

export const EventUpdateManyMutationInputSchema: z.ZodType<Prisma.EventUpdateManyMutationInput> = z.object({
  LogType: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  Parameter: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  Player: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  TriggeredObject: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.EventUpdateManyMutationInput>;

export const EventUncheckedUpdateManyInputSchema: z.ZodType<Prisma.EventUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  LogType: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  Parameter: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  Player: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  TriggeredObject: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  teamId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.EventUncheckedUpdateManyInput>;

export const ChallengeCreateInputSchema: z.ZodType<Prisma.ChallengeCreateInput> = z.object({
  display: z.string(),
  image: z.string(),
  name: z.string(),
  order: z.number().int().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict() as z.ZodType<Prisma.ChallengeCreateInput>;

export const ChallengeUncheckedCreateInputSchema: z.ZodType<Prisma.ChallengeUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  display: z.string(),
  image: z.string(),
  name: z.string(),
  order: z.number().int().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict() as z.ZodType<Prisma.ChallengeUncheckedCreateInput>;

export const ChallengeUpdateInputSchema: z.ZodType<Prisma.ChallengeUpdateInput> = z.object({
  display: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  order: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.ChallengeUpdateInput>;

export const ChallengeUncheckedUpdateInputSchema: z.ZodType<Prisma.ChallengeUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  display: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  order: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.ChallengeUncheckedUpdateInput>;

export const ChallengeCreateManyInputSchema: z.ZodType<Prisma.ChallengeCreateManyInput> = z.object({
  id: z.number().int().optional(),
  display: z.string(),
  image: z.string(),
  name: z.string(),
  order: z.number().int().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict() as z.ZodType<Prisma.ChallengeCreateManyInput>;

export const ChallengeUpdateManyMutationInputSchema: z.ZodType<Prisma.ChallengeUpdateManyMutationInput> = z.object({
  display: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  order: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.ChallengeUpdateManyMutationInput>;

export const ChallengeUncheckedUpdateManyInputSchema: z.ZodType<Prisma.ChallengeUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  display: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  image: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  order: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.ChallengeUncheckedUpdateManyInput>;

export const CompanyCreateInputSchema: z.ZodType<Prisma.CompanyCreateInput> = z.object({
  uuid: z.string().optional(),
  name: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  users: z.lazy(() => UserCreateNestedManyWithoutCompanyInputSchema).optional(),
  sessions: z.lazy(() => SessionCreateNestedManyWithoutCompanyInputSchema).optional()
}).strict() as z.ZodType<Prisma.CompanyCreateInput>;

export const CompanyUncheckedCreateInputSchema: z.ZodType<Prisma.CompanyUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  name: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedCreateNestedManyWithoutCompanyInputSchema).optional()
}).strict() as z.ZodType<Prisma.CompanyUncheckedCreateInput>;

export const CompanyUpdateInputSchema: z.ZodType<Prisma.CompanyUpdateInput> = z.object({
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  users: z.lazy(() => UserUpdateManyWithoutCompanyNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUpdateManyWithoutCompanyNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.CompanyUpdateInput>;

export const CompanyUncheckedUpdateInputSchema: z.ZodType<Prisma.CompanyUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.CompanyUncheckedUpdateInput>;

export const CompanyCreateManyInputSchema: z.ZodType<Prisma.CompanyCreateManyInput> = z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  name: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict() as z.ZodType<Prisma.CompanyCreateManyInput>;

export const CompanyUpdateManyMutationInputSchema: z.ZodType<Prisma.CompanyUpdateManyMutationInput> = z.object({
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.CompanyUpdateManyMutationInput>;

export const CompanyUncheckedUpdateManyInputSchema: z.ZodType<Prisma.CompanyUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.CompanyUncheckedUpdateManyInput>;

export const GameserverCreateInputSchema: z.ZodType<Prisma.GameserverCreateInput> = z.object({
  uuid: z.string().optional(),
  capacity: z.number().int(),
  hostname: z.string().optional().nullable(),
  provider: z.lazy(() => GameserverProviderSchema).optional(),
  state: z.lazy(() => InstanceStateSchema),
  instanceId: z.string().optional().nullable(),
  socket: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  teams: z.lazy(() => TeamCreateNestedManyWithoutGameserversInputSchema).optional()
}).strict() as z.ZodType<Prisma.GameserverCreateInput>;

export const GameserverUncheckedCreateInputSchema: z.ZodType<Prisma.GameserverUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  capacity: z.number().int(),
  hostname: z.string().optional().nullable(),
  provider: z.lazy(() => GameserverProviderSchema).optional(),
  state: z.lazy(() => InstanceStateSchema),
  instanceId: z.string().optional().nullable(),
  socket: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  teams: z.lazy(() => TeamUncheckedCreateNestedManyWithoutGameserversInputSchema).optional()
}).strict() as z.ZodType<Prisma.GameserverUncheckedCreateInput>;

export const GameserverUpdateInputSchema: z.ZodType<Prisma.GameserverUpdateInput> = z.object({
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  capacity: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  hostname: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  provider: z.union([ z.lazy(() => GameserverProviderSchema),z.lazy(() => EnumGameserverProviderFieldUpdateOperationsInputSchema) ]).optional(),
  state: z.union([ z.lazy(() => InstanceStateSchema),z.lazy(() => EnumInstanceStateFieldUpdateOperationsInputSchema) ]).optional(),
  instanceId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  socket: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  teams: z.lazy(() => TeamUpdateManyWithoutGameserversNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.GameserverUpdateInput>;

export const GameserverUncheckedUpdateInputSchema: z.ZodType<Prisma.GameserverUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  capacity: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  hostname: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  provider: z.union([ z.lazy(() => GameserverProviderSchema),z.lazy(() => EnumGameserverProviderFieldUpdateOperationsInputSchema) ]).optional(),
  state: z.union([ z.lazy(() => InstanceStateSchema),z.lazy(() => EnumInstanceStateFieldUpdateOperationsInputSchema) ]).optional(),
  instanceId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  socket: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  teams: z.lazy(() => TeamUncheckedUpdateManyWithoutGameserversNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.GameserverUncheckedUpdateInput>;

export const GameserverCreateManyInputSchema: z.ZodType<Prisma.GameserverCreateManyInput> = z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  capacity: z.number().int(),
  hostname: z.string().optional().nullable(),
  provider: z.lazy(() => GameserverProviderSchema).optional(),
  state: z.lazy(() => InstanceStateSchema),
  instanceId: z.string().optional().nullable(),
  socket: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict() as z.ZodType<Prisma.GameserverCreateManyInput>;

export const GameserverUpdateManyMutationInputSchema: z.ZodType<Prisma.GameserverUpdateManyMutationInput> = z.object({
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  capacity: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  hostname: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  provider: z.union([ z.lazy(() => GameserverProviderSchema),z.lazy(() => EnumGameserverProviderFieldUpdateOperationsInputSchema) ]).optional(),
  state: z.union([ z.lazy(() => InstanceStateSchema),z.lazy(() => EnumInstanceStateFieldUpdateOperationsInputSchema) ]).optional(),
  instanceId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  socket: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.GameserverUpdateManyMutationInput>;

export const GameserverUncheckedUpdateManyInputSchema: z.ZodType<Prisma.GameserverUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  capacity: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  hostname: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  provider: z.union([ z.lazy(() => GameserverProviderSchema),z.lazy(() => EnumGameserverProviderFieldUpdateOperationsInputSchema) ]).optional(),
  state: z.union([ z.lazy(() => InstanceStateSchema),z.lazy(() => EnumInstanceStateFieldUpdateOperationsInputSchema) ]).optional(),
  instanceId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  socket: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.GameserverUncheckedUpdateManyInput>;

export const PasswordresetsCreateInputSchema: z.ZodType<Prisma.PasswordresetsCreateInput> = z.object({
  userId: z.string(),
  uuid: z.string().optional(),
  token: z.string().optional()
}).strict() as z.ZodType<Prisma.PasswordresetsCreateInput>;

export const PasswordresetsUncheckedCreateInputSchema: z.ZodType<Prisma.PasswordresetsUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  userId: z.string(),
  uuid: z.string().optional(),
  token: z.string().optional()
}).strict() as z.ZodType<Prisma.PasswordresetsUncheckedCreateInput>;

export const PasswordresetsUpdateInputSchema: z.ZodType<Prisma.PasswordresetsUpdateInput> = z.object({
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.PasswordresetsUpdateInput>;

export const PasswordresetsUncheckedUpdateInputSchema: z.ZodType<Prisma.PasswordresetsUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.PasswordresetsUncheckedUpdateInput>;

export const PasswordresetsCreateManyInputSchema: z.ZodType<Prisma.PasswordresetsCreateManyInput> = z.object({
  id: z.number().int().optional(),
  userId: z.string(),
  uuid: z.string().optional(),
  token: z.string().optional()
}).strict() as z.ZodType<Prisma.PasswordresetsCreateManyInput>;

export const PasswordresetsUpdateManyMutationInputSchema: z.ZodType<Prisma.PasswordresetsUpdateManyMutationInput> = z.object({
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.PasswordresetsUpdateManyMutationInput>;

export const PasswordresetsUncheckedUpdateManyInputSchema: z.ZodType<Prisma.PasswordresetsUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.PasswordresetsUncheckedUpdateManyInput>;

export const SessionCreateInputSchema: z.ZodType<Prisma.SessionCreateInput> = z.object({
  uuid: z.string().optional(),
  challenges: z.union([ z.lazy(() => SessionCreatechallengesInputSchema),z.string().array() ]).optional(),
  name: z.string(),
  status: z.lazy(() => SessionStatusSchema).optional(),
  time_limit: z.number().int().optional().nullable(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutSessionsInputSchema),
  teams: z.lazy(() => TeamCreateNestedManyWithoutSessionsInputSchema).optional()
}).strict() as z.ZodType<Prisma.SessionCreateInput>;

export const SessionUncheckedCreateInputSchema: z.ZodType<Prisma.SessionUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  challenges: z.union([ z.lazy(() => SessionCreatechallengesInputSchema),z.string().array() ]).optional(),
  companyId: z.number().int(),
  name: z.string(),
  status: z.lazy(() => SessionStatusSchema).optional(),
  time_limit: z.number().int().optional().nullable(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  teams: z.lazy(() => TeamUncheckedCreateNestedManyWithoutSessionsInputSchema).optional()
}).strict() as z.ZodType<Prisma.SessionUncheckedCreateInput>;

export const SessionUpdateInputSchema: z.ZodType<Prisma.SessionUpdateInput> = z.object({
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  challenges: z.union([ z.lazy(() => SessionUpdatechallengesInputSchema),z.string().array() ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SessionStatusSchema),z.lazy(() => EnumSessionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  time_limit: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  start: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  end: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  company: z.lazy(() => CompanyUpdateOneRequiredWithoutSessionsNestedInputSchema).optional(),
  teams: z.lazy(() => TeamUpdateManyWithoutSessionsNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.SessionUpdateInput>;

export const SessionUncheckedUpdateInputSchema: z.ZodType<Prisma.SessionUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  challenges: z.union([ z.lazy(() => SessionUpdatechallengesInputSchema),z.string().array() ]).optional(),
  companyId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SessionStatusSchema),z.lazy(() => EnumSessionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  time_limit: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  start: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  end: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  teams: z.lazy(() => TeamUncheckedUpdateManyWithoutSessionsNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.SessionUncheckedUpdateInput>;

export const SessionCreateManyInputSchema: z.ZodType<Prisma.SessionCreateManyInput> = z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  challenges: z.union([ z.lazy(() => SessionCreatechallengesInputSchema),z.string().array() ]).optional(),
  companyId: z.number().int(),
  name: z.string(),
  status: z.lazy(() => SessionStatusSchema).optional(),
  time_limit: z.number().int().optional().nullable(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict() as z.ZodType<Prisma.SessionCreateManyInput>;

export const SessionUpdateManyMutationInputSchema: z.ZodType<Prisma.SessionUpdateManyMutationInput> = z.object({
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  challenges: z.union([ z.lazy(() => SessionUpdatechallengesInputSchema),z.string().array() ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SessionStatusSchema),z.lazy(() => EnumSessionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  time_limit: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  start: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  end: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.SessionUpdateManyMutationInput>;

export const SessionUncheckedUpdateManyInputSchema: z.ZodType<Prisma.SessionUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  challenges: z.union([ z.lazy(() => SessionUpdatechallengesInputSchema),z.string().array() ]).optional(),
  companyId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SessionStatusSchema),z.lazy(() => EnumSessionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  time_limit: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  start: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  end: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.SessionUncheckedUpdateManyInput>;

export const TeamCreateInputSchema: z.ZodType<Prisma.TeamCreateInput> = z.object({
  uuid: z.string().optional(),
  connected: z.boolean().optional(),
  currentChallenge: z.number().int().optional(),
  challengeStatus: z.lazy(() => ChallengeStatusSchema).optional(),
  licenceUsed: z.number().int().optional(),
  max_players: z.number().int().optional(),
  players: z.union([ z.lazy(() => TeamCreateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.lazy(() => TeamStatusSchema).optional(),
  token: z.string(),
  spectoken: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  events: z.lazy(() => EventCreateNestedManyWithoutTeamInputSchema).optional(),
  positions: z.lazy(() => PositionCreateNestedManyWithoutTeamInputSchema).optional(),
  gameservers: z.lazy(() => GameserverCreateNestedOneWithoutTeamsInputSchema).optional(),
  sessions: z.lazy(() => SessionCreateNestedOneWithoutTeamsInputSchema)
}).strict() as z.ZodType<Prisma.TeamCreateInput>;

export const TeamUncheckedCreateInputSchema: z.ZodType<Prisma.TeamUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  connected: z.boolean().optional(),
  currentChallenge: z.number().int().optional(),
  challengeStatus: z.lazy(() => ChallengeStatusSchema).optional(),
  licenceUsed: z.number().int().optional(),
  max_players: z.number().int().optional(),
  players: z.union([ z.lazy(() => TeamCreateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.lazy(() => TeamStatusSchema).optional(),
  token: z.string(),
  spectoken: z.string(),
  gameserversId: z.number().int().optional().nullable(),
  sessionsId: z.number().int(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  events: z.lazy(() => EventUncheckedCreateNestedManyWithoutTeamInputSchema).optional(),
  positions: z.lazy(() => PositionUncheckedCreateNestedManyWithoutTeamInputSchema).optional()
}).strict() as z.ZodType<Prisma.TeamUncheckedCreateInput>;

export const TeamUpdateInputSchema: z.ZodType<Prisma.TeamUpdateInput> = z.object({
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  connected: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  currentChallenge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  challengeStatus: z.union([ z.lazy(() => ChallengeStatusSchema),z.lazy(() => EnumChallengeStatusFieldUpdateOperationsInputSchema) ]).optional(),
  licenceUsed: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  max_players: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  players: z.union([ z.lazy(() => TeamUpdateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.union([ z.lazy(() => TeamStatusSchema),z.lazy(() => EnumTeamStatusFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  spectoken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  events: z.lazy(() => EventUpdateManyWithoutTeamNestedInputSchema).optional(),
  positions: z.lazy(() => PositionUpdateManyWithoutTeamNestedInputSchema).optional(),
  gameservers: z.lazy(() => GameserverUpdateOneWithoutTeamsNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUpdateOneRequiredWithoutTeamsNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.TeamUpdateInput>;

export const TeamUncheckedUpdateInputSchema: z.ZodType<Prisma.TeamUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  connected: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  currentChallenge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  challengeStatus: z.union([ z.lazy(() => ChallengeStatusSchema),z.lazy(() => EnumChallengeStatusFieldUpdateOperationsInputSchema) ]).optional(),
  licenceUsed: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  max_players: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  players: z.union([ z.lazy(() => TeamUpdateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.union([ z.lazy(() => TeamStatusSchema),z.lazy(() => EnumTeamStatusFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  spectoken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  gameserversId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sessionsId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  events: z.lazy(() => EventUncheckedUpdateManyWithoutTeamNestedInputSchema).optional(),
  positions: z.lazy(() => PositionUncheckedUpdateManyWithoutTeamNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.TeamUncheckedUpdateInput>;

export const TeamCreateManyInputSchema: z.ZodType<Prisma.TeamCreateManyInput> = z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  connected: z.boolean().optional(),
  currentChallenge: z.number().int().optional(),
  challengeStatus: z.lazy(() => ChallengeStatusSchema).optional(),
  licenceUsed: z.number().int().optional(),
  max_players: z.number().int().optional(),
  players: z.union([ z.lazy(() => TeamCreateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.lazy(() => TeamStatusSchema).optional(),
  token: z.string(),
  spectoken: z.string(),
  gameserversId: z.number().int().optional().nullable(),
  sessionsId: z.number().int(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict() as z.ZodType<Prisma.TeamCreateManyInput>;

export const TeamUpdateManyMutationInputSchema: z.ZodType<Prisma.TeamUpdateManyMutationInput> = z.object({
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  connected: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  currentChallenge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  challengeStatus: z.union([ z.lazy(() => ChallengeStatusSchema),z.lazy(() => EnumChallengeStatusFieldUpdateOperationsInputSchema) ]).optional(),
  licenceUsed: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  max_players: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  players: z.union([ z.lazy(() => TeamUpdateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.union([ z.lazy(() => TeamStatusSchema),z.lazy(() => EnumTeamStatusFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  spectoken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.TeamUpdateManyMutationInput>;

export const TeamUncheckedUpdateManyInputSchema: z.ZodType<Prisma.TeamUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  connected: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  currentChallenge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  challengeStatus: z.union([ z.lazy(() => ChallengeStatusSchema),z.lazy(() => EnumChallengeStatusFieldUpdateOperationsInputSchema) ]).optional(),
  licenceUsed: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  max_players: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  players: z.union([ z.lazy(() => TeamUpdateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.union([ z.lazy(() => TeamStatusSchema),z.lazy(() => EnumTeamStatusFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  spectoken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  gameserversId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sessionsId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.TeamUncheckedUpdateManyInput>;

export const UserCreateInputSchema: z.ZodType<Prisma.UserCreateInput> = z.object({
  uuid: z.string().optional(),
  email: z.string(),
  hash: z.string(),
  name: z.string(),
  role: z.lazy(() => RoleSchema),
  salt: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutUsersInputSchema)
}).strict() as z.ZodType<Prisma.UserCreateInput>;

export const UserUncheckedCreateInputSchema: z.ZodType<Prisma.UserUncheckedCreateInput> = z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  companyId: z.number().int(),
  email: z.string(),
  hash: z.string(),
  name: z.string(),
  role: z.lazy(() => RoleSchema),
  salt: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict() as z.ZodType<Prisma.UserUncheckedCreateInput>;

export const UserUpdateInputSchema: z.ZodType<Prisma.UserUpdateInput> = z.object({
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  hash: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  role: z.union([ z.lazy(() => RoleSchema),z.lazy(() => EnumRoleFieldUpdateOperationsInputSchema) ]).optional(),
  salt: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  company: z.lazy(() => CompanyUpdateOneRequiredWithoutUsersNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.UserUpdateInput>;

export const UserUncheckedUpdateInputSchema: z.ZodType<Prisma.UserUncheckedUpdateInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  hash: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  role: z.union([ z.lazy(() => RoleSchema),z.lazy(() => EnumRoleFieldUpdateOperationsInputSchema) ]).optional(),
  salt: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.UserUncheckedUpdateInput>;

export const UserCreateManyInputSchema: z.ZodType<Prisma.UserCreateManyInput> = z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  companyId: z.number().int(),
  email: z.string(),
  hash: z.string(),
  name: z.string(),
  role: z.lazy(() => RoleSchema),
  salt: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict() as z.ZodType<Prisma.UserCreateManyInput>;

export const UserUpdateManyMutationInputSchema: z.ZodType<Prisma.UserUpdateManyMutationInput> = z.object({
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  hash: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  role: z.union([ z.lazy(() => RoleSchema),z.lazy(() => EnumRoleFieldUpdateOperationsInputSchema) ]).optional(),
  salt: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.UserUpdateManyMutationInput>;

export const UserUncheckedUpdateManyInputSchema: z.ZodType<Prisma.UserUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  hash: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  role: z.union([ z.lazy(() => RoleSchema),z.lazy(() => EnumRoleFieldUpdateOperationsInputSchema) ]).optional(),
  salt: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.UserUncheckedUpdateManyInput>;

export const IntFilterSchema: z.ZodType<Prisma.IntFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntFilterSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.IntFilter>;

export const JsonFilterSchema: z.ZodType<Prisma.JsonFilter> = z.object({
  equals: InputJsonValueSchema.optional(),
  path: z.string().array().optional(),
  string_contains: z.string().optional(),
  string_starts_with: z.string().optional(),
  string_ends_with: z.string().optional(),
  array_contains: InputJsonValueSchema.optional().nullable(),
  array_starts_with: InputJsonValueSchema.optional().nullable(),
  array_ends_with: InputJsonValueSchema.optional().nullable(),
  lt: InputJsonValueSchema.optional(),
  lte: InputJsonValueSchema.optional(),
  gt: InputJsonValueSchema.optional(),
  gte: InputJsonValueSchema.optional(),
  not: InputJsonValueSchema.optional()
}).strict() as z.ZodType<Prisma.JsonFilter>;

export const TeamRelationFilterSchema: z.ZodType<Prisma.TeamRelationFilter> = z.object({
  is: z.lazy(() => TeamWhereInputSchema).optional(),
  isNot: z.lazy(() => TeamWhereInputSchema).optional()
}).strict() as z.ZodType<Prisma.TeamRelationFilter>;

export const PositionCountOrderByAggregateInputSchema: z.ZodType<Prisma.PositionCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  locations: z.lazy(() => SortOrderSchema).optional(),
  teamId: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.PositionCountOrderByAggregateInput>;

export const PositionAvgOrderByAggregateInputSchema: z.ZodType<Prisma.PositionAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  teamId: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.PositionAvgOrderByAggregateInput>;

export const PositionMaxOrderByAggregateInputSchema: z.ZodType<Prisma.PositionMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  teamId: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.PositionMaxOrderByAggregateInput>;

export const PositionMinOrderByAggregateInputSchema: z.ZodType<Prisma.PositionMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  teamId: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.PositionMinOrderByAggregateInput>;

export const PositionSumOrderByAggregateInputSchema: z.ZodType<Prisma.PositionSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  teamId: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.PositionSumOrderByAggregateInput>;

export const IntWithAggregatesFilterSchema: z.ZodType<Prisma.IntWithAggregatesFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatFilterSchema).optional(),
  _sum: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedIntFilterSchema).optional(),
  _max: z.lazy(() => NestedIntFilterSchema).optional()
}).strict() as z.ZodType<Prisma.IntWithAggregatesFilter>;

export const JsonWithAggregatesFilterSchema: z.ZodType<Prisma.JsonWithAggregatesFilter> = z.object({
  equals: InputJsonValueSchema.optional(),
  path: z.string().array().optional(),
  string_contains: z.string().optional(),
  string_starts_with: z.string().optional(),
  string_ends_with: z.string().optional(),
  array_contains: InputJsonValueSchema.optional().nullable(),
  array_starts_with: InputJsonValueSchema.optional().nullable(),
  array_ends_with: InputJsonValueSchema.optional().nullable(),
  lt: InputJsonValueSchema.optional(),
  lte: InputJsonValueSchema.optional(),
  gt: InputJsonValueSchema.optional(),
  gte: InputJsonValueSchema.optional(),
  not: InputJsonValueSchema.optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedJsonFilterSchema).optional(),
  _max: z.lazy(() => NestedJsonFilterSchema).optional()
}).strict() as z.ZodType<Prisma.JsonWithAggregatesFilter>;

export const StringFilterSchema: z.ZodType<Prisma.StringFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  mode: z.lazy(() => QueryModeSchema).optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringFilterSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.StringFilter>;

export const DateTimeFilterSchema: z.ZodType<Prisma.DateTimeFilter> = z.object({
  equals: z.coerce.date().optional(),
  in: z.coerce.date().array().optional(),
  notIn: z.coerce.date().array().optional(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeFilterSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.DateTimeFilter>;

export const EventCountOrderByAggregateInputSchema: z.ZodType<Prisma.EventCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  LogType: z.lazy(() => SortOrderSchema).optional(),
  Parameter: z.lazy(() => SortOrderSchema).optional(),
  Player: z.lazy(() => SortOrderSchema).optional(),
  TriggeredObject: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  teamId: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.EventCountOrderByAggregateInput>;

export const EventAvgOrderByAggregateInputSchema: z.ZodType<Prisma.EventAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  Player: z.lazy(() => SortOrderSchema).optional(),
  teamId: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.EventAvgOrderByAggregateInput>;

export const EventMaxOrderByAggregateInputSchema: z.ZodType<Prisma.EventMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  LogType: z.lazy(() => SortOrderSchema).optional(),
  Parameter: z.lazy(() => SortOrderSchema).optional(),
  Player: z.lazy(() => SortOrderSchema).optional(),
  TriggeredObject: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  teamId: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.EventMaxOrderByAggregateInput>;

export const EventMinOrderByAggregateInputSchema: z.ZodType<Prisma.EventMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  LogType: z.lazy(() => SortOrderSchema).optional(),
  Parameter: z.lazy(() => SortOrderSchema).optional(),
  Player: z.lazy(() => SortOrderSchema).optional(),
  TriggeredObject: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  teamId: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.EventMinOrderByAggregateInput>;

export const EventSumOrderByAggregateInputSchema: z.ZodType<Prisma.EventSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  Player: z.lazy(() => SortOrderSchema).optional(),
  teamId: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.EventSumOrderByAggregateInput>;

export const StringWithAggregatesFilterSchema: z.ZodType<Prisma.StringWithAggregatesFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  mode: z.lazy(() => QueryModeSchema).optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedStringFilterSchema).optional(),
  _max: z.lazy(() => NestedStringFilterSchema).optional()
}).strict() as z.ZodType<Prisma.StringWithAggregatesFilter>;

export const DateTimeWithAggregatesFilterSchema: z.ZodType<Prisma.DateTimeWithAggregatesFilter> = z.object({
  equals: z.coerce.date().optional(),
  in: z.coerce.date().array().optional(),
  notIn: z.coerce.date().array().optional(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedDateTimeFilterSchema).optional(),
  _max: z.lazy(() => NestedDateTimeFilterSchema).optional()
}).strict() as z.ZodType<Prisma.DateTimeWithAggregatesFilter>;

export const ChallengeCountOrderByAggregateInputSchema: z.ZodType<Prisma.ChallengeCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  display: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  order: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.ChallengeCountOrderByAggregateInput>;

export const ChallengeAvgOrderByAggregateInputSchema: z.ZodType<Prisma.ChallengeAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  order: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.ChallengeAvgOrderByAggregateInput>;

export const ChallengeMaxOrderByAggregateInputSchema: z.ZodType<Prisma.ChallengeMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  display: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  order: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.ChallengeMaxOrderByAggregateInput>;

export const ChallengeMinOrderByAggregateInputSchema: z.ZodType<Prisma.ChallengeMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  display: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  order: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.ChallengeMinOrderByAggregateInput>;

export const ChallengeSumOrderByAggregateInputSchema: z.ZodType<Prisma.ChallengeSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  order: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.ChallengeSumOrderByAggregateInput>;

export const UuidFilterSchema: z.ZodType<Prisma.UuidFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  mode: z.lazy(() => QueryModeSchema).optional(),
  not: z.union([ z.string(),z.lazy(() => NestedUuidFilterSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.UuidFilter>;

export const UserListRelationFilterSchema: z.ZodType<Prisma.UserListRelationFilter> = z.object({
  every: z.lazy(() => UserWhereInputSchema).optional(),
  some: z.lazy(() => UserWhereInputSchema).optional(),
  none: z.lazy(() => UserWhereInputSchema).optional()
}).strict() as z.ZodType<Prisma.UserListRelationFilter>;

export const SessionListRelationFilterSchema: z.ZodType<Prisma.SessionListRelationFilter> = z.object({
  every: z.lazy(() => SessionWhereInputSchema).optional(),
  some: z.lazy(() => SessionWhereInputSchema).optional(),
  none: z.lazy(() => SessionWhereInputSchema).optional()
}).strict() as z.ZodType<Prisma.SessionListRelationFilter>;

export const UserOrderByRelationAggregateInputSchema: z.ZodType<Prisma.UserOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.UserOrderByRelationAggregateInput>;

export const SessionOrderByRelationAggregateInputSchema: z.ZodType<Prisma.SessionOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.SessionOrderByRelationAggregateInput>;

export const CompanyCountOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.CompanyCountOrderByAggregateInput>;

export const CompanyAvgOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.CompanyAvgOrderByAggregateInput>;

export const CompanyMaxOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.CompanyMaxOrderByAggregateInput>;

export const CompanyMinOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.CompanyMinOrderByAggregateInput>;

export const CompanySumOrderByAggregateInputSchema: z.ZodType<Prisma.CompanySumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.CompanySumOrderByAggregateInput>;

export const UuidWithAggregatesFilterSchema: z.ZodType<Prisma.UuidWithAggregatesFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  mode: z.lazy(() => QueryModeSchema).optional(),
  not: z.union([ z.string(),z.lazy(() => NestedUuidWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedStringFilterSchema).optional(),
  _max: z.lazy(() => NestedStringFilterSchema).optional()
}).strict() as z.ZodType<Prisma.UuidWithAggregatesFilter>;

export const StringNullableFilterSchema: z.ZodType<Prisma.StringNullableFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.string().array().optional().nullable(),
  notIn: z.string().array().optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  mode: z.lazy(() => QueryModeSchema).optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringNullableFilterSchema) ]).optional().nullable(),
}).strict() as z.ZodType<Prisma.StringNullableFilter>;

export const EnumGameserverProviderFilterSchema: z.ZodType<Prisma.EnumGameserverProviderFilter> = z.object({
  equals: z.lazy(() => GameserverProviderSchema).optional(),
  in: z.lazy(() => GameserverProviderSchema).array().optional(),
  notIn: z.lazy(() => GameserverProviderSchema).array().optional(),
  not: z.union([ z.lazy(() => GameserverProviderSchema),z.lazy(() => NestedEnumGameserverProviderFilterSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.EnumGameserverProviderFilter>;

export const EnumInstanceStateFilterSchema: z.ZodType<Prisma.EnumInstanceStateFilter> = z.object({
  equals: z.lazy(() => InstanceStateSchema).optional(),
  in: z.lazy(() => InstanceStateSchema).array().optional(),
  notIn: z.lazy(() => InstanceStateSchema).array().optional(),
  not: z.union([ z.lazy(() => InstanceStateSchema),z.lazy(() => NestedEnumInstanceStateFilterSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.EnumInstanceStateFilter>;

export const TeamListRelationFilterSchema: z.ZodType<Prisma.TeamListRelationFilter> = z.object({
  every: z.lazy(() => TeamWhereInputSchema).optional(),
  some: z.lazy(() => TeamWhereInputSchema).optional(),
  none: z.lazy(() => TeamWhereInputSchema).optional()
}).strict() as z.ZodType<Prisma.TeamListRelationFilter>;

export const SortOrderInputSchema: z.ZodType<Prisma.SortOrderInput> = z.object({
  sort: z.lazy(() => SortOrderSchema),
  nulls: z.lazy(() => NullsOrderSchema).optional()
}).strict() as z.ZodType<Prisma.SortOrderInput>;

export const TeamOrderByRelationAggregateInputSchema: z.ZodType<Prisma.TeamOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.TeamOrderByRelationAggregateInput>;

export const GameserverCountOrderByAggregateInputSchema: z.ZodType<Prisma.GameserverCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  capacity: z.lazy(() => SortOrderSchema).optional(),
  hostname: z.lazy(() => SortOrderSchema).optional(),
  provider: z.lazy(() => SortOrderSchema).optional(),
  state: z.lazy(() => SortOrderSchema).optional(),
  instanceId: z.lazy(() => SortOrderSchema).optional(),
  socket: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.GameserverCountOrderByAggregateInput>;

export const GameserverAvgOrderByAggregateInputSchema: z.ZodType<Prisma.GameserverAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  capacity: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.GameserverAvgOrderByAggregateInput>;

export const GameserverMaxOrderByAggregateInputSchema: z.ZodType<Prisma.GameserverMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  capacity: z.lazy(() => SortOrderSchema).optional(),
  hostname: z.lazy(() => SortOrderSchema).optional(),
  provider: z.lazy(() => SortOrderSchema).optional(),
  state: z.lazy(() => SortOrderSchema).optional(),
  instanceId: z.lazy(() => SortOrderSchema).optional(),
  socket: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.GameserverMaxOrderByAggregateInput>;

export const GameserverMinOrderByAggregateInputSchema: z.ZodType<Prisma.GameserverMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  capacity: z.lazy(() => SortOrderSchema).optional(),
  hostname: z.lazy(() => SortOrderSchema).optional(),
  provider: z.lazy(() => SortOrderSchema).optional(),
  state: z.lazy(() => SortOrderSchema).optional(),
  instanceId: z.lazy(() => SortOrderSchema).optional(),
  socket: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.GameserverMinOrderByAggregateInput>;

export const GameserverSumOrderByAggregateInputSchema: z.ZodType<Prisma.GameserverSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  capacity: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.GameserverSumOrderByAggregateInput>;

export const StringNullableWithAggregatesFilterSchema: z.ZodType<Prisma.StringNullableWithAggregatesFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.string().array().optional().nullable(),
  notIn: z.string().array().optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  mode: z.lazy(() => QueryModeSchema).optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedStringNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedStringNullableFilterSchema).optional()
}).strict() as z.ZodType<Prisma.StringNullableWithAggregatesFilter>;

export const EnumGameserverProviderWithAggregatesFilterSchema: z.ZodType<Prisma.EnumGameserverProviderWithAggregatesFilter> = z.object({
  equals: z.lazy(() => GameserverProviderSchema).optional(),
  in: z.lazy(() => GameserverProviderSchema).array().optional(),
  notIn: z.lazy(() => GameserverProviderSchema).array().optional(),
  not: z.union([ z.lazy(() => GameserverProviderSchema),z.lazy(() => NestedEnumGameserverProviderWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumGameserverProviderFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumGameserverProviderFilterSchema).optional()
}).strict() as z.ZodType<Prisma.EnumGameserverProviderWithAggregatesFilter>;

export const EnumInstanceStateWithAggregatesFilterSchema: z.ZodType<Prisma.EnumInstanceStateWithAggregatesFilter> = z.object({
  equals: z.lazy(() => InstanceStateSchema).optional(),
  in: z.lazy(() => InstanceStateSchema).array().optional(),
  notIn: z.lazy(() => InstanceStateSchema).array().optional(),
  not: z.union([ z.lazy(() => InstanceStateSchema),z.lazy(() => NestedEnumInstanceStateWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumInstanceStateFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumInstanceStateFilterSchema).optional()
}).strict() as z.ZodType<Prisma.EnumInstanceStateWithAggregatesFilter>;

export const PasswordresetsCountOrderByAggregateInputSchema: z.ZodType<Prisma.PasswordresetsCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.PasswordresetsCountOrderByAggregateInput>;

export const PasswordresetsAvgOrderByAggregateInputSchema: z.ZodType<Prisma.PasswordresetsAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.PasswordresetsAvgOrderByAggregateInput>;

export const PasswordresetsMaxOrderByAggregateInputSchema: z.ZodType<Prisma.PasswordresetsMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.PasswordresetsMaxOrderByAggregateInput>;

export const PasswordresetsMinOrderByAggregateInputSchema: z.ZodType<Prisma.PasswordresetsMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.PasswordresetsMinOrderByAggregateInput>;

export const PasswordresetsSumOrderByAggregateInputSchema: z.ZodType<Prisma.PasswordresetsSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.PasswordresetsSumOrderByAggregateInput>;

export const StringNullableListFilterSchema: z.ZodType<Prisma.StringNullableListFilter> = z.object({
  equals: z.string().array().optional().nullable(),
  has: z.string().optional().nullable(),
  hasEvery: z.string().array().optional(),
  hasSome: z.string().array().optional(),
  isEmpty: z.boolean().optional()
}).strict() as z.ZodType<Prisma.StringNullableListFilter>;

export const EnumSessionStatusFilterSchema: z.ZodType<Prisma.EnumSessionStatusFilter> = z.object({
  equals: z.lazy(() => SessionStatusSchema).optional(),
  in: z.lazy(() => SessionStatusSchema).array().optional(),
  notIn: z.lazy(() => SessionStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => SessionStatusSchema),z.lazy(() => NestedEnumSessionStatusFilterSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.EnumSessionStatusFilter>;

export const IntNullableFilterSchema: z.ZodType<Prisma.IntNullableFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntNullableFilterSchema) ]).optional().nullable(),
}).strict() as z.ZodType<Prisma.IntNullableFilter>;

export const CompanyRelationFilterSchema: z.ZodType<Prisma.CompanyRelationFilter> = z.object({
  is: z.lazy(() => CompanyWhereInputSchema).optional(),
  isNot: z.lazy(() => CompanyWhereInputSchema).optional()
}).strict() as z.ZodType<Prisma.CompanyRelationFilter>;

export const SessionCountOrderByAggregateInputSchema: z.ZodType<Prisma.SessionCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  challenges: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  time_limit: z.lazy(() => SortOrderSchema).optional(),
  start: z.lazy(() => SortOrderSchema).optional(),
  end: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.SessionCountOrderByAggregateInput>;

export const SessionAvgOrderByAggregateInputSchema: z.ZodType<Prisma.SessionAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  time_limit: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.SessionAvgOrderByAggregateInput>;

export const SessionMaxOrderByAggregateInputSchema: z.ZodType<Prisma.SessionMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  time_limit: z.lazy(() => SortOrderSchema).optional(),
  start: z.lazy(() => SortOrderSchema).optional(),
  end: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.SessionMaxOrderByAggregateInput>;

export const SessionMinOrderByAggregateInputSchema: z.ZodType<Prisma.SessionMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  time_limit: z.lazy(() => SortOrderSchema).optional(),
  start: z.lazy(() => SortOrderSchema).optional(),
  end: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.SessionMinOrderByAggregateInput>;

export const SessionSumOrderByAggregateInputSchema: z.ZodType<Prisma.SessionSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  time_limit: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.SessionSumOrderByAggregateInput>;

export const EnumSessionStatusWithAggregatesFilterSchema: z.ZodType<Prisma.EnumSessionStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => SessionStatusSchema).optional(),
  in: z.lazy(() => SessionStatusSchema).array().optional(),
  notIn: z.lazy(() => SessionStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => SessionStatusSchema),z.lazy(() => NestedEnumSessionStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumSessionStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumSessionStatusFilterSchema).optional()
}).strict() as z.ZodType<Prisma.EnumSessionStatusWithAggregatesFilter>;

export const IntNullableWithAggregatesFilterSchema: z.ZodType<Prisma.IntNullableWithAggregatesFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _sum: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedIntNullableFilterSchema).optional()
}).strict() as z.ZodType<Prisma.IntNullableWithAggregatesFilter>;

export const BoolFilterSchema: z.ZodType<Prisma.BoolFilter> = z.object({
  equals: z.boolean().optional(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolFilterSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.BoolFilter>;

export const EnumChallengeStatusFilterSchema: z.ZodType<Prisma.EnumChallengeStatusFilter> = z.object({
  equals: z.lazy(() => ChallengeStatusSchema).optional(),
  in: z.lazy(() => ChallengeStatusSchema).array().optional(),
  notIn: z.lazy(() => ChallengeStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => ChallengeStatusSchema),z.lazy(() => NestedEnumChallengeStatusFilterSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.EnumChallengeStatusFilter>;

export const JsonNullableListFilterSchema: z.ZodType<Prisma.JsonNullableListFilter> = z.object({
  equals: InputJsonValueSchema.array().optional().nullable(),
  has: InputJsonValueSchema.optional().nullable(),
  hasEvery: InputJsonValueSchema.array().optional(),
  hasSome: InputJsonValueSchema.array().optional(),
  isEmpty: z.boolean().optional()
}).strict() as z.ZodType<Prisma.JsonNullableListFilter>;

export const EnumTeamStatusFilterSchema: z.ZodType<Prisma.EnumTeamStatusFilter> = z.object({
  equals: z.lazy(() => TeamStatusSchema).optional(),
  in: z.lazy(() => TeamStatusSchema).array().optional(),
  notIn: z.lazy(() => TeamStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => TeamStatusSchema),z.lazy(() => NestedEnumTeamStatusFilterSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.EnumTeamStatusFilter>;

export const EventListRelationFilterSchema: z.ZodType<Prisma.EventListRelationFilter> = z.object({
  every: z.lazy(() => EventWhereInputSchema).optional(),
  some: z.lazy(() => EventWhereInputSchema).optional(),
  none: z.lazy(() => EventWhereInputSchema).optional()
}).strict() as z.ZodType<Prisma.EventListRelationFilter>;

export const PositionListRelationFilterSchema: z.ZodType<Prisma.PositionListRelationFilter> = z.object({
  every: z.lazy(() => PositionWhereInputSchema).optional(),
  some: z.lazy(() => PositionWhereInputSchema).optional(),
  none: z.lazy(() => PositionWhereInputSchema).optional()
}).strict() as z.ZodType<Prisma.PositionListRelationFilter>;

export const GameserverNullableRelationFilterSchema: z.ZodType<Prisma.GameserverNullableRelationFilter> = z.object({
  is: z.lazy(() => GameserverWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => GameserverWhereInputSchema).optional().nullable()
}).strict() as z.ZodType<Prisma.GameserverNullableRelationFilter>;

export const SessionRelationFilterSchema: z.ZodType<Prisma.SessionRelationFilter> = z.object({
  is: z.lazy(() => SessionWhereInputSchema).optional(),
  isNot: z.lazy(() => SessionWhereInputSchema).optional()
}).strict() as z.ZodType<Prisma.SessionRelationFilter>;

export const EventOrderByRelationAggregateInputSchema: z.ZodType<Prisma.EventOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.EventOrderByRelationAggregateInput>;

export const PositionOrderByRelationAggregateInputSchema: z.ZodType<Prisma.PositionOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.PositionOrderByRelationAggregateInput>;

export const TeamCountOrderByAggregateInputSchema: z.ZodType<Prisma.TeamCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  connected: z.lazy(() => SortOrderSchema).optional(),
  currentChallenge: z.lazy(() => SortOrderSchema).optional(),
  challengeStatus: z.lazy(() => SortOrderSchema).optional(),
  licenceUsed: z.lazy(() => SortOrderSchema).optional(),
  max_players: z.lazy(() => SortOrderSchema).optional(),
  players: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional(),
  spectoken: z.lazy(() => SortOrderSchema).optional(),
  gameserversId: z.lazy(() => SortOrderSchema).optional(),
  sessionsId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.TeamCountOrderByAggregateInput>;

export const TeamAvgOrderByAggregateInputSchema: z.ZodType<Prisma.TeamAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  currentChallenge: z.lazy(() => SortOrderSchema).optional(),
  licenceUsed: z.lazy(() => SortOrderSchema).optional(),
  max_players: z.lazy(() => SortOrderSchema).optional(),
  gameserversId: z.lazy(() => SortOrderSchema).optional(),
  sessionsId: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.TeamAvgOrderByAggregateInput>;

export const TeamMaxOrderByAggregateInputSchema: z.ZodType<Prisma.TeamMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  connected: z.lazy(() => SortOrderSchema).optional(),
  currentChallenge: z.lazy(() => SortOrderSchema).optional(),
  challengeStatus: z.lazy(() => SortOrderSchema).optional(),
  licenceUsed: z.lazy(() => SortOrderSchema).optional(),
  max_players: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional(),
  spectoken: z.lazy(() => SortOrderSchema).optional(),
  gameserversId: z.lazy(() => SortOrderSchema).optional(),
  sessionsId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.TeamMaxOrderByAggregateInput>;

export const TeamMinOrderByAggregateInputSchema: z.ZodType<Prisma.TeamMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  connected: z.lazy(() => SortOrderSchema).optional(),
  currentChallenge: z.lazy(() => SortOrderSchema).optional(),
  challengeStatus: z.lazy(() => SortOrderSchema).optional(),
  licenceUsed: z.lazy(() => SortOrderSchema).optional(),
  max_players: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional(),
  spectoken: z.lazy(() => SortOrderSchema).optional(),
  gameserversId: z.lazy(() => SortOrderSchema).optional(),
  sessionsId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.TeamMinOrderByAggregateInput>;

export const TeamSumOrderByAggregateInputSchema: z.ZodType<Prisma.TeamSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  currentChallenge: z.lazy(() => SortOrderSchema).optional(),
  licenceUsed: z.lazy(() => SortOrderSchema).optional(),
  max_players: z.lazy(() => SortOrderSchema).optional(),
  gameserversId: z.lazy(() => SortOrderSchema).optional(),
  sessionsId: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.TeamSumOrderByAggregateInput>;

export const BoolWithAggregatesFilterSchema: z.ZodType<Prisma.BoolWithAggregatesFilter> = z.object({
  equals: z.boolean().optional(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedBoolFilterSchema).optional(),
  _max: z.lazy(() => NestedBoolFilterSchema).optional()
}).strict() as z.ZodType<Prisma.BoolWithAggregatesFilter>;

export const EnumChallengeStatusWithAggregatesFilterSchema: z.ZodType<Prisma.EnumChallengeStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => ChallengeStatusSchema).optional(),
  in: z.lazy(() => ChallengeStatusSchema).array().optional(),
  notIn: z.lazy(() => ChallengeStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => ChallengeStatusSchema),z.lazy(() => NestedEnumChallengeStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumChallengeStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumChallengeStatusFilterSchema).optional()
}).strict() as z.ZodType<Prisma.EnumChallengeStatusWithAggregatesFilter>;

export const EnumTeamStatusWithAggregatesFilterSchema: z.ZodType<Prisma.EnumTeamStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => TeamStatusSchema).optional(),
  in: z.lazy(() => TeamStatusSchema).array().optional(),
  notIn: z.lazy(() => TeamStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => TeamStatusSchema),z.lazy(() => NestedEnumTeamStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumTeamStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumTeamStatusFilterSchema).optional()
}).strict() as z.ZodType<Prisma.EnumTeamStatusWithAggregatesFilter>;

export const EnumRoleFilterSchema: z.ZodType<Prisma.EnumRoleFilter> = z.object({
  equals: z.lazy(() => RoleSchema).optional(),
  in: z.lazy(() => RoleSchema).array().optional(),
  notIn: z.lazy(() => RoleSchema).array().optional(),
  not: z.union([ z.lazy(() => RoleSchema),z.lazy(() => NestedEnumRoleFilterSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.EnumRoleFilter>;

export const UserCountOrderByAggregateInputSchema: z.ZodType<Prisma.UserCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  hash: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  role: z.lazy(() => SortOrderSchema).optional(),
  salt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.UserCountOrderByAggregateInput>;

export const UserAvgOrderByAggregateInputSchema: z.ZodType<Prisma.UserAvgOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.UserAvgOrderByAggregateInput>;

export const UserMaxOrderByAggregateInputSchema: z.ZodType<Prisma.UserMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  hash: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  role: z.lazy(() => SortOrderSchema).optional(),
  salt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.UserMaxOrderByAggregateInput>;

export const UserMinOrderByAggregateInputSchema: z.ZodType<Prisma.UserMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  uuid: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  hash: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  role: z.lazy(() => SortOrderSchema).optional(),
  salt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.UserMinOrderByAggregateInput>;

export const UserSumOrderByAggregateInputSchema: z.ZodType<Prisma.UserSumOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional()
}).strict() as z.ZodType<Prisma.UserSumOrderByAggregateInput>;

export const EnumRoleWithAggregatesFilterSchema: z.ZodType<Prisma.EnumRoleWithAggregatesFilter> = z.object({
  equals: z.lazy(() => RoleSchema).optional(),
  in: z.lazy(() => RoleSchema).array().optional(),
  notIn: z.lazy(() => RoleSchema).array().optional(),
  not: z.union([ z.lazy(() => RoleSchema),z.lazy(() => NestedEnumRoleWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumRoleFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumRoleFilterSchema).optional()
}).strict() as z.ZodType<Prisma.EnumRoleWithAggregatesFilter>;

export const TeamCreateNestedOneWithoutPositionsInputSchema: z.ZodType<Prisma.TeamCreateNestedOneWithoutPositionsInput> = z.object({
  create: z.union([ z.lazy(() => TeamCreateWithoutPositionsInputSchema),z.lazy(() => TeamUncheckedCreateWithoutPositionsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => TeamCreateOrConnectWithoutPositionsInputSchema).optional(),
  connect: z.lazy(() => TeamWhereUniqueInputSchema).optional()
}).strict() as z.ZodType<Prisma.TeamCreateNestedOneWithoutPositionsInput>;

export const TeamUpdateOneRequiredWithoutPositionsNestedInputSchema: z.ZodType<Prisma.TeamUpdateOneRequiredWithoutPositionsNestedInput> = z.object({
  create: z.union([ z.lazy(() => TeamCreateWithoutPositionsInputSchema),z.lazy(() => TeamUncheckedCreateWithoutPositionsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => TeamCreateOrConnectWithoutPositionsInputSchema).optional(),
  upsert: z.lazy(() => TeamUpsertWithoutPositionsInputSchema).optional(),
  connect: z.lazy(() => TeamWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => TeamUpdateToOneWithWhereWithoutPositionsInputSchema),z.lazy(() => TeamUpdateWithoutPositionsInputSchema),z.lazy(() => TeamUncheckedUpdateWithoutPositionsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.TeamUpdateOneRequiredWithoutPositionsNestedInput>;

export const IntFieldUpdateOperationsInputSchema: z.ZodType<Prisma.IntFieldUpdateOperationsInput> = z.object({
  set: z.number().optional(),
  increment: z.number().optional(),
  decrement: z.number().optional(),
  multiply: z.number().optional(),
  divide: z.number().optional()
}).strict() as z.ZodType<Prisma.IntFieldUpdateOperationsInput>;

export const TeamCreateNestedOneWithoutEventsInputSchema: z.ZodType<Prisma.TeamCreateNestedOneWithoutEventsInput> = z.object({
  create: z.union([ z.lazy(() => TeamCreateWithoutEventsInputSchema),z.lazy(() => TeamUncheckedCreateWithoutEventsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => TeamCreateOrConnectWithoutEventsInputSchema).optional(),
  connect: z.lazy(() => TeamWhereUniqueInputSchema).optional()
}).strict() as z.ZodType<Prisma.TeamCreateNestedOneWithoutEventsInput>;

export const StringFieldUpdateOperationsInputSchema: z.ZodType<Prisma.StringFieldUpdateOperationsInput> = z.object({
  set: z.string().optional()
}).strict() as z.ZodType<Prisma.StringFieldUpdateOperationsInput>;

export const DateTimeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.DateTimeFieldUpdateOperationsInput> = z.object({
  set: z.coerce.date().optional()
}).strict() as z.ZodType<Prisma.DateTimeFieldUpdateOperationsInput>;

export const TeamUpdateOneRequiredWithoutEventsNestedInputSchema: z.ZodType<Prisma.TeamUpdateOneRequiredWithoutEventsNestedInput> = z.object({
  create: z.union([ z.lazy(() => TeamCreateWithoutEventsInputSchema),z.lazy(() => TeamUncheckedCreateWithoutEventsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => TeamCreateOrConnectWithoutEventsInputSchema).optional(),
  upsert: z.lazy(() => TeamUpsertWithoutEventsInputSchema).optional(),
  connect: z.lazy(() => TeamWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => TeamUpdateToOneWithWhereWithoutEventsInputSchema),z.lazy(() => TeamUpdateWithoutEventsInputSchema),z.lazy(() => TeamUncheckedUpdateWithoutEventsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.TeamUpdateOneRequiredWithoutEventsNestedInput>;

export const UserCreateNestedManyWithoutCompanyInputSchema: z.ZodType<Prisma.UserCreateNestedManyWithoutCompanyInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCompanyInputSchema),z.lazy(() => UserCreateWithoutCompanyInputSchema).array(),z.lazy(() => UserUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => UserUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => UserCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => UserCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => UserCreateManyCompanyInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
}).strict() as z.ZodType<Prisma.UserCreateNestedManyWithoutCompanyInput>;

export const SessionCreateNestedManyWithoutCompanyInputSchema: z.ZodType<Prisma.SessionCreateNestedManyWithoutCompanyInput> = z.object({
  create: z.union([ z.lazy(() => SessionCreateWithoutCompanyInputSchema),z.lazy(() => SessionCreateWithoutCompanyInputSchema).array(),z.lazy(() => SessionUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => SessionUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SessionCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => SessionCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SessionCreateManyCompanyInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
}).strict() as z.ZodType<Prisma.SessionCreateNestedManyWithoutCompanyInput>;

export const UserUncheckedCreateNestedManyWithoutCompanyInputSchema: z.ZodType<Prisma.UserUncheckedCreateNestedManyWithoutCompanyInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCompanyInputSchema),z.lazy(() => UserCreateWithoutCompanyInputSchema).array(),z.lazy(() => UserUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => UserUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => UserCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => UserCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => UserCreateManyCompanyInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
}).strict() as z.ZodType<Prisma.UserUncheckedCreateNestedManyWithoutCompanyInput>;

export const SessionUncheckedCreateNestedManyWithoutCompanyInputSchema: z.ZodType<Prisma.SessionUncheckedCreateNestedManyWithoutCompanyInput> = z.object({
  create: z.union([ z.lazy(() => SessionCreateWithoutCompanyInputSchema),z.lazy(() => SessionCreateWithoutCompanyInputSchema).array(),z.lazy(() => SessionUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => SessionUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SessionCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => SessionCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SessionCreateManyCompanyInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
}).strict() as z.ZodType<Prisma.SessionUncheckedCreateNestedManyWithoutCompanyInput>;

export const UserUpdateManyWithoutCompanyNestedInputSchema: z.ZodType<Prisma.UserUpdateManyWithoutCompanyNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCompanyInputSchema),z.lazy(() => UserCreateWithoutCompanyInputSchema).array(),z.lazy(() => UserUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => UserUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => UserCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => UserCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => UserUpsertWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => UserUpsertWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => UserCreateManyCompanyInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => UserUpdateWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => UserUpdateWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => UserUpdateManyWithWhereWithoutCompanyInputSchema),z.lazy(() => UserUpdateManyWithWhereWithoutCompanyInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => UserScalarWhereInputSchema),z.lazy(() => UserScalarWhereInputSchema).array() ]).optional(),
}).strict() as z.ZodType<Prisma.UserUpdateManyWithoutCompanyNestedInput>;

export const SessionUpdateManyWithoutCompanyNestedInputSchema: z.ZodType<Prisma.SessionUpdateManyWithoutCompanyNestedInput> = z.object({
  create: z.union([ z.lazy(() => SessionCreateWithoutCompanyInputSchema),z.lazy(() => SessionCreateWithoutCompanyInputSchema).array(),z.lazy(() => SessionUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => SessionUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SessionCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => SessionCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SessionUpsertWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => SessionUpsertWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SessionCreateManyCompanyInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SessionUpdateWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => SessionUpdateWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SessionUpdateManyWithWhereWithoutCompanyInputSchema),z.lazy(() => SessionUpdateManyWithWhereWithoutCompanyInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SessionScalarWhereInputSchema),z.lazy(() => SessionScalarWhereInputSchema).array() ]).optional(),
}).strict() as z.ZodType<Prisma.SessionUpdateManyWithoutCompanyNestedInput>;

export const UserUncheckedUpdateManyWithoutCompanyNestedInputSchema: z.ZodType<Prisma.UserUncheckedUpdateManyWithoutCompanyNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCompanyInputSchema),z.lazy(() => UserCreateWithoutCompanyInputSchema).array(),z.lazy(() => UserUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => UserUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => UserCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => UserCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => UserUpsertWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => UserUpsertWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => UserCreateManyCompanyInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => UserWhereUniqueInputSchema),z.lazy(() => UserWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => UserUpdateWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => UserUpdateWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => UserUpdateManyWithWhereWithoutCompanyInputSchema),z.lazy(() => UserUpdateManyWithWhereWithoutCompanyInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => UserScalarWhereInputSchema),z.lazy(() => UserScalarWhereInputSchema).array() ]).optional(),
}).strict() as z.ZodType<Prisma.UserUncheckedUpdateManyWithoutCompanyNestedInput>;

export const SessionUncheckedUpdateManyWithoutCompanyNestedInputSchema: z.ZodType<Prisma.SessionUncheckedUpdateManyWithoutCompanyNestedInput> = z.object({
  create: z.union([ z.lazy(() => SessionCreateWithoutCompanyInputSchema),z.lazy(() => SessionCreateWithoutCompanyInputSchema).array(),z.lazy(() => SessionUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => SessionUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SessionCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => SessionCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SessionUpsertWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => SessionUpsertWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SessionCreateManyCompanyInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SessionUpdateWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => SessionUpdateWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SessionUpdateManyWithWhereWithoutCompanyInputSchema),z.lazy(() => SessionUpdateManyWithWhereWithoutCompanyInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SessionScalarWhereInputSchema),z.lazy(() => SessionScalarWhereInputSchema).array() ]).optional(),
}).strict() as z.ZodType<Prisma.SessionUncheckedUpdateManyWithoutCompanyNestedInput>;

export const TeamCreateNestedManyWithoutGameserversInputSchema: z.ZodType<Prisma.TeamCreateNestedManyWithoutGameserversInput> = z.object({
  create: z.union([ z.lazy(() => TeamCreateWithoutGameserversInputSchema),z.lazy(() => TeamCreateWithoutGameserversInputSchema).array(),z.lazy(() => TeamUncheckedCreateWithoutGameserversInputSchema),z.lazy(() => TeamUncheckedCreateWithoutGameserversInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => TeamCreateOrConnectWithoutGameserversInputSchema),z.lazy(() => TeamCreateOrConnectWithoutGameserversInputSchema).array() ]).optional(),
  createMany: z.lazy(() => TeamCreateManyGameserversInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => TeamWhereUniqueInputSchema),z.lazy(() => TeamWhereUniqueInputSchema).array() ]).optional(),
}).strict() as z.ZodType<Prisma.TeamCreateNestedManyWithoutGameserversInput>;

export const TeamUncheckedCreateNestedManyWithoutGameserversInputSchema: z.ZodType<Prisma.TeamUncheckedCreateNestedManyWithoutGameserversInput> = z.object({
  create: z.union([ z.lazy(() => TeamCreateWithoutGameserversInputSchema),z.lazy(() => TeamCreateWithoutGameserversInputSchema).array(),z.lazy(() => TeamUncheckedCreateWithoutGameserversInputSchema),z.lazy(() => TeamUncheckedCreateWithoutGameserversInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => TeamCreateOrConnectWithoutGameserversInputSchema),z.lazy(() => TeamCreateOrConnectWithoutGameserversInputSchema).array() ]).optional(),
  createMany: z.lazy(() => TeamCreateManyGameserversInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => TeamWhereUniqueInputSchema),z.lazy(() => TeamWhereUniqueInputSchema).array() ]).optional(),
}).strict() as z.ZodType<Prisma.TeamUncheckedCreateNestedManyWithoutGameserversInput>;

export const NullableStringFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableStringFieldUpdateOperationsInput> = z.object({
  set: z.string().optional().nullable()
}).strict() as z.ZodType<Prisma.NullableStringFieldUpdateOperationsInput>;

export const EnumGameserverProviderFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumGameserverProviderFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => GameserverProviderSchema).optional()
}).strict() as z.ZodType<Prisma.EnumGameserverProviderFieldUpdateOperationsInput>;

export const EnumInstanceStateFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumInstanceStateFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => InstanceStateSchema).optional()
}).strict() as z.ZodType<Prisma.EnumInstanceStateFieldUpdateOperationsInput>;

export const TeamUpdateManyWithoutGameserversNestedInputSchema: z.ZodType<Prisma.TeamUpdateManyWithoutGameserversNestedInput> = z.object({
  create: z.union([ z.lazy(() => TeamCreateWithoutGameserversInputSchema),z.lazy(() => TeamCreateWithoutGameserversInputSchema).array(),z.lazy(() => TeamUncheckedCreateWithoutGameserversInputSchema),z.lazy(() => TeamUncheckedCreateWithoutGameserversInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => TeamCreateOrConnectWithoutGameserversInputSchema),z.lazy(() => TeamCreateOrConnectWithoutGameserversInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => TeamUpsertWithWhereUniqueWithoutGameserversInputSchema),z.lazy(() => TeamUpsertWithWhereUniqueWithoutGameserversInputSchema).array() ]).optional(),
  createMany: z.lazy(() => TeamCreateManyGameserversInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => TeamWhereUniqueInputSchema),z.lazy(() => TeamWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => TeamWhereUniqueInputSchema),z.lazy(() => TeamWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => TeamWhereUniqueInputSchema),z.lazy(() => TeamWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => TeamWhereUniqueInputSchema),z.lazy(() => TeamWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => TeamUpdateWithWhereUniqueWithoutGameserversInputSchema),z.lazy(() => TeamUpdateWithWhereUniqueWithoutGameserversInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => TeamUpdateManyWithWhereWithoutGameserversInputSchema),z.lazy(() => TeamUpdateManyWithWhereWithoutGameserversInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => TeamScalarWhereInputSchema),z.lazy(() => TeamScalarWhereInputSchema).array() ]).optional(),
}).strict() as z.ZodType<Prisma.TeamUpdateManyWithoutGameserversNestedInput>;

export const TeamUncheckedUpdateManyWithoutGameserversNestedInputSchema: z.ZodType<Prisma.TeamUncheckedUpdateManyWithoutGameserversNestedInput> = z.object({
  create: z.union([ z.lazy(() => TeamCreateWithoutGameserversInputSchema),z.lazy(() => TeamCreateWithoutGameserversInputSchema).array(),z.lazy(() => TeamUncheckedCreateWithoutGameserversInputSchema),z.lazy(() => TeamUncheckedCreateWithoutGameserversInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => TeamCreateOrConnectWithoutGameserversInputSchema),z.lazy(() => TeamCreateOrConnectWithoutGameserversInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => TeamUpsertWithWhereUniqueWithoutGameserversInputSchema),z.lazy(() => TeamUpsertWithWhereUniqueWithoutGameserversInputSchema).array() ]).optional(),
  createMany: z.lazy(() => TeamCreateManyGameserversInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => TeamWhereUniqueInputSchema),z.lazy(() => TeamWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => TeamWhereUniqueInputSchema),z.lazy(() => TeamWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => TeamWhereUniqueInputSchema),z.lazy(() => TeamWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => TeamWhereUniqueInputSchema),z.lazy(() => TeamWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => TeamUpdateWithWhereUniqueWithoutGameserversInputSchema),z.lazy(() => TeamUpdateWithWhereUniqueWithoutGameserversInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => TeamUpdateManyWithWhereWithoutGameserversInputSchema),z.lazy(() => TeamUpdateManyWithWhereWithoutGameserversInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => TeamScalarWhereInputSchema),z.lazy(() => TeamScalarWhereInputSchema).array() ]).optional(),
}).strict() as z.ZodType<Prisma.TeamUncheckedUpdateManyWithoutGameserversNestedInput>;

export const SessionCreatechallengesInputSchema: z.ZodType<Prisma.SessionCreatechallengesInput> = z.object({
  set: z.string().array()
}).strict() as z.ZodType<Prisma.SessionCreatechallengesInput>;

export const CompanyCreateNestedOneWithoutSessionsInputSchema: z.ZodType<Prisma.CompanyCreateNestedOneWithoutSessionsInput> = z.object({
  create: z.union([ z.lazy(() => CompanyCreateWithoutSessionsInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutSessionsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyCreateOrConnectWithoutSessionsInputSchema).optional(),
  connect: z.lazy(() => CompanyWhereUniqueInputSchema).optional()
}).strict() as z.ZodType<Prisma.CompanyCreateNestedOneWithoutSessionsInput>;

export const TeamCreateNestedManyWithoutSessionsInputSchema: z.ZodType<Prisma.TeamCreateNestedManyWithoutSessionsInput> = z.object({
  create: z.union([ z.lazy(() => TeamCreateWithoutSessionsInputSchema),z.lazy(() => TeamCreateWithoutSessionsInputSchema).array(),z.lazy(() => TeamUncheckedCreateWithoutSessionsInputSchema),z.lazy(() => TeamUncheckedCreateWithoutSessionsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => TeamCreateOrConnectWithoutSessionsInputSchema),z.lazy(() => TeamCreateOrConnectWithoutSessionsInputSchema).array() ]).optional(),
  createMany: z.lazy(() => TeamCreateManySessionsInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => TeamWhereUniqueInputSchema),z.lazy(() => TeamWhereUniqueInputSchema).array() ]).optional(),
}).strict() as z.ZodType<Prisma.TeamCreateNestedManyWithoutSessionsInput>;

export const TeamUncheckedCreateNestedManyWithoutSessionsInputSchema: z.ZodType<Prisma.TeamUncheckedCreateNestedManyWithoutSessionsInput> = z.object({
  create: z.union([ z.lazy(() => TeamCreateWithoutSessionsInputSchema),z.lazy(() => TeamCreateWithoutSessionsInputSchema).array(),z.lazy(() => TeamUncheckedCreateWithoutSessionsInputSchema),z.lazy(() => TeamUncheckedCreateWithoutSessionsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => TeamCreateOrConnectWithoutSessionsInputSchema),z.lazy(() => TeamCreateOrConnectWithoutSessionsInputSchema).array() ]).optional(),
  createMany: z.lazy(() => TeamCreateManySessionsInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => TeamWhereUniqueInputSchema),z.lazy(() => TeamWhereUniqueInputSchema).array() ]).optional(),
}).strict() as z.ZodType<Prisma.TeamUncheckedCreateNestedManyWithoutSessionsInput>;

export const SessionUpdatechallengesInputSchema: z.ZodType<Prisma.SessionUpdatechallengesInput> = z.object({
  set: z.string().array().optional(),
  push: z.union([ z.string(),z.string().array() ]).optional(),
}).strict() as z.ZodType<Prisma.SessionUpdatechallengesInput>;

export const EnumSessionStatusFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumSessionStatusFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => SessionStatusSchema).optional()
}).strict() as z.ZodType<Prisma.EnumSessionStatusFieldUpdateOperationsInput>;

export const NullableIntFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableIntFieldUpdateOperationsInput> = z.object({
  set: z.number().optional().nullable(),
  increment: z.number().optional(),
  decrement: z.number().optional(),
  multiply: z.number().optional(),
  divide: z.number().optional()
}).strict() as z.ZodType<Prisma.NullableIntFieldUpdateOperationsInput>;

export const CompanyUpdateOneRequiredWithoutSessionsNestedInputSchema: z.ZodType<Prisma.CompanyUpdateOneRequiredWithoutSessionsNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyCreateWithoutSessionsInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutSessionsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyCreateOrConnectWithoutSessionsInputSchema).optional(),
  upsert: z.lazy(() => CompanyUpsertWithoutSessionsInputSchema).optional(),
  connect: z.lazy(() => CompanyWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CompanyUpdateToOneWithWhereWithoutSessionsInputSchema),z.lazy(() => CompanyUpdateWithoutSessionsInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutSessionsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.CompanyUpdateOneRequiredWithoutSessionsNestedInput>;

export const TeamUpdateManyWithoutSessionsNestedInputSchema: z.ZodType<Prisma.TeamUpdateManyWithoutSessionsNestedInput> = z.object({
  create: z.union([ z.lazy(() => TeamCreateWithoutSessionsInputSchema),z.lazy(() => TeamCreateWithoutSessionsInputSchema).array(),z.lazy(() => TeamUncheckedCreateWithoutSessionsInputSchema),z.lazy(() => TeamUncheckedCreateWithoutSessionsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => TeamCreateOrConnectWithoutSessionsInputSchema),z.lazy(() => TeamCreateOrConnectWithoutSessionsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => TeamUpsertWithWhereUniqueWithoutSessionsInputSchema),z.lazy(() => TeamUpsertWithWhereUniqueWithoutSessionsInputSchema).array() ]).optional(),
  createMany: z.lazy(() => TeamCreateManySessionsInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => TeamWhereUniqueInputSchema),z.lazy(() => TeamWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => TeamWhereUniqueInputSchema),z.lazy(() => TeamWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => TeamWhereUniqueInputSchema),z.lazy(() => TeamWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => TeamWhereUniqueInputSchema),z.lazy(() => TeamWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => TeamUpdateWithWhereUniqueWithoutSessionsInputSchema),z.lazy(() => TeamUpdateWithWhereUniqueWithoutSessionsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => TeamUpdateManyWithWhereWithoutSessionsInputSchema),z.lazy(() => TeamUpdateManyWithWhereWithoutSessionsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => TeamScalarWhereInputSchema),z.lazy(() => TeamScalarWhereInputSchema).array() ]).optional(),
}).strict() as z.ZodType<Prisma.TeamUpdateManyWithoutSessionsNestedInput>;

export const TeamUncheckedUpdateManyWithoutSessionsNestedInputSchema: z.ZodType<Prisma.TeamUncheckedUpdateManyWithoutSessionsNestedInput> = z.object({
  create: z.union([ z.lazy(() => TeamCreateWithoutSessionsInputSchema),z.lazy(() => TeamCreateWithoutSessionsInputSchema).array(),z.lazy(() => TeamUncheckedCreateWithoutSessionsInputSchema),z.lazy(() => TeamUncheckedCreateWithoutSessionsInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => TeamCreateOrConnectWithoutSessionsInputSchema),z.lazy(() => TeamCreateOrConnectWithoutSessionsInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => TeamUpsertWithWhereUniqueWithoutSessionsInputSchema),z.lazy(() => TeamUpsertWithWhereUniqueWithoutSessionsInputSchema).array() ]).optional(),
  createMany: z.lazy(() => TeamCreateManySessionsInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => TeamWhereUniqueInputSchema),z.lazy(() => TeamWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => TeamWhereUniqueInputSchema),z.lazy(() => TeamWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => TeamWhereUniqueInputSchema),z.lazy(() => TeamWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => TeamWhereUniqueInputSchema),z.lazy(() => TeamWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => TeamUpdateWithWhereUniqueWithoutSessionsInputSchema),z.lazy(() => TeamUpdateWithWhereUniqueWithoutSessionsInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => TeamUpdateManyWithWhereWithoutSessionsInputSchema),z.lazy(() => TeamUpdateManyWithWhereWithoutSessionsInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => TeamScalarWhereInputSchema),z.lazy(() => TeamScalarWhereInputSchema).array() ]).optional(),
}).strict() as z.ZodType<Prisma.TeamUncheckedUpdateManyWithoutSessionsNestedInput>;

export const TeamCreateplayersInputSchema: z.ZodType<Prisma.TeamCreateplayersInput> = z.object({
  set: InputJsonValueSchema.array()
}).strict() as z.ZodType<Prisma.TeamCreateplayersInput>;

export const EventCreateNestedManyWithoutTeamInputSchema: z.ZodType<Prisma.EventCreateNestedManyWithoutTeamInput> = z.object({
  create: z.union([ z.lazy(() => EventCreateWithoutTeamInputSchema),z.lazy(() => EventCreateWithoutTeamInputSchema).array(),z.lazy(() => EventUncheckedCreateWithoutTeamInputSchema),z.lazy(() => EventUncheckedCreateWithoutTeamInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => EventCreateOrConnectWithoutTeamInputSchema),z.lazy(() => EventCreateOrConnectWithoutTeamInputSchema).array() ]).optional(),
  createMany: z.lazy(() => EventCreateManyTeamInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => EventWhereUniqueInputSchema),z.lazy(() => EventWhereUniqueInputSchema).array() ]).optional(),
}).strict() as z.ZodType<Prisma.EventCreateNestedManyWithoutTeamInput>;

export const PositionCreateNestedManyWithoutTeamInputSchema: z.ZodType<Prisma.PositionCreateNestedManyWithoutTeamInput> = z.object({
  create: z.union([ z.lazy(() => PositionCreateWithoutTeamInputSchema),z.lazy(() => PositionCreateWithoutTeamInputSchema).array(),z.lazy(() => PositionUncheckedCreateWithoutTeamInputSchema),z.lazy(() => PositionUncheckedCreateWithoutTeamInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PositionCreateOrConnectWithoutTeamInputSchema),z.lazy(() => PositionCreateOrConnectWithoutTeamInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PositionCreateManyTeamInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PositionWhereUniqueInputSchema),z.lazy(() => PositionWhereUniqueInputSchema).array() ]).optional(),
}).strict() as z.ZodType<Prisma.PositionCreateNestedManyWithoutTeamInput>;

export const GameserverCreateNestedOneWithoutTeamsInputSchema: z.ZodType<Prisma.GameserverCreateNestedOneWithoutTeamsInput> = z.object({
  create: z.union([ z.lazy(() => GameserverCreateWithoutTeamsInputSchema),z.lazy(() => GameserverUncheckedCreateWithoutTeamsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => GameserverCreateOrConnectWithoutTeamsInputSchema).optional(),
  connect: z.lazy(() => GameserverWhereUniqueInputSchema).optional()
}).strict() as z.ZodType<Prisma.GameserverCreateNestedOneWithoutTeamsInput>;

export const SessionCreateNestedOneWithoutTeamsInputSchema: z.ZodType<Prisma.SessionCreateNestedOneWithoutTeamsInput> = z.object({
  create: z.union([ z.lazy(() => SessionCreateWithoutTeamsInputSchema),z.lazy(() => SessionUncheckedCreateWithoutTeamsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => SessionCreateOrConnectWithoutTeamsInputSchema).optional(),
  connect: z.lazy(() => SessionWhereUniqueInputSchema).optional()
}).strict() as z.ZodType<Prisma.SessionCreateNestedOneWithoutTeamsInput>;

export const EventUncheckedCreateNestedManyWithoutTeamInputSchema: z.ZodType<Prisma.EventUncheckedCreateNestedManyWithoutTeamInput> = z.object({
  create: z.union([ z.lazy(() => EventCreateWithoutTeamInputSchema),z.lazy(() => EventCreateWithoutTeamInputSchema).array(),z.lazy(() => EventUncheckedCreateWithoutTeamInputSchema),z.lazy(() => EventUncheckedCreateWithoutTeamInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => EventCreateOrConnectWithoutTeamInputSchema),z.lazy(() => EventCreateOrConnectWithoutTeamInputSchema).array() ]).optional(),
  createMany: z.lazy(() => EventCreateManyTeamInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => EventWhereUniqueInputSchema),z.lazy(() => EventWhereUniqueInputSchema).array() ]).optional(),
}).strict() as z.ZodType<Prisma.EventUncheckedCreateNestedManyWithoutTeamInput>;

export const PositionUncheckedCreateNestedManyWithoutTeamInputSchema: z.ZodType<Prisma.PositionUncheckedCreateNestedManyWithoutTeamInput> = z.object({
  create: z.union([ z.lazy(() => PositionCreateWithoutTeamInputSchema),z.lazy(() => PositionCreateWithoutTeamInputSchema).array(),z.lazy(() => PositionUncheckedCreateWithoutTeamInputSchema),z.lazy(() => PositionUncheckedCreateWithoutTeamInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PositionCreateOrConnectWithoutTeamInputSchema),z.lazy(() => PositionCreateOrConnectWithoutTeamInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PositionCreateManyTeamInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PositionWhereUniqueInputSchema),z.lazy(() => PositionWhereUniqueInputSchema).array() ]).optional(),
}).strict() as z.ZodType<Prisma.PositionUncheckedCreateNestedManyWithoutTeamInput>;

export const BoolFieldUpdateOperationsInputSchema: z.ZodType<Prisma.BoolFieldUpdateOperationsInput> = z.object({
  set: z.boolean().optional()
}).strict() as z.ZodType<Prisma.BoolFieldUpdateOperationsInput>;

export const EnumChallengeStatusFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumChallengeStatusFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => ChallengeStatusSchema).optional()
}).strict() as z.ZodType<Prisma.EnumChallengeStatusFieldUpdateOperationsInput>;

export const TeamUpdateplayersInputSchema: z.ZodType<Prisma.TeamUpdateplayersInput> = z.object({
  set: InputJsonValueSchema.array().optional(),
  push: z.union([ InputJsonValueSchema,InputJsonValueSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.TeamUpdateplayersInput>;

export const EnumTeamStatusFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumTeamStatusFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => TeamStatusSchema).optional()
}).strict() as z.ZodType<Prisma.EnumTeamStatusFieldUpdateOperationsInput>;

export const EventUpdateManyWithoutTeamNestedInputSchema: z.ZodType<Prisma.EventUpdateManyWithoutTeamNestedInput> = z.object({
  create: z.union([ z.lazy(() => EventCreateWithoutTeamInputSchema),z.lazy(() => EventCreateWithoutTeamInputSchema).array(),z.lazy(() => EventUncheckedCreateWithoutTeamInputSchema),z.lazy(() => EventUncheckedCreateWithoutTeamInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => EventCreateOrConnectWithoutTeamInputSchema),z.lazy(() => EventCreateOrConnectWithoutTeamInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => EventUpsertWithWhereUniqueWithoutTeamInputSchema),z.lazy(() => EventUpsertWithWhereUniqueWithoutTeamInputSchema).array() ]).optional(),
  createMany: z.lazy(() => EventCreateManyTeamInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => EventWhereUniqueInputSchema),z.lazy(() => EventWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => EventWhereUniqueInputSchema),z.lazy(() => EventWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => EventWhereUniqueInputSchema),z.lazy(() => EventWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => EventWhereUniqueInputSchema),z.lazy(() => EventWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => EventUpdateWithWhereUniqueWithoutTeamInputSchema),z.lazy(() => EventUpdateWithWhereUniqueWithoutTeamInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => EventUpdateManyWithWhereWithoutTeamInputSchema),z.lazy(() => EventUpdateManyWithWhereWithoutTeamInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => EventScalarWhereInputSchema),z.lazy(() => EventScalarWhereInputSchema).array() ]).optional(),
}).strict() as z.ZodType<Prisma.EventUpdateManyWithoutTeamNestedInput>;

export const PositionUpdateManyWithoutTeamNestedInputSchema: z.ZodType<Prisma.PositionUpdateManyWithoutTeamNestedInput> = z.object({
  create: z.union([ z.lazy(() => PositionCreateWithoutTeamInputSchema),z.lazy(() => PositionCreateWithoutTeamInputSchema).array(),z.lazy(() => PositionUncheckedCreateWithoutTeamInputSchema),z.lazy(() => PositionUncheckedCreateWithoutTeamInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PositionCreateOrConnectWithoutTeamInputSchema),z.lazy(() => PositionCreateOrConnectWithoutTeamInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PositionUpsertWithWhereUniqueWithoutTeamInputSchema),z.lazy(() => PositionUpsertWithWhereUniqueWithoutTeamInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PositionCreateManyTeamInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PositionWhereUniqueInputSchema),z.lazy(() => PositionWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PositionWhereUniqueInputSchema),z.lazy(() => PositionWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PositionWhereUniqueInputSchema),z.lazy(() => PositionWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PositionWhereUniqueInputSchema),z.lazy(() => PositionWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PositionUpdateWithWhereUniqueWithoutTeamInputSchema),z.lazy(() => PositionUpdateWithWhereUniqueWithoutTeamInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PositionUpdateManyWithWhereWithoutTeamInputSchema),z.lazy(() => PositionUpdateManyWithWhereWithoutTeamInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PositionScalarWhereInputSchema),z.lazy(() => PositionScalarWhereInputSchema).array() ]).optional(),
}).strict() as z.ZodType<Prisma.PositionUpdateManyWithoutTeamNestedInput>;

export const GameserverUpdateOneWithoutTeamsNestedInputSchema: z.ZodType<Prisma.GameserverUpdateOneWithoutTeamsNestedInput> = z.object({
  create: z.union([ z.lazy(() => GameserverCreateWithoutTeamsInputSchema),z.lazy(() => GameserverUncheckedCreateWithoutTeamsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => GameserverCreateOrConnectWithoutTeamsInputSchema).optional(),
  upsert: z.lazy(() => GameserverUpsertWithoutTeamsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => GameserverWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => GameserverWhereInputSchema) ]).optional(),
  connect: z.lazy(() => GameserverWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => GameserverUpdateToOneWithWhereWithoutTeamsInputSchema),z.lazy(() => GameserverUpdateWithoutTeamsInputSchema),z.lazy(() => GameserverUncheckedUpdateWithoutTeamsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.GameserverUpdateOneWithoutTeamsNestedInput>;

export const SessionUpdateOneRequiredWithoutTeamsNestedInputSchema: z.ZodType<Prisma.SessionUpdateOneRequiredWithoutTeamsNestedInput> = z.object({
  create: z.union([ z.lazy(() => SessionCreateWithoutTeamsInputSchema),z.lazy(() => SessionUncheckedCreateWithoutTeamsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => SessionCreateOrConnectWithoutTeamsInputSchema).optional(),
  upsert: z.lazy(() => SessionUpsertWithoutTeamsInputSchema).optional(),
  connect: z.lazy(() => SessionWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => SessionUpdateToOneWithWhereWithoutTeamsInputSchema),z.lazy(() => SessionUpdateWithoutTeamsInputSchema),z.lazy(() => SessionUncheckedUpdateWithoutTeamsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.SessionUpdateOneRequiredWithoutTeamsNestedInput>;

export const EventUncheckedUpdateManyWithoutTeamNestedInputSchema: z.ZodType<Prisma.EventUncheckedUpdateManyWithoutTeamNestedInput> = z.object({
  create: z.union([ z.lazy(() => EventCreateWithoutTeamInputSchema),z.lazy(() => EventCreateWithoutTeamInputSchema).array(),z.lazy(() => EventUncheckedCreateWithoutTeamInputSchema),z.lazy(() => EventUncheckedCreateWithoutTeamInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => EventCreateOrConnectWithoutTeamInputSchema),z.lazy(() => EventCreateOrConnectWithoutTeamInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => EventUpsertWithWhereUniqueWithoutTeamInputSchema),z.lazy(() => EventUpsertWithWhereUniqueWithoutTeamInputSchema).array() ]).optional(),
  createMany: z.lazy(() => EventCreateManyTeamInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => EventWhereUniqueInputSchema),z.lazy(() => EventWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => EventWhereUniqueInputSchema),z.lazy(() => EventWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => EventWhereUniqueInputSchema),z.lazy(() => EventWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => EventWhereUniqueInputSchema),z.lazy(() => EventWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => EventUpdateWithWhereUniqueWithoutTeamInputSchema),z.lazy(() => EventUpdateWithWhereUniqueWithoutTeamInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => EventUpdateManyWithWhereWithoutTeamInputSchema),z.lazy(() => EventUpdateManyWithWhereWithoutTeamInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => EventScalarWhereInputSchema),z.lazy(() => EventScalarWhereInputSchema).array() ]).optional(),
}).strict() as z.ZodType<Prisma.EventUncheckedUpdateManyWithoutTeamNestedInput>;

export const PositionUncheckedUpdateManyWithoutTeamNestedInputSchema: z.ZodType<Prisma.PositionUncheckedUpdateManyWithoutTeamNestedInput> = z.object({
  create: z.union([ z.lazy(() => PositionCreateWithoutTeamInputSchema),z.lazy(() => PositionCreateWithoutTeamInputSchema).array(),z.lazy(() => PositionUncheckedCreateWithoutTeamInputSchema),z.lazy(() => PositionUncheckedCreateWithoutTeamInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PositionCreateOrConnectWithoutTeamInputSchema),z.lazy(() => PositionCreateOrConnectWithoutTeamInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PositionUpsertWithWhereUniqueWithoutTeamInputSchema),z.lazy(() => PositionUpsertWithWhereUniqueWithoutTeamInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PositionCreateManyTeamInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PositionWhereUniqueInputSchema),z.lazy(() => PositionWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PositionWhereUniqueInputSchema),z.lazy(() => PositionWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PositionWhereUniqueInputSchema),z.lazy(() => PositionWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PositionWhereUniqueInputSchema),z.lazy(() => PositionWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PositionUpdateWithWhereUniqueWithoutTeamInputSchema),z.lazy(() => PositionUpdateWithWhereUniqueWithoutTeamInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PositionUpdateManyWithWhereWithoutTeamInputSchema),z.lazy(() => PositionUpdateManyWithWhereWithoutTeamInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PositionScalarWhereInputSchema),z.lazy(() => PositionScalarWhereInputSchema).array() ]).optional(),
}).strict() as z.ZodType<Prisma.PositionUncheckedUpdateManyWithoutTeamNestedInput>;

export const CompanyCreateNestedOneWithoutUsersInputSchema: z.ZodType<Prisma.CompanyCreateNestedOneWithoutUsersInput> = z.object({
  create: z.union([ z.lazy(() => CompanyCreateWithoutUsersInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutUsersInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyCreateOrConnectWithoutUsersInputSchema).optional(),
  connect: z.lazy(() => CompanyWhereUniqueInputSchema).optional()
}).strict() as z.ZodType<Prisma.CompanyCreateNestedOneWithoutUsersInput>;

export const EnumRoleFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumRoleFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => RoleSchema).optional()
}).strict() as z.ZodType<Prisma.EnumRoleFieldUpdateOperationsInput>;

export const CompanyUpdateOneRequiredWithoutUsersNestedInputSchema: z.ZodType<Prisma.CompanyUpdateOneRequiredWithoutUsersNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyCreateWithoutUsersInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutUsersInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyCreateOrConnectWithoutUsersInputSchema).optional(),
  upsert: z.lazy(() => CompanyUpsertWithoutUsersInputSchema).optional(),
  connect: z.lazy(() => CompanyWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CompanyUpdateToOneWithWhereWithoutUsersInputSchema),z.lazy(() => CompanyUpdateWithoutUsersInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutUsersInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.CompanyUpdateOneRequiredWithoutUsersNestedInput>;

export const NestedIntFilterSchema: z.ZodType<Prisma.NestedIntFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntFilterSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.NestedIntFilter>;

export const NestedIntWithAggregatesFilterSchema: z.ZodType<Prisma.NestedIntWithAggregatesFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatFilterSchema).optional(),
  _sum: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedIntFilterSchema).optional(),
  _max: z.lazy(() => NestedIntFilterSchema).optional()
}).strict() as z.ZodType<Prisma.NestedIntWithAggregatesFilter>;

export const NestedFloatFilterSchema: z.ZodType<Prisma.NestedFloatFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatFilterSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.NestedFloatFilter>;

export const NestedJsonFilterSchema: z.ZodType<Prisma.NestedJsonFilter> = z.object({
  equals: InputJsonValueSchema.optional(),
  path: z.string().array().optional(),
  string_contains: z.string().optional(),
  string_starts_with: z.string().optional(),
  string_ends_with: z.string().optional(),
  array_contains: InputJsonValueSchema.optional().nullable(),
  array_starts_with: InputJsonValueSchema.optional().nullable(),
  array_ends_with: InputJsonValueSchema.optional().nullable(),
  lt: InputJsonValueSchema.optional(),
  lte: InputJsonValueSchema.optional(),
  gt: InputJsonValueSchema.optional(),
  gte: InputJsonValueSchema.optional(),
  not: InputJsonValueSchema.optional()
}).strict() as z.ZodType<Prisma.NestedJsonFilter>;

export const NestedStringFilterSchema: z.ZodType<Prisma.NestedStringFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringFilterSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.NestedStringFilter>;

export const NestedDateTimeFilterSchema: z.ZodType<Prisma.NestedDateTimeFilter> = z.object({
  equals: z.coerce.date().optional(),
  in: z.coerce.date().array().optional(),
  notIn: z.coerce.date().array().optional(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeFilterSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.NestedDateTimeFilter>;

export const NestedStringWithAggregatesFilterSchema: z.ZodType<Prisma.NestedStringWithAggregatesFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedStringFilterSchema).optional(),
  _max: z.lazy(() => NestedStringFilterSchema).optional()
}).strict() as z.ZodType<Prisma.NestedStringWithAggregatesFilter>;

export const NestedDateTimeWithAggregatesFilterSchema: z.ZodType<Prisma.NestedDateTimeWithAggregatesFilter> = z.object({
  equals: z.coerce.date().optional(),
  in: z.coerce.date().array().optional(),
  notIn: z.coerce.date().array().optional(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedDateTimeFilterSchema).optional(),
  _max: z.lazy(() => NestedDateTimeFilterSchema).optional()
}).strict() as z.ZodType<Prisma.NestedDateTimeWithAggregatesFilter>;

export const NestedUuidFilterSchema: z.ZodType<Prisma.NestedUuidFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedUuidFilterSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.NestedUuidFilter>;

export const NestedUuidWithAggregatesFilterSchema: z.ZodType<Prisma.NestedUuidWithAggregatesFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedUuidWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedStringFilterSchema).optional(),
  _max: z.lazy(() => NestedStringFilterSchema).optional()
}).strict() as z.ZodType<Prisma.NestedUuidWithAggregatesFilter>;

export const NestedStringNullableFilterSchema: z.ZodType<Prisma.NestedStringNullableFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.string().array().optional().nullable(),
  notIn: z.string().array().optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringNullableFilterSchema) ]).optional().nullable(),
}).strict() as z.ZodType<Prisma.NestedStringNullableFilter>;

export const NestedEnumGameserverProviderFilterSchema: z.ZodType<Prisma.NestedEnumGameserverProviderFilter> = z.object({
  equals: z.lazy(() => GameserverProviderSchema).optional(),
  in: z.lazy(() => GameserverProviderSchema).array().optional(),
  notIn: z.lazy(() => GameserverProviderSchema).array().optional(),
  not: z.union([ z.lazy(() => GameserverProviderSchema),z.lazy(() => NestedEnumGameserverProviderFilterSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.NestedEnumGameserverProviderFilter>;

export const NestedEnumInstanceStateFilterSchema: z.ZodType<Prisma.NestedEnumInstanceStateFilter> = z.object({
  equals: z.lazy(() => InstanceStateSchema).optional(),
  in: z.lazy(() => InstanceStateSchema).array().optional(),
  notIn: z.lazy(() => InstanceStateSchema).array().optional(),
  not: z.union([ z.lazy(() => InstanceStateSchema),z.lazy(() => NestedEnumInstanceStateFilterSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.NestedEnumInstanceStateFilter>;

export const NestedStringNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedStringNullableWithAggregatesFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.string().array().optional().nullable(),
  notIn: z.string().array().optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedStringNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedStringNullableFilterSchema).optional()
}).strict() as z.ZodType<Prisma.NestedStringNullableWithAggregatesFilter>;

export const NestedIntNullableFilterSchema: z.ZodType<Prisma.NestedIntNullableFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntNullableFilterSchema) ]).optional().nullable(),
}).strict() as z.ZodType<Prisma.NestedIntNullableFilter>;

export const NestedEnumGameserverProviderWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumGameserverProviderWithAggregatesFilter> = z.object({
  equals: z.lazy(() => GameserverProviderSchema).optional(),
  in: z.lazy(() => GameserverProviderSchema).array().optional(),
  notIn: z.lazy(() => GameserverProviderSchema).array().optional(),
  not: z.union([ z.lazy(() => GameserverProviderSchema),z.lazy(() => NestedEnumGameserverProviderWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumGameserverProviderFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumGameserverProviderFilterSchema).optional()
}).strict() as z.ZodType<Prisma.NestedEnumGameserverProviderWithAggregatesFilter>;

export const NestedEnumInstanceStateWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumInstanceStateWithAggregatesFilter> = z.object({
  equals: z.lazy(() => InstanceStateSchema).optional(),
  in: z.lazy(() => InstanceStateSchema).array().optional(),
  notIn: z.lazy(() => InstanceStateSchema).array().optional(),
  not: z.union([ z.lazy(() => InstanceStateSchema),z.lazy(() => NestedEnumInstanceStateWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumInstanceStateFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumInstanceStateFilterSchema).optional()
}).strict() as z.ZodType<Prisma.NestedEnumInstanceStateWithAggregatesFilter>;

export const NestedEnumSessionStatusFilterSchema: z.ZodType<Prisma.NestedEnumSessionStatusFilter> = z.object({
  equals: z.lazy(() => SessionStatusSchema).optional(),
  in: z.lazy(() => SessionStatusSchema).array().optional(),
  notIn: z.lazy(() => SessionStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => SessionStatusSchema),z.lazy(() => NestedEnumSessionStatusFilterSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.NestedEnumSessionStatusFilter>;

export const NestedEnumSessionStatusWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumSessionStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => SessionStatusSchema).optional(),
  in: z.lazy(() => SessionStatusSchema).array().optional(),
  notIn: z.lazy(() => SessionStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => SessionStatusSchema),z.lazy(() => NestedEnumSessionStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumSessionStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumSessionStatusFilterSchema).optional()
}).strict() as z.ZodType<Prisma.NestedEnumSessionStatusWithAggregatesFilter>;

export const NestedIntNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedIntNullableWithAggregatesFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _sum: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedIntNullableFilterSchema).optional()
}).strict() as z.ZodType<Prisma.NestedIntNullableWithAggregatesFilter>;

export const NestedFloatNullableFilterSchema: z.ZodType<Prisma.NestedFloatNullableFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatNullableFilterSchema) ]).optional().nullable(),
}).strict() as z.ZodType<Prisma.NestedFloatNullableFilter>;

export const NestedBoolFilterSchema: z.ZodType<Prisma.NestedBoolFilter> = z.object({
  equals: z.boolean().optional(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolFilterSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.NestedBoolFilter>;

export const NestedEnumChallengeStatusFilterSchema: z.ZodType<Prisma.NestedEnumChallengeStatusFilter> = z.object({
  equals: z.lazy(() => ChallengeStatusSchema).optional(),
  in: z.lazy(() => ChallengeStatusSchema).array().optional(),
  notIn: z.lazy(() => ChallengeStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => ChallengeStatusSchema),z.lazy(() => NestedEnumChallengeStatusFilterSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.NestedEnumChallengeStatusFilter>;

export const NestedEnumTeamStatusFilterSchema: z.ZodType<Prisma.NestedEnumTeamStatusFilter> = z.object({
  equals: z.lazy(() => TeamStatusSchema).optional(),
  in: z.lazy(() => TeamStatusSchema).array().optional(),
  notIn: z.lazy(() => TeamStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => TeamStatusSchema),z.lazy(() => NestedEnumTeamStatusFilterSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.NestedEnumTeamStatusFilter>;

export const NestedBoolWithAggregatesFilterSchema: z.ZodType<Prisma.NestedBoolWithAggregatesFilter> = z.object({
  equals: z.boolean().optional(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedBoolFilterSchema).optional(),
  _max: z.lazy(() => NestedBoolFilterSchema).optional()
}).strict() as z.ZodType<Prisma.NestedBoolWithAggregatesFilter>;

export const NestedEnumChallengeStatusWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumChallengeStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => ChallengeStatusSchema).optional(),
  in: z.lazy(() => ChallengeStatusSchema).array().optional(),
  notIn: z.lazy(() => ChallengeStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => ChallengeStatusSchema),z.lazy(() => NestedEnumChallengeStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumChallengeStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumChallengeStatusFilterSchema).optional()
}).strict() as z.ZodType<Prisma.NestedEnumChallengeStatusWithAggregatesFilter>;

export const NestedEnumTeamStatusWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumTeamStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => TeamStatusSchema).optional(),
  in: z.lazy(() => TeamStatusSchema).array().optional(),
  notIn: z.lazy(() => TeamStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => TeamStatusSchema),z.lazy(() => NestedEnumTeamStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumTeamStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumTeamStatusFilterSchema).optional()
}).strict() as z.ZodType<Prisma.NestedEnumTeamStatusWithAggregatesFilter>;

export const NestedEnumRoleFilterSchema: z.ZodType<Prisma.NestedEnumRoleFilter> = z.object({
  equals: z.lazy(() => RoleSchema).optional(),
  in: z.lazy(() => RoleSchema).array().optional(),
  notIn: z.lazy(() => RoleSchema).array().optional(),
  not: z.union([ z.lazy(() => RoleSchema),z.lazy(() => NestedEnumRoleFilterSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.NestedEnumRoleFilter>;

export const NestedEnumRoleWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumRoleWithAggregatesFilter> = z.object({
  equals: z.lazy(() => RoleSchema).optional(),
  in: z.lazy(() => RoleSchema).array().optional(),
  notIn: z.lazy(() => RoleSchema).array().optional(),
  not: z.union([ z.lazy(() => RoleSchema),z.lazy(() => NestedEnumRoleWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumRoleFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumRoleFilterSchema).optional()
}).strict() as z.ZodType<Prisma.NestedEnumRoleWithAggregatesFilter>;

export const TeamCreateWithoutPositionsInputSchema: z.ZodType<Prisma.TeamCreateWithoutPositionsInput> = z.object({
  uuid: z.string().optional(),
  connected: z.boolean().optional(),
  currentChallenge: z.number().int().optional(),
  challengeStatus: z.lazy(() => ChallengeStatusSchema).optional(),
  licenceUsed: z.number().int().optional(),
  max_players: z.number().int().optional(),
  players: z.union([ z.lazy(() => TeamCreateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.lazy(() => TeamStatusSchema).optional(),
  token: z.string(),
  spectoken: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  events: z.lazy(() => EventCreateNestedManyWithoutTeamInputSchema).optional(),
  gameservers: z.lazy(() => GameserverCreateNestedOneWithoutTeamsInputSchema).optional(),
  sessions: z.lazy(() => SessionCreateNestedOneWithoutTeamsInputSchema)
}).strict() as z.ZodType<Prisma.TeamCreateWithoutPositionsInput>;

export const TeamUncheckedCreateWithoutPositionsInputSchema: z.ZodType<Prisma.TeamUncheckedCreateWithoutPositionsInput> = z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  connected: z.boolean().optional(),
  currentChallenge: z.number().int().optional(),
  challengeStatus: z.lazy(() => ChallengeStatusSchema).optional(),
  licenceUsed: z.number().int().optional(),
  max_players: z.number().int().optional(),
  players: z.union([ z.lazy(() => TeamCreateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.lazy(() => TeamStatusSchema).optional(),
  token: z.string(),
  spectoken: z.string(),
  gameserversId: z.number().int().optional().nullable(),
  sessionsId: z.number().int(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  events: z.lazy(() => EventUncheckedCreateNestedManyWithoutTeamInputSchema).optional()
}).strict() as z.ZodType<Prisma.TeamUncheckedCreateWithoutPositionsInput>;

export const TeamCreateOrConnectWithoutPositionsInputSchema: z.ZodType<Prisma.TeamCreateOrConnectWithoutPositionsInput> = z.object({
  where: z.lazy(() => TeamWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => TeamCreateWithoutPositionsInputSchema),z.lazy(() => TeamUncheckedCreateWithoutPositionsInputSchema) ]),
}).strict() as z.ZodType<Prisma.TeamCreateOrConnectWithoutPositionsInput>;

export const TeamUpsertWithoutPositionsInputSchema: z.ZodType<Prisma.TeamUpsertWithoutPositionsInput> = z.object({
  update: z.union([ z.lazy(() => TeamUpdateWithoutPositionsInputSchema),z.lazy(() => TeamUncheckedUpdateWithoutPositionsInputSchema) ]),
  create: z.union([ z.lazy(() => TeamCreateWithoutPositionsInputSchema),z.lazy(() => TeamUncheckedCreateWithoutPositionsInputSchema) ]),
  where: z.lazy(() => TeamWhereInputSchema).optional()
}).strict() as z.ZodType<Prisma.TeamUpsertWithoutPositionsInput>;

export const TeamUpdateToOneWithWhereWithoutPositionsInputSchema: z.ZodType<Prisma.TeamUpdateToOneWithWhereWithoutPositionsInput> = z.object({
  where: z.lazy(() => TeamWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => TeamUpdateWithoutPositionsInputSchema),z.lazy(() => TeamUncheckedUpdateWithoutPositionsInputSchema) ]),
}).strict() as z.ZodType<Prisma.TeamUpdateToOneWithWhereWithoutPositionsInput>;

export const TeamUpdateWithoutPositionsInputSchema: z.ZodType<Prisma.TeamUpdateWithoutPositionsInput> = z.object({
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  connected: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  currentChallenge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  challengeStatus: z.union([ z.lazy(() => ChallengeStatusSchema),z.lazy(() => EnumChallengeStatusFieldUpdateOperationsInputSchema) ]).optional(),
  licenceUsed: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  max_players: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  players: z.union([ z.lazy(() => TeamUpdateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.union([ z.lazy(() => TeamStatusSchema),z.lazy(() => EnumTeamStatusFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  spectoken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  events: z.lazy(() => EventUpdateManyWithoutTeamNestedInputSchema).optional(),
  gameservers: z.lazy(() => GameserverUpdateOneWithoutTeamsNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUpdateOneRequiredWithoutTeamsNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.TeamUpdateWithoutPositionsInput>;

export const TeamUncheckedUpdateWithoutPositionsInputSchema: z.ZodType<Prisma.TeamUncheckedUpdateWithoutPositionsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  connected: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  currentChallenge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  challengeStatus: z.union([ z.lazy(() => ChallengeStatusSchema),z.lazy(() => EnumChallengeStatusFieldUpdateOperationsInputSchema) ]).optional(),
  licenceUsed: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  max_players: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  players: z.union([ z.lazy(() => TeamUpdateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.union([ z.lazy(() => TeamStatusSchema),z.lazy(() => EnumTeamStatusFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  spectoken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  gameserversId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sessionsId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  events: z.lazy(() => EventUncheckedUpdateManyWithoutTeamNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.TeamUncheckedUpdateWithoutPositionsInput>;

export const TeamCreateWithoutEventsInputSchema: z.ZodType<Prisma.TeamCreateWithoutEventsInput> = z.object({
  uuid: z.string().optional(),
  connected: z.boolean().optional(),
  currentChallenge: z.number().int().optional(),
  challengeStatus: z.lazy(() => ChallengeStatusSchema).optional(),
  licenceUsed: z.number().int().optional(),
  max_players: z.number().int().optional(),
  players: z.union([ z.lazy(() => TeamCreateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.lazy(() => TeamStatusSchema).optional(),
  token: z.string(),
  spectoken: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  positions: z.lazy(() => PositionCreateNestedManyWithoutTeamInputSchema).optional(),
  gameservers: z.lazy(() => GameserverCreateNestedOneWithoutTeamsInputSchema).optional(),
  sessions: z.lazy(() => SessionCreateNestedOneWithoutTeamsInputSchema)
}).strict() as z.ZodType<Prisma.TeamCreateWithoutEventsInput>;

export const TeamUncheckedCreateWithoutEventsInputSchema: z.ZodType<Prisma.TeamUncheckedCreateWithoutEventsInput> = z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  connected: z.boolean().optional(),
  currentChallenge: z.number().int().optional(),
  challengeStatus: z.lazy(() => ChallengeStatusSchema).optional(),
  licenceUsed: z.number().int().optional(),
  max_players: z.number().int().optional(),
  players: z.union([ z.lazy(() => TeamCreateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.lazy(() => TeamStatusSchema).optional(),
  token: z.string(),
  spectoken: z.string(),
  gameserversId: z.number().int().optional().nullable(),
  sessionsId: z.number().int(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  positions: z.lazy(() => PositionUncheckedCreateNestedManyWithoutTeamInputSchema).optional()
}).strict() as z.ZodType<Prisma.TeamUncheckedCreateWithoutEventsInput>;

export const TeamCreateOrConnectWithoutEventsInputSchema: z.ZodType<Prisma.TeamCreateOrConnectWithoutEventsInput> = z.object({
  where: z.lazy(() => TeamWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => TeamCreateWithoutEventsInputSchema),z.lazy(() => TeamUncheckedCreateWithoutEventsInputSchema) ]),
}).strict() as z.ZodType<Prisma.TeamCreateOrConnectWithoutEventsInput>;

export const TeamUpsertWithoutEventsInputSchema: z.ZodType<Prisma.TeamUpsertWithoutEventsInput> = z.object({
  update: z.union([ z.lazy(() => TeamUpdateWithoutEventsInputSchema),z.lazy(() => TeamUncheckedUpdateWithoutEventsInputSchema) ]),
  create: z.union([ z.lazy(() => TeamCreateWithoutEventsInputSchema),z.lazy(() => TeamUncheckedCreateWithoutEventsInputSchema) ]),
  where: z.lazy(() => TeamWhereInputSchema).optional()
}).strict() as z.ZodType<Prisma.TeamUpsertWithoutEventsInput>;

export const TeamUpdateToOneWithWhereWithoutEventsInputSchema: z.ZodType<Prisma.TeamUpdateToOneWithWhereWithoutEventsInput> = z.object({
  where: z.lazy(() => TeamWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => TeamUpdateWithoutEventsInputSchema),z.lazy(() => TeamUncheckedUpdateWithoutEventsInputSchema) ]),
}).strict() as z.ZodType<Prisma.TeamUpdateToOneWithWhereWithoutEventsInput>;

export const TeamUpdateWithoutEventsInputSchema: z.ZodType<Prisma.TeamUpdateWithoutEventsInput> = z.object({
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  connected: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  currentChallenge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  challengeStatus: z.union([ z.lazy(() => ChallengeStatusSchema),z.lazy(() => EnumChallengeStatusFieldUpdateOperationsInputSchema) ]).optional(),
  licenceUsed: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  max_players: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  players: z.union([ z.lazy(() => TeamUpdateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.union([ z.lazy(() => TeamStatusSchema),z.lazy(() => EnumTeamStatusFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  spectoken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  positions: z.lazy(() => PositionUpdateManyWithoutTeamNestedInputSchema).optional(),
  gameservers: z.lazy(() => GameserverUpdateOneWithoutTeamsNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUpdateOneRequiredWithoutTeamsNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.TeamUpdateWithoutEventsInput>;

export const TeamUncheckedUpdateWithoutEventsInputSchema: z.ZodType<Prisma.TeamUncheckedUpdateWithoutEventsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  connected: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  currentChallenge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  challengeStatus: z.union([ z.lazy(() => ChallengeStatusSchema),z.lazy(() => EnumChallengeStatusFieldUpdateOperationsInputSchema) ]).optional(),
  licenceUsed: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  max_players: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  players: z.union([ z.lazy(() => TeamUpdateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.union([ z.lazy(() => TeamStatusSchema),z.lazy(() => EnumTeamStatusFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  spectoken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  gameserversId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sessionsId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  positions: z.lazy(() => PositionUncheckedUpdateManyWithoutTeamNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.TeamUncheckedUpdateWithoutEventsInput>;

export const UserCreateWithoutCompanyInputSchema: z.ZodType<Prisma.UserCreateWithoutCompanyInput> = z.object({
  uuid: z.string().optional(),
  email: z.string(),
  hash: z.string(),
  name: z.string(),
  role: z.lazy(() => RoleSchema),
  salt: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict() as z.ZodType<Prisma.UserCreateWithoutCompanyInput>;

export const UserUncheckedCreateWithoutCompanyInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutCompanyInput> = z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  email: z.string(),
  hash: z.string(),
  name: z.string(),
  role: z.lazy(() => RoleSchema),
  salt: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict() as z.ZodType<Prisma.UserUncheckedCreateWithoutCompanyInput>;

export const UserCreateOrConnectWithoutCompanyInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutCompanyInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutCompanyInputSchema),z.lazy(() => UserUncheckedCreateWithoutCompanyInputSchema) ]),
}).strict() as z.ZodType<Prisma.UserCreateOrConnectWithoutCompanyInput>;

export const UserCreateManyCompanyInputEnvelopeSchema: z.ZodType<Prisma.UserCreateManyCompanyInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => UserCreateManyCompanyInputSchema),z.lazy(() => UserCreateManyCompanyInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict() as z.ZodType<Prisma.UserCreateManyCompanyInputEnvelope>;

export const SessionCreateWithoutCompanyInputSchema: z.ZodType<Prisma.SessionCreateWithoutCompanyInput> = z.object({
  uuid: z.string().optional(),
  challenges: z.union([ z.lazy(() => SessionCreatechallengesInputSchema),z.string().array() ]).optional(),
  name: z.string(),
  status: z.lazy(() => SessionStatusSchema).optional(),
  time_limit: z.number().int().optional().nullable(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  teams: z.lazy(() => TeamCreateNestedManyWithoutSessionsInputSchema).optional()
}).strict() as z.ZodType<Prisma.SessionCreateWithoutCompanyInput>;

export const SessionUncheckedCreateWithoutCompanyInputSchema: z.ZodType<Prisma.SessionUncheckedCreateWithoutCompanyInput> = z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  challenges: z.union([ z.lazy(() => SessionCreatechallengesInputSchema),z.string().array() ]).optional(),
  name: z.string(),
  status: z.lazy(() => SessionStatusSchema).optional(),
  time_limit: z.number().int().optional().nullable(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  teams: z.lazy(() => TeamUncheckedCreateNestedManyWithoutSessionsInputSchema).optional()
}).strict() as z.ZodType<Prisma.SessionUncheckedCreateWithoutCompanyInput>;

export const SessionCreateOrConnectWithoutCompanyInputSchema: z.ZodType<Prisma.SessionCreateOrConnectWithoutCompanyInput> = z.object({
  where: z.lazy(() => SessionWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SessionCreateWithoutCompanyInputSchema),z.lazy(() => SessionUncheckedCreateWithoutCompanyInputSchema) ]),
}).strict() as z.ZodType<Prisma.SessionCreateOrConnectWithoutCompanyInput>;

export const SessionCreateManyCompanyInputEnvelopeSchema: z.ZodType<Prisma.SessionCreateManyCompanyInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => SessionCreateManyCompanyInputSchema),z.lazy(() => SessionCreateManyCompanyInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict() as z.ZodType<Prisma.SessionCreateManyCompanyInputEnvelope>;

export const UserUpsertWithWhereUniqueWithoutCompanyInputSchema: z.ZodType<Prisma.UserUpsertWithWhereUniqueWithoutCompanyInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => UserUpdateWithoutCompanyInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCompanyInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutCompanyInputSchema),z.lazy(() => UserUncheckedCreateWithoutCompanyInputSchema) ]),
}).strict() as z.ZodType<Prisma.UserUpsertWithWhereUniqueWithoutCompanyInput>;

export const UserUpdateWithWhereUniqueWithoutCompanyInputSchema: z.ZodType<Prisma.UserUpdateWithWhereUniqueWithoutCompanyInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => UserUpdateWithoutCompanyInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCompanyInputSchema) ]),
}).strict() as z.ZodType<Prisma.UserUpdateWithWhereUniqueWithoutCompanyInput>;

export const UserUpdateManyWithWhereWithoutCompanyInputSchema: z.ZodType<Prisma.UserUpdateManyWithWhereWithoutCompanyInput> = z.object({
  where: z.lazy(() => UserScalarWhereInputSchema),
  data: z.union([ z.lazy(() => UserUpdateManyMutationInputSchema),z.lazy(() => UserUncheckedUpdateManyWithoutCompanyInputSchema) ]),
}).strict() as z.ZodType<Prisma.UserUpdateManyWithWhereWithoutCompanyInput>;

export const UserScalarWhereInputSchema: z.ZodType<Prisma.UserScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => UserScalarWhereInputSchema),z.lazy(() => UserScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => UserScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => UserScalarWhereInputSchema),z.lazy(() => UserScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  uuid: z.union([ z.lazy(() => UuidFilterSchema),z.string() ]).optional(),
  companyId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  email: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  hash: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  role: z.union([ z.lazy(() => EnumRoleFilterSchema),z.lazy(() => RoleSchema) ]).optional(),
  salt: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict() as z.ZodType<Prisma.UserScalarWhereInput>;

export const SessionUpsertWithWhereUniqueWithoutCompanyInputSchema: z.ZodType<Prisma.SessionUpsertWithWhereUniqueWithoutCompanyInput> = z.object({
  where: z.lazy(() => SessionWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => SessionUpdateWithoutCompanyInputSchema),z.lazy(() => SessionUncheckedUpdateWithoutCompanyInputSchema) ]),
  create: z.union([ z.lazy(() => SessionCreateWithoutCompanyInputSchema),z.lazy(() => SessionUncheckedCreateWithoutCompanyInputSchema) ]),
}).strict() as z.ZodType<Prisma.SessionUpsertWithWhereUniqueWithoutCompanyInput>;

export const SessionUpdateWithWhereUniqueWithoutCompanyInputSchema: z.ZodType<Prisma.SessionUpdateWithWhereUniqueWithoutCompanyInput> = z.object({
  where: z.lazy(() => SessionWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => SessionUpdateWithoutCompanyInputSchema),z.lazy(() => SessionUncheckedUpdateWithoutCompanyInputSchema) ]),
}).strict() as z.ZodType<Prisma.SessionUpdateWithWhereUniqueWithoutCompanyInput>;

export const SessionUpdateManyWithWhereWithoutCompanyInputSchema: z.ZodType<Prisma.SessionUpdateManyWithWhereWithoutCompanyInput> = z.object({
  where: z.lazy(() => SessionScalarWhereInputSchema),
  data: z.union([ z.lazy(() => SessionUpdateManyMutationInputSchema),z.lazy(() => SessionUncheckedUpdateManyWithoutCompanyInputSchema) ]),
}).strict() as z.ZodType<Prisma.SessionUpdateManyWithWhereWithoutCompanyInput>;

export const SessionScalarWhereInputSchema: z.ZodType<Prisma.SessionScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => SessionScalarWhereInputSchema),z.lazy(() => SessionScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SessionScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SessionScalarWhereInputSchema),z.lazy(() => SessionScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  uuid: z.union([ z.lazy(() => UuidFilterSchema),z.string() ]).optional(),
  challenges: z.lazy(() => StringNullableListFilterSchema).optional(),
  companyId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => EnumSessionStatusFilterSchema),z.lazy(() => SessionStatusSchema) ]).optional(),
  time_limit: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  start: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  end: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict() as z.ZodType<Prisma.SessionScalarWhereInput>;

export const TeamCreateWithoutGameserversInputSchema: z.ZodType<Prisma.TeamCreateWithoutGameserversInput> = z.object({
  uuid: z.string().optional(),
  connected: z.boolean().optional(),
  currentChallenge: z.number().int().optional(),
  challengeStatus: z.lazy(() => ChallengeStatusSchema).optional(),
  licenceUsed: z.number().int().optional(),
  max_players: z.number().int().optional(),
  players: z.union([ z.lazy(() => TeamCreateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.lazy(() => TeamStatusSchema).optional(),
  token: z.string(),
  spectoken: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  events: z.lazy(() => EventCreateNestedManyWithoutTeamInputSchema).optional(),
  positions: z.lazy(() => PositionCreateNestedManyWithoutTeamInputSchema).optional(),
  sessions: z.lazy(() => SessionCreateNestedOneWithoutTeamsInputSchema)
}).strict() as z.ZodType<Prisma.TeamCreateWithoutGameserversInput>;

export const TeamUncheckedCreateWithoutGameserversInputSchema: z.ZodType<Prisma.TeamUncheckedCreateWithoutGameserversInput> = z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  connected: z.boolean().optional(),
  currentChallenge: z.number().int().optional(),
  challengeStatus: z.lazy(() => ChallengeStatusSchema).optional(),
  licenceUsed: z.number().int().optional(),
  max_players: z.number().int().optional(),
  players: z.union([ z.lazy(() => TeamCreateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.lazy(() => TeamStatusSchema).optional(),
  token: z.string(),
  spectoken: z.string(),
  sessionsId: z.number().int(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  events: z.lazy(() => EventUncheckedCreateNestedManyWithoutTeamInputSchema).optional(),
  positions: z.lazy(() => PositionUncheckedCreateNestedManyWithoutTeamInputSchema).optional()
}).strict() as z.ZodType<Prisma.TeamUncheckedCreateWithoutGameserversInput>;

export const TeamCreateOrConnectWithoutGameserversInputSchema: z.ZodType<Prisma.TeamCreateOrConnectWithoutGameserversInput> = z.object({
  where: z.lazy(() => TeamWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => TeamCreateWithoutGameserversInputSchema),z.lazy(() => TeamUncheckedCreateWithoutGameserversInputSchema) ]),
}).strict() as z.ZodType<Prisma.TeamCreateOrConnectWithoutGameserversInput>;

export const TeamCreateManyGameserversInputEnvelopeSchema: z.ZodType<Prisma.TeamCreateManyGameserversInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => TeamCreateManyGameserversInputSchema),z.lazy(() => TeamCreateManyGameserversInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict() as z.ZodType<Prisma.TeamCreateManyGameserversInputEnvelope>;

export const TeamUpsertWithWhereUniqueWithoutGameserversInputSchema: z.ZodType<Prisma.TeamUpsertWithWhereUniqueWithoutGameserversInput> = z.object({
  where: z.lazy(() => TeamWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => TeamUpdateWithoutGameserversInputSchema),z.lazy(() => TeamUncheckedUpdateWithoutGameserversInputSchema) ]),
  create: z.union([ z.lazy(() => TeamCreateWithoutGameserversInputSchema),z.lazy(() => TeamUncheckedCreateWithoutGameserversInputSchema) ]),
}).strict() as z.ZodType<Prisma.TeamUpsertWithWhereUniqueWithoutGameserversInput>;

export const TeamUpdateWithWhereUniqueWithoutGameserversInputSchema: z.ZodType<Prisma.TeamUpdateWithWhereUniqueWithoutGameserversInput> = z.object({
  where: z.lazy(() => TeamWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => TeamUpdateWithoutGameserversInputSchema),z.lazy(() => TeamUncheckedUpdateWithoutGameserversInputSchema) ]),
}).strict() as z.ZodType<Prisma.TeamUpdateWithWhereUniqueWithoutGameserversInput>;

export const TeamUpdateManyWithWhereWithoutGameserversInputSchema: z.ZodType<Prisma.TeamUpdateManyWithWhereWithoutGameserversInput> = z.object({
  where: z.lazy(() => TeamScalarWhereInputSchema),
  data: z.union([ z.lazy(() => TeamUpdateManyMutationInputSchema),z.lazy(() => TeamUncheckedUpdateManyWithoutGameserversInputSchema) ]),
}).strict() as z.ZodType<Prisma.TeamUpdateManyWithWhereWithoutGameserversInput>;

export const TeamScalarWhereInputSchema: z.ZodType<Prisma.TeamScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => TeamScalarWhereInputSchema),z.lazy(() => TeamScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => TeamScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => TeamScalarWhereInputSchema),z.lazy(() => TeamScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  uuid: z.union([ z.lazy(() => UuidFilterSchema),z.string() ]).optional(),
  connected: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  currentChallenge: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  challengeStatus: z.union([ z.lazy(() => EnumChallengeStatusFilterSchema),z.lazy(() => ChallengeStatusSchema) ]).optional(),
  licenceUsed: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  max_players: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  players: z.lazy(() => JsonNullableListFilterSchema).optional(),
  status: z.union([ z.lazy(() => EnumTeamStatusFilterSchema),z.lazy(() => TeamStatusSchema) ]).optional(),
  token: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  spectoken: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  gameserversId: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  sessionsId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict() as z.ZodType<Prisma.TeamScalarWhereInput>;

export const CompanyCreateWithoutSessionsInputSchema: z.ZodType<Prisma.CompanyCreateWithoutSessionsInput> = z.object({
  uuid: z.string().optional(),
  name: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  users: z.lazy(() => UserCreateNestedManyWithoutCompanyInputSchema).optional()
}).strict() as z.ZodType<Prisma.CompanyCreateWithoutSessionsInput>;

export const CompanyUncheckedCreateWithoutSessionsInputSchema: z.ZodType<Prisma.CompanyUncheckedCreateWithoutSessionsInput> = z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  name: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  users: z.lazy(() => UserUncheckedCreateNestedManyWithoutCompanyInputSchema).optional()
}).strict() as z.ZodType<Prisma.CompanyUncheckedCreateWithoutSessionsInput>;

export const CompanyCreateOrConnectWithoutSessionsInputSchema: z.ZodType<Prisma.CompanyCreateOrConnectWithoutSessionsInput> = z.object({
  where: z.lazy(() => CompanyWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CompanyCreateWithoutSessionsInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutSessionsInputSchema) ]),
}).strict() as z.ZodType<Prisma.CompanyCreateOrConnectWithoutSessionsInput>;

export const TeamCreateWithoutSessionsInputSchema: z.ZodType<Prisma.TeamCreateWithoutSessionsInput> = z.object({
  uuid: z.string().optional(),
  connected: z.boolean().optional(),
  currentChallenge: z.number().int().optional(),
  challengeStatus: z.lazy(() => ChallengeStatusSchema).optional(),
  licenceUsed: z.number().int().optional(),
  max_players: z.number().int().optional(),
  players: z.union([ z.lazy(() => TeamCreateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.lazy(() => TeamStatusSchema).optional(),
  token: z.string(),
  spectoken: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  events: z.lazy(() => EventCreateNestedManyWithoutTeamInputSchema).optional(),
  positions: z.lazy(() => PositionCreateNestedManyWithoutTeamInputSchema).optional(),
  gameservers: z.lazy(() => GameserverCreateNestedOneWithoutTeamsInputSchema).optional()
}).strict() as z.ZodType<Prisma.TeamCreateWithoutSessionsInput>;

export const TeamUncheckedCreateWithoutSessionsInputSchema: z.ZodType<Prisma.TeamUncheckedCreateWithoutSessionsInput> = z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  connected: z.boolean().optional(),
  currentChallenge: z.number().int().optional(),
  challengeStatus: z.lazy(() => ChallengeStatusSchema).optional(),
  licenceUsed: z.number().int().optional(),
  max_players: z.number().int().optional(),
  players: z.union([ z.lazy(() => TeamCreateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.lazy(() => TeamStatusSchema).optional(),
  token: z.string(),
  spectoken: z.string(),
  gameserversId: z.number().int().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  events: z.lazy(() => EventUncheckedCreateNestedManyWithoutTeamInputSchema).optional(),
  positions: z.lazy(() => PositionUncheckedCreateNestedManyWithoutTeamInputSchema).optional()
}).strict() as z.ZodType<Prisma.TeamUncheckedCreateWithoutSessionsInput>;

export const TeamCreateOrConnectWithoutSessionsInputSchema: z.ZodType<Prisma.TeamCreateOrConnectWithoutSessionsInput> = z.object({
  where: z.lazy(() => TeamWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => TeamCreateWithoutSessionsInputSchema),z.lazy(() => TeamUncheckedCreateWithoutSessionsInputSchema) ]),
}).strict() as z.ZodType<Prisma.TeamCreateOrConnectWithoutSessionsInput>;

export const TeamCreateManySessionsInputEnvelopeSchema: z.ZodType<Prisma.TeamCreateManySessionsInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => TeamCreateManySessionsInputSchema),z.lazy(() => TeamCreateManySessionsInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict() as z.ZodType<Prisma.TeamCreateManySessionsInputEnvelope>;

export const CompanyUpsertWithoutSessionsInputSchema: z.ZodType<Prisma.CompanyUpsertWithoutSessionsInput> = z.object({
  update: z.union([ z.lazy(() => CompanyUpdateWithoutSessionsInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutSessionsInputSchema) ]),
  create: z.union([ z.lazy(() => CompanyCreateWithoutSessionsInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutSessionsInputSchema) ]),
  where: z.lazy(() => CompanyWhereInputSchema).optional()
}).strict() as z.ZodType<Prisma.CompanyUpsertWithoutSessionsInput>;

export const CompanyUpdateToOneWithWhereWithoutSessionsInputSchema: z.ZodType<Prisma.CompanyUpdateToOneWithWhereWithoutSessionsInput> = z.object({
  where: z.lazy(() => CompanyWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CompanyUpdateWithoutSessionsInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutSessionsInputSchema) ]),
}).strict() as z.ZodType<Prisma.CompanyUpdateToOneWithWhereWithoutSessionsInput>;

export const CompanyUpdateWithoutSessionsInputSchema: z.ZodType<Prisma.CompanyUpdateWithoutSessionsInput> = z.object({
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  users: z.lazy(() => UserUpdateManyWithoutCompanyNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.CompanyUpdateWithoutSessionsInput>;

export const CompanyUncheckedUpdateWithoutSessionsInputSchema: z.ZodType<Prisma.CompanyUncheckedUpdateWithoutSessionsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  users: z.lazy(() => UserUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.CompanyUncheckedUpdateWithoutSessionsInput>;

export const TeamUpsertWithWhereUniqueWithoutSessionsInputSchema: z.ZodType<Prisma.TeamUpsertWithWhereUniqueWithoutSessionsInput> = z.object({
  where: z.lazy(() => TeamWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => TeamUpdateWithoutSessionsInputSchema),z.lazy(() => TeamUncheckedUpdateWithoutSessionsInputSchema) ]),
  create: z.union([ z.lazy(() => TeamCreateWithoutSessionsInputSchema),z.lazy(() => TeamUncheckedCreateWithoutSessionsInputSchema) ]),
}).strict() as z.ZodType<Prisma.TeamUpsertWithWhereUniqueWithoutSessionsInput>;

export const TeamUpdateWithWhereUniqueWithoutSessionsInputSchema: z.ZodType<Prisma.TeamUpdateWithWhereUniqueWithoutSessionsInput> = z.object({
  where: z.lazy(() => TeamWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => TeamUpdateWithoutSessionsInputSchema),z.lazy(() => TeamUncheckedUpdateWithoutSessionsInputSchema) ]),
}).strict() as z.ZodType<Prisma.TeamUpdateWithWhereUniqueWithoutSessionsInput>;

export const TeamUpdateManyWithWhereWithoutSessionsInputSchema: z.ZodType<Prisma.TeamUpdateManyWithWhereWithoutSessionsInput> = z.object({
  where: z.lazy(() => TeamScalarWhereInputSchema),
  data: z.union([ z.lazy(() => TeamUpdateManyMutationInputSchema),z.lazy(() => TeamUncheckedUpdateManyWithoutSessionsInputSchema) ]),
}).strict() as z.ZodType<Prisma.TeamUpdateManyWithWhereWithoutSessionsInput>;

export const EventCreateWithoutTeamInputSchema: z.ZodType<Prisma.EventCreateWithoutTeamInput> = z.object({
  LogType: z.string(),
  Parameter: z.string(),
  Player: z.number().int(),
  TriggeredObject: z.string(),
  timestamp: z.coerce.date()
}).strict() as z.ZodType<Prisma.EventCreateWithoutTeamInput>;

export const EventUncheckedCreateWithoutTeamInputSchema: z.ZodType<Prisma.EventUncheckedCreateWithoutTeamInput> = z.object({
  id: z.number().int().optional(),
  LogType: z.string(),
  Parameter: z.string(),
  Player: z.number().int(),
  TriggeredObject: z.string(),
  timestamp: z.coerce.date()
}).strict() as z.ZodType<Prisma.EventUncheckedCreateWithoutTeamInput>;

export const EventCreateOrConnectWithoutTeamInputSchema: z.ZodType<Prisma.EventCreateOrConnectWithoutTeamInput> = z.object({
  where: z.lazy(() => EventWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => EventCreateWithoutTeamInputSchema),z.lazy(() => EventUncheckedCreateWithoutTeamInputSchema) ]),
}).strict() as z.ZodType<Prisma.EventCreateOrConnectWithoutTeamInput>;

export const EventCreateManyTeamInputEnvelopeSchema: z.ZodType<Prisma.EventCreateManyTeamInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => EventCreateManyTeamInputSchema),z.lazy(() => EventCreateManyTeamInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict() as z.ZodType<Prisma.EventCreateManyTeamInputEnvelope>;

export const PositionCreateWithoutTeamInputSchema: z.ZodType<Prisma.PositionCreateWithoutTeamInput> = z.object({
  locations: z.union([ z.lazy(() => JsonNullValueInputSchema),z.object({slot: z.number(), location: z.number().array().length(3)}).array() ]),
}).strict() as z.ZodType<Prisma.PositionCreateWithoutTeamInput>;

export const PositionUncheckedCreateWithoutTeamInputSchema: z.ZodType<Prisma.PositionUncheckedCreateWithoutTeamInput> = z.object({
  id: z.number().int().optional(),
  locations: z.union([ z.lazy(() => JsonNullValueInputSchema),z.object({slot: z.number(), location: z.number().array().length(3)}).array() ]),
}).strict() as z.ZodType<Prisma.PositionUncheckedCreateWithoutTeamInput>;

export const PositionCreateOrConnectWithoutTeamInputSchema: z.ZodType<Prisma.PositionCreateOrConnectWithoutTeamInput> = z.object({
  where: z.lazy(() => PositionWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => PositionCreateWithoutTeamInputSchema),z.lazy(() => PositionUncheckedCreateWithoutTeamInputSchema) ]),
}).strict() as z.ZodType<Prisma.PositionCreateOrConnectWithoutTeamInput>;

export const PositionCreateManyTeamInputEnvelopeSchema: z.ZodType<Prisma.PositionCreateManyTeamInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => PositionCreateManyTeamInputSchema),z.lazy(() => PositionCreateManyTeamInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict() as z.ZodType<Prisma.PositionCreateManyTeamInputEnvelope>;

export const GameserverCreateWithoutTeamsInputSchema: z.ZodType<Prisma.GameserverCreateWithoutTeamsInput> = z.object({
  uuid: z.string().optional(),
  capacity: z.number().int(),
  hostname: z.string().optional().nullable(),
  provider: z.lazy(() => GameserverProviderSchema).optional(),
  state: z.lazy(() => InstanceStateSchema),
  instanceId: z.string().optional().nullable(),
  socket: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict() as z.ZodType<Prisma.GameserverCreateWithoutTeamsInput>;

export const GameserverUncheckedCreateWithoutTeamsInputSchema: z.ZodType<Prisma.GameserverUncheckedCreateWithoutTeamsInput> = z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  capacity: z.number().int(),
  hostname: z.string().optional().nullable(),
  provider: z.lazy(() => GameserverProviderSchema).optional(),
  state: z.lazy(() => InstanceStateSchema),
  instanceId: z.string().optional().nullable(),
  socket: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict() as z.ZodType<Prisma.GameserverUncheckedCreateWithoutTeamsInput>;

export const GameserverCreateOrConnectWithoutTeamsInputSchema: z.ZodType<Prisma.GameserverCreateOrConnectWithoutTeamsInput> = z.object({
  where: z.lazy(() => GameserverWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => GameserverCreateWithoutTeamsInputSchema),z.lazy(() => GameserverUncheckedCreateWithoutTeamsInputSchema) ]),
}).strict() as z.ZodType<Prisma.GameserverCreateOrConnectWithoutTeamsInput>;

export const SessionCreateWithoutTeamsInputSchema: z.ZodType<Prisma.SessionCreateWithoutTeamsInput> = z.object({
  uuid: z.string().optional(),
  challenges: z.union([ z.lazy(() => SessionCreatechallengesInputSchema),z.string().array() ]).optional(),
  name: z.string(),
  status: z.lazy(() => SessionStatusSchema).optional(),
  time_limit: z.number().int().optional().nullable(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutSessionsInputSchema)
}).strict() as z.ZodType<Prisma.SessionCreateWithoutTeamsInput>;

export const SessionUncheckedCreateWithoutTeamsInputSchema: z.ZodType<Prisma.SessionUncheckedCreateWithoutTeamsInput> = z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  challenges: z.union([ z.lazy(() => SessionCreatechallengesInputSchema),z.string().array() ]).optional(),
  companyId: z.number().int(),
  name: z.string(),
  status: z.lazy(() => SessionStatusSchema).optional(),
  time_limit: z.number().int().optional().nullable(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict() as z.ZodType<Prisma.SessionUncheckedCreateWithoutTeamsInput>;

export const SessionCreateOrConnectWithoutTeamsInputSchema: z.ZodType<Prisma.SessionCreateOrConnectWithoutTeamsInput> = z.object({
  where: z.lazy(() => SessionWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SessionCreateWithoutTeamsInputSchema),z.lazy(() => SessionUncheckedCreateWithoutTeamsInputSchema) ]),
}).strict() as z.ZodType<Prisma.SessionCreateOrConnectWithoutTeamsInput>;

export const EventUpsertWithWhereUniqueWithoutTeamInputSchema: z.ZodType<Prisma.EventUpsertWithWhereUniqueWithoutTeamInput> = z.object({
  where: z.lazy(() => EventWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => EventUpdateWithoutTeamInputSchema),z.lazy(() => EventUncheckedUpdateWithoutTeamInputSchema) ]),
  create: z.union([ z.lazy(() => EventCreateWithoutTeamInputSchema),z.lazy(() => EventUncheckedCreateWithoutTeamInputSchema) ]),
}).strict() as z.ZodType<Prisma.EventUpsertWithWhereUniqueWithoutTeamInput>;

export const EventUpdateWithWhereUniqueWithoutTeamInputSchema: z.ZodType<Prisma.EventUpdateWithWhereUniqueWithoutTeamInput> = z.object({
  where: z.lazy(() => EventWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => EventUpdateWithoutTeamInputSchema),z.lazy(() => EventUncheckedUpdateWithoutTeamInputSchema) ]),
}).strict() as z.ZodType<Prisma.EventUpdateWithWhereUniqueWithoutTeamInput>;

export const EventUpdateManyWithWhereWithoutTeamInputSchema: z.ZodType<Prisma.EventUpdateManyWithWhereWithoutTeamInput> = z.object({
  where: z.lazy(() => EventScalarWhereInputSchema),
  data: z.union([ z.lazy(() => EventUpdateManyMutationInputSchema),z.lazy(() => EventUncheckedUpdateManyWithoutTeamInputSchema) ]),
}).strict() as z.ZodType<Prisma.EventUpdateManyWithWhereWithoutTeamInput>;

export const EventScalarWhereInputSchema: z.ZodType<Prisma.EventScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => EventScalarWhereInputSchema),z.lazy(() => EventScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => EventScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => EventScalarWhereInputSchema),z.lazy(() => EventScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  LogType: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  Parameter: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  Player: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  TriggeredObject: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  timestamp: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  teamId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
}).strict() as z.ZodType<Prisma.EventScalarWhereInput>;

export const PositionUpsertWithWhereUniqueWithoutTeamInputSchema: z.ZodType<Prisma.PositionUpsertWithWhereUniqueWithoutTeamInput> = z.object({
  where: z.lazy(() => PositionWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => PositionUpdateWithoutTeamInputSchema),z.lazy(() => PositionUncheckedUpdateWithoutTeamInputSchema) ]),
  create: z.union([ z.lazy(() => PositionCreateWithoutTeamInputSchema),z.lazy(() => PositionUncheckedCreateWithoutTeamInputSchema) ]),
}).strict() as z.ZodType<Prisma.PositionUpsertWithWhereUniqueWithoutTeamInput>;

export const PositionUpdateWithWhereUniqueWithoutTeamInputSchema: z.ZodType<Prisma.PositionUpdateWithWhereUniqueWithoutTeamInput> = z.object({
  where: z.lazy(() => PositionWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => PositionUpdateWithoutTeamInputSchema),z.lazy(() => PositionUncheckedUpdateWithoutTeamInputSchema) ]),
}).strict() as z.ZodType<Prisma.PositionUpdateWithWhereUniqueWithoutTeamInput>;

export const PositionUpdateManyWithWhereWithoutTeamInputSchema: z.ZodType<Prisma.PositionUpdateManyWithWhereWithoutTeamInput> = z.object({
  where: z.lazy(() => PositionScalarWhereInputSchema),
  data: z.union([ z.lazy(() => PositionUpdateManyMutationInputSchema),z.lazy(() => PositionUncheckedUpdateManyWithoutTeamInputSchema) ]),
}).strict() as z.ZodType<Prisma.PositionUpdateManyWithWhereWithoutTeamInput>;

export const PositionScalarWhereInputSchema: z.ZodType<Prisma.PositionScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PositionScalarWhereInputSchema),z.lazy(() => PositionScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PositionScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PositionScalarWhereInputSchema),z.lazy(() => PositionScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  locations: z.lazy(() => JsonFilterSchema).optional(),
  teamId: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
}).strict() as z.ZodType<Prisma.PositionScalarWhereInput>;

export const GameserverUpsertWithoutTeamsInputSchema: z.ZodType<Prisma.GameserverUpsertWithoutTeamsInput> = z.object({
  update: z.union([ z.lazy(() => GameserverUpdateWithoutTeamsInputSchema),z.lazy(() => GameserverUncheckedUpdateWithoutTeamsInputSchema) ]),
  create: z.union([ z.lazy(() => GameserverCreateWithoutTeamsInputSchema),z.lazy(() => GameserverUncheckedCreateWithoutTeamsInputSchema) ]),
  where: z.lazy(() => GameserverWhereInputSchema).optional()
}).strict() as z.ZodType<Prisma.GameserverUpsertWithoutTeamsInput>;

export const GameserverUpdateToOneWithWhereWithoutTeamsInputSchema: z.ZodType<Prisma.GameserverUpdateToOneWithWhereWithoutTeamsInput> = z.object({
  where: z.lazy(() => GameserverWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => GameserverUpdateWithoutTeamsInputSchema),z.lazy(() => GameserverUncheckedUpdateWithoutTeamsInputSchema) ]),
}).strict() as z.ZodType<Prisma.GameserverUpdateToOneWithWhereWithoutTeamsInput>;

export const GameserverUpdateWithoutTeamsInputSchema: z.ZodType<Prisma.GameserverUpdateWithoutTeamsInput> = z.object({
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  capacity: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  hostname: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  provider: z.union([ z.lazy(() => GameserverProviderSchema),z.lazy(() => EnumGameserverProviderFieldUpdateOperationsInputSchema) ]).optional(),
  state: z.union([ z.lazy(() => InstanceStateSchema),z.lazy(() => EnumInstanceStateFieldUpdateOperationsInputSchema) ]).optional(),
  instanceId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  socket: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.GameserverUpdateWithoutTeamsInput>;

export const GameserverUncheckedUpdateWithoutTeamsInputSchema: z.ZodType<Prisma.GameserverUncheckedUpdateWithoutTeamsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  capacity: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  hostname: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  provider: z.union([ z.lazy(() => GameserverProviderSchema),z.lazy(() => EnumGameserverProviderFieldUpdateOperationsInputSchema) ]).optional(),
  state: z.union([ z.lazy(() => InstanceStateSchema),z.lazy(() => EnumInstanceStateFieldUpdateOperationsInputSchema) ]).optional(),
  instanceId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  socket: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.GameserverUncheckedUpdateWithoutTeamsInput>;

export const SessionUpsertWithoutTeamsInputSchema: z.ZodType<Prisma.SessionUpsertWithoutTeamsInput> = z.object({
  update: z.union([ z.lazy(() => SessionUpdateWithoutTeamsInputSchema),z.lazy(() => SessionUncheckedUpdateWithoutTeamsInputSchema) ]),
  create: z.union([ z.lazy(() => SessionCreateWithoutTeamsInputSchema),z.lazy(() => SessionUncheckedCreateWithoutTeamsInputSchema) ]),
  where: z.lazy(() => SessionWhereInputSchema).optional()
}).strict() as z.ZodType<Prisma.SessionUpsertWithoutTeamsInput>;

export const SessionUpdateToOneWithWhereWithoutTeamsInputSchema: z.ZodType<Prisma.SessionUpdateToOneWithWhereWithoutTeamsInput> = z.object({
  where: z.lazy(() => SessionWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => SessionUpdateWithoutTeamsInputSchema),z.lazy(() => SessionUncheckedUpdateWithoutTeamsInputSchema) ]),
}).strict() as z.ZodType<Prisma.SessionUpdateToOneWithWhereWithoutTeamsInput>;

export const SessionUpdateWithoutTeamsInputSchema: z.ZodType<Prisma.SessionUpdateWithoutTeamsInput> = z.object({
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  challenges: z.union([ z.lazy(() => SessionUpdatechallengesInputSchema),z.string().array() ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SessionStatusSchema),z.lazy(() => EnumSessionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  time_limit: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  start: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  end: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  company: z.lazy(() => CompanyUpdateOneRequiredWithoutSessionsNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.SessionUpdateWithoutTeamsInput>;

export const SessionUncheckedUpdateWithoutTeamsInputSchema: z.ZodType<Prisma.SessionUncheckedUpdateWithoutTeamsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  challenges: z.union([ z.lazy(() => SessionUpdatechallengesInputSchema),z.string().array() ]).optional(),
  companyId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SessionStatusSchema),z.lazy(() => EnumSessionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  time_limit: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  start: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  end: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.SessionUncheckedUpdateWithoutTeamsInput>;

export const CompanyCreateWithoutUsersInputSchema: z.ZodType<Prisma.CompanyCreateWithoutUsersInput> = z.object({
  uuid: z.string().optional(),
  name: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  sessions: z.lazy(() => SessionCreateNestedManyWithoutCompanyInputSchema).optional()
}).strict() as z.ZodType<Prisma.CompanyCreateWithoutUsersInput>;

export const CompanyUncheckedCreateWithoutUsersInputSchema: z.ZodType<Prisma.CompanyUncheckedCreateWithoutUsersInput> = z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  name: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  sessions: z.lazy(() => SessionUncheckedCreateNestedManyWithoutCompanyInputSchema).optional()
}).strict() as z.ZodType<Prisma.CompanyUncheckedCreateWithoutUsersInput>;

export const CompanyCreateOrConnectWithoutUsersInputSchema: z.ZodType<Prisma.CompanyCreateOrConnectWithoutUsersInput> = z.object({
  where: z.lazy(() => CompanyWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CompanyCreateWithoutUsersInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutUsersInputSchema) ]),
}).strict() as z.ZodType<Prisma.CompanyCreateOrConnectWithoutUsersInput>;

export const CompanyUpsertWithoutUsersInputSchema: z.ZodType<Prisma.CompanyUpsertWithoutUsersInput> = z.object({
  update: z.union([ z.lazy(() => CompanyUpdateWithoutUsersInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutUsersInputSchema) ]),
  create: z.union([ z.lazy(() => CompanyCreateWithoutUsersInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutUsersInputSchema) ]),
  where: z.lazy(() => CompanyWhereInputSchema).optional()
}).strict() as z.ZodType<Prisma.CompanyUpsertWithoutUsersInput>;

export const CompanyUpdateToOneWithWhereWithoutUsersInputSchema: z.ZodType<Prisma.CompanyUpdateToOneWithWhereWithoutUsersInput> = z.object({
  where: z.lazy(() => CompanyWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CompanyUpdateWithoutUsersInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutUsersInputSchema) ]),
}).strict() as z.ZodType<Prisma.CompanyUpdateToOneWithWhereWithoutUsersInput>;

export const CompanyUpdateWithoutUsersInputSchema: z.ZodType<Prisma.CompanyUpdateWithoutUsersInput> = z.object({
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  sessions: z.lazy(() => SessionUpdateManyWithoutCompanyNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.CompanyUpdateWithoutUsersInput>;

export const CompanyUncheckedUpdateWithoutUsersInputSchema: z.ZodType<Prisma.CompanyUncheckedUpdateWithoutUsersInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  sessions: z.lazy(() => SessionUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.CompanyUncheckedUpdateWithoutUsersInput>;

export const UserCreateManyCompanyInputSchema: z.ZodType<Prisma.UserCreateManyCompanyInput> = z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  email: z.string(),
  hash: z.string(),
  name: z.string(),
  role: z.lazy(() => RoleSchema),
  salt: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict() as z.ZodType<Prisma.UserCreateManyCompanyInput>;

export const SessionCreateManyCompanyInputSchema: z.ZodType<Prisma.SessionCreateManyCompanyInput> = z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  challenges: z.union([ z.lazy(() => SessionCreatechallengesInputSchema),z.string().array() ]).optional(),
  name: z.string(),
  status: z.lazy(() => SessionStatusSchema).optional(),
  time_limit: z.number().int().optional().nullable(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict() as z.ZodType<Prisma.SessionCreateManyCompanyInput>;

export const UserUpdateWithoutCompanyInputSchema: z.ZodType<Prisma.UserUpdateWithoutCompanyInput> = z.object({
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  hash: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  role: z.union([ z.lazy(() => RoleSchema),z.lazy(() => EnumRoleFieldUpdateOperationsInputSchema) ]).optional(),
  salt: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.UserUpdateWithoutCompanyInput>;

export const UserUncheckedUpdateWithoutCompanyInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutCompanyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  hash: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  role: z.union([ z.lazy(() => RoleSchema),z.lazy(() => EnumRoleFieldUpdateOperationsInputSchema) ]).optional(),
  salt: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.UserUncheckedUpdateWithoutCompanyInput>;

export const UserUncheckedUpdateManyWithoutCompanyInputSchema: z.ZodType<Prisma.UserUncheckedUpdateManyWithoutCompanyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  hash: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  role: z.union([ z.lazy(() => RoleSchema),z.lazy(() => EnumRoleFieldUpdateOperationsInputSchema) ]).optional(),
  salt: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.UserUncheckedUpdateManyWithoutCompanyInput>;

export const SessionUpdateWithoutCompanyInputSchema: z.ZodType<Prisma.SessionUpdateWithoutCompanyInput> = z.object({
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  challenges: z.union([ z.lazy(() => SessionUpdatechallengesInputSchema),z.string().array() ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SessionStatusSchema),z.lazy(() => EnumSessionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  time_limit: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  start: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  end: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  teams: z.lazy(() => TeamUpdateManyWithoutSessionsNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.SessionUpdateWithoutCompanyInput>;

export const SessionUncheckedUpdateWithoutCompanyInputSchema: z.ZodType<Prisma.SessionUncheckedUpdateWithoutCompanyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  challenges: z.union([ z.lazy(() => SessionUpdatechallengesInputSchema),z.string().array() ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SessionStatusSchema),z.lazy(() => EnumSessionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  time_limit: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  start: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  end: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  teams: z.lazy(() => TeamUncheckedUpdateManyWithoutSessionsNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.SessionUncheckedUpdateWithoutCompanyInput>;

export const SessionUncheckedUpdateManyWithoutCompanyInputSchema: z.ZodType<Prisma.SessionUncheckedUpdateManyWithoutCompanyInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  challenges: z.union([ z.lazy(() => SessionUpdatechallengesInputSchema),z.string().array() ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SessionStatusSchema),z.lazy(() => EnumSessionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  time_limit: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  start: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  end: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.SessionUncheckedUpdateManyWithoutCompanyInput>;

export const TeamCreateManyGameserversInputSchema: z.ZodType<Prisma.TeamCreateManyGameserversInput> = z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  connected: z.boolean().optional(),
  currentChallenge: z.number().int().optional(),
  challengeStatus: z.lazy(() => ChallengeStatusSchema).optional(),
  licenceUsed: z.number().int().optional(),
  max_players: z.number().int().optional(),
  players: z.union([ z.lazy(() => TeamCreateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.lazy(() => TeamStatusSchema).optional(),
  token: z.string(),
  spectoken: z.string(),
  sessionsId: z.number().int(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict() as z.ZodType<Prisma.TeamCreateManyGameserversInput>;

export const TeamUpdateWithoutGameserversInputSchema: z.ZodType<Prisma.TeamUpdateWithoutGameserversInput> = z.object({
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  connected: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  currentChallenge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  challengeStatus: z.union([ z.lazy(() => ChallengeStatusSchema),z.lazy(() => EnumChallengeStatusFieldUpdateOperationsInputSchema) ]).optional(),
  licenceUsed: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  max_players: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  players: z.union([ z.lazy(() => TeamUpdateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.union([ z.lazy(() => TeamStatusSchema),z.lazy(() => EnumTeamStatusFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  spectoken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  events: z.lazy(() => EventUpdateManyWithoutTeamNestedInputSchema).optional(),
  positions: z.lazy(() => PositionUpdateManyWithoutTeamNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUpdateOneRequiredWithoutTeamsNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.TeamUpdateWithoutGameserversInput>;

export const TeamUncheckedUpdateWithoutGameserversInputSchema: z.ZodType<Prisma.TeamUncheckedUpdateWithoutGameserversInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  connected: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  currentChallenge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  challengeStatus: z.union([ z.lazy(() => ChallengeStatusSchema),z.lazy(() => EnumChallengeStatusFieldUpdateOperationsInputSchema) ]).optional(),
  licenceUsed: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  max_players: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  players: z.union([ z.lazy(() => TeamUpdateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.union([ z.lazy(() => TeamStatusSchema),z.lazy(() => EnumTeamStatusFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  spectoken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sessionsId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  events: z.lazy(() => EventUncheckedUpdateManyWithoutTeamNestedInputSchema).optional(),
  positions: z.lazy(() => PositionUncheckedUpdateManyWithoutTeamNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.TeamUncheckedUpdateWithoutGameserversInput>;

export const TeamUncheckedUpdateManyWithoutGameserversInputSchema: z.ZodType<Prisma.TeamUncheckedUpdateManyWithoutGameserversInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  connected: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  currentChallenge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  challengeStatus: z.union([ z.lazy(() => ChallengeStatusSchema),z.lazy(() => EnumChallengeStatusFieldUpdateOperationsInputSchema) ]).optional(),
  licenceUsed: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  max_players: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  players: z.union([ z.lazy(() => TeamUpdateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.union([ z.lazy(() => TeamStatusSchema),z.lazy(() => EnumTeamStatusFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  spectoken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sessionsId: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.TeamUncheckedUpdateManyWithoutGameserversInput>;

export const TeamCreateManySessionsInputSchema: z.ZodType<Prisma.TeamCreateManySessionsInput> = z.object({
  id: z.number().int().optional(),
  uuid: z.string().optional(),
  connected: z.boolean().optional(),
  currentChallenge: z.number().int().optional(),
  challengeStatus: z.lazy(() => ChallengeStatusSchema).optional(),
  licenceUsed: z.number().int().optional(),
  max_players: z.number().int().optional(),
  players: z.union([ z.lazy(() => TeamCreateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.lazy(() => TeamStatusSchema).optional(),
  token: z.string(),
  spectoken: z.string(),
  gameserversId: z.number().int().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict() as z.ZodType<Prisma.TeamCreateManySessionsInput>;

export const TeamUpdateWithoutSessionsInputSchema: z.ZodType<Prisma.TeamUpdateWithoutSessionsInput> = z.object({
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  connected: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  currentChallenge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  challengeStatus: z.union([ z.lazy(() => ChallengeStatusSchema),z.lazy(() => EnumChallengeStatusFieldUpdateOperationsInputSchema) ]).optional(),
  licenceUsed: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  max_players: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  players: z.union([ z.lazy(() => TeamUpdateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.union([ z.lazy(() => TeamStatusSchema),z.lazy(() => EnumTeamStatusFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  spectoken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  events: z.lazy(() => EventUpdateManyWithoutTeamNestedInputSchema).optional(),
  positions: z.lazy(() => PositionUpdateManyWithoutTeamNestedInputSchema).optional(),
  gameservers: z.lazy(() => GameserverUpdateOneWithoutTeamsNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.TeamUpdateWithoutSessionsInput>;

export const TeamUncheckedUpdateWithoutSessionsInputSchema: z.ZodType<Prisma.TeamUncheckedUpdateWithoutSessionsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  connected: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  currentChallenge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  challengeStatus: z.union([ z.lazy(() => ChallengeStatusSchema),z.lazy(() => EnumChallengeStatusFieldUpdateOperationsInputSchema) ]).optional(),
  licenceUsed: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  max_players: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  players: z.union([ z.lazy(() => TeamUpdateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.union([ z.lazy(() => TeamStatusSchema),z.lazy(() => EnumTeamStatusFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  spectoken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  gameserversId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  events: z.lazy(() => EventUncheckedUpdateManyWithoutTeamNestedInputSchema).optional(),
  positions: z.lazy(() => PositionUncheckedUpdateManyWithoutTeamNestedInputSchema).optional()
}).strict() as z.ZodType<Prisma.TeamUncheckedUpdateWithoutSessionsInput>;

export const TeamUncheckedUpdateManyWithoutSessionsInputSchema: z.ZodType<Prisma.TeamUncheckedUpdateManyWithoutSessionsInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  uuid: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  connected: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  currentChallenge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  challengeStatus: z.union([ z.lazy(() => ChallengeStatusSchema),z.lazy(() => EnumChallengeStatusFieldUpdateOperationsInputSchema) ]).optional(),
  licenceUsed: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  max_players: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  players: z.union([ z.lazy(() => TeamUpdateplayersInputSchema),z.object({ slot: z.number(), status: z.nativeEnum(PlayerStatus), socket: z.string().optional(), uuid: z.string().optional() }).array() ]).optional(),
  status: z.union([ z.lazy(() => TeamStatusSchema),z.lazy(() => EnumTeamStatusFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  spectoken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  gameserversId: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.TeamUncheckedUpdateManyWithoutSessionsInput>;

export const EventCreateManyTeamInputSchema: z.ZodType<Prisma.EventCreateManyTeamInput> = z.object({
  id: z.number().int().optional(),
  LogType: z.string(),
  Parameter: z.string(),
  Player: z.number().int(),
  TriggeredObject: z.string(),
  timestamp: z.coerce.date()
}).strict() as z.ZodType<Prisma.EventCreateManyTeamInput>;

export const PositionCreateManyTeamInputSchema: z.ZodType<Prisma.PositionCreateManyTeamInput> = z.object({
  id: z.number().int().optional(),
  locations: z.union([ z.lazy(() => JsonNullValueInputSchema),z.object({slot: z.number(), location: z.number().array().length(3)}).array() ]),
}).strict() as z.ZodType<Prisma.PositionCreateManyTeamInput>;

export const EventUpdateWithoutTeamInputSchema: z.ZodType<Prisma.EventUpdateWithoutTeamInput> = z.object({
  LogType: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  Parameter: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  Player: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  TriggeredObject: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.EventUpdateWithoutTeamInput>;

export const EventUncheckedUpdateWithoutTeamInputSchema: z.ZodType<Prisma.EventUncheckedUpdateWithoutTeamInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  LogType: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  Parameter: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  Player: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  TriggeredObject: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.EventUncheckedUpdateWithoutTeamInput>;

export const EventUncheckedUpdateManyWithoutTeamInputSchema: z.ZodType<Prisma.EventUncheckedUpdateManyWithoutTeamInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  LogType: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  Parameter: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  Player: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  TriggeredObject: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict() as z.ZodType<Prisma.EventUncheckedUpdateManyWithoutTeamInput>;

export const PositionUpdateWithoutTeamInputSchema: z.ZodType<Prisma.PositionUpdateWithoutTeamInput> = z.object({
  locations: z.union([ z.lazy(() => JsonNullValueInputSchema),z.object({slot: z.number(), location: z.number().array().length(3)}).array() ]).optional(),
}).strict() as z.ZodType<Prisma.PositionUpdateWithoutTeamInput>;

export const PositionUncheckedUpdateWithoutTeamInputSchema: z.ZodType<Prisma.PositionUncheckedUpdateWithoutTeamInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  locations: z.union([ z.lazy(() => JsonNullValueInputSchema),z.object({slot: z.number(), location: z.number().array().length(3)}).array() ]).optional(),
}).strict() as z.ZodType<Prisma.PositionUncheckedUpdateWithoutTeamInput>;

export const PositionUncheckedUpdateManyWithoutTeamInputSchema: z.ZodType<Prisma.PositionUncheckedUpdateManyWithoutTeamInput> = z.object({
  id: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  locations: z.union([ z.lazy(() => JsonNullValueInputSchema),z.object({slot: z.number(), location: z.number().array().length(3)}).array() ]).optional(),
}).strict() as z.ZodType<Prisma.PositionUncheckedUpdateManyWithoutTeamInput>;

/////////////////////////////////////////
// ARGS
/////////////////////////////////////////

export const PositionFindFirstArgsSchema: z.ZodType<Prisma.PositionFindFirstArgs> = z.object({
  select: PositionSelectSchema.optional(),
  include: PositionIncludeSchema.optional(),
  where: PositionWhereInputSchema.optional(),
  orderBy: z.union([ PositionOrderByWithRelationInputSchema.array(),PositionOrderByWithRelationInputSchema ]).optional(),
  cursor: PositionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PositionScalarFieldEnumSchema,PositionScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.PositionFindFirstArgs>;

export const PositionFindFirstOrThrowArgsSchema: z.ZodType<Prisma.PositionFindFirstOrThrowArgs> = z.object({
  select: PositionSelectSchema.optional(),
  include: PositionIncludeSchema.optional(),
  where: PositionWhereInputSchema.optional(),
  orderBy: z.union([ PositionOrderByWithRelationInputSchema.array(),PositionOrderByWithRelationInputSchema ]).optional(),
  cursor: PositionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PositionScalarFieldEnumSchema,PositionScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.PositionFindFirstOrThrowArgs>;

export const PositionFindManyArgsSchema: z.ZodType<Prisma.PositionFindManyArgs> = z.object({
  select: PositionSelectSchema.optional(),
  include: PositionIncludeSchema.optional(),
  where: PositionWhereInputSchema.optional(),
  orderBy: z.union([ PositionOrderByWithRelationInputSchema.array(),PositionOrderByWithRelationInputSchema ]).optional(),
  cursor: PositionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PositionScalarFieldEnumSchema,PositionScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.PositionFindManyArgs>;

export const PositionAggregateArgsSchema: z.ZodType<Prisma.PositionAggregateArgs> = z.object({
  where: PositionWhereInputSchema.optional(),
  orderBy: z.union([ PositionOrderByWithRelationInputSchema.array(),PositionOrderByWithRelationInputSchema ]).optional(),
  cursor: PositionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() as z.ZodType<Prisma.PositionAggregateArgs>;

export const PositionGroupByArgsSchema: z.ZodType<Prisma.PositionGroupByArgs> = z.object({
  where: PositionWhereInputSchema.optional(),
  orderBy: z.union([ PositionOrderByWithAggregationInputSchema.array(),PositionOrderByWithAggregationInputSchema ]).optional(),
  by: PositionScalarFieldEnumSchema.array(),
  having: PositionScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() as z.ZodType<Prisma.PositionGroupByArgs>;

export const PositionFindUniqueArgsSchema: z.ZodType<Prisma.PositionFindUniqueArgs> = z.object({
  select: PositionSelectSchema.optional(),
  include: PositionIncludeSchema.optional(),
  where: PositionWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.PositionFindUniqueArgs>;

export const PositionFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.PositionFindUniqueOrThrowArgs> = z.object({
  select: PositionSelectSchema.optional(),
  include: PositionIncludeSchema.optional(),
  where: PositionWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.PositionFindUniqueOrThrowArgs>;

export const EventFindFirstArgsSchema: z.ZodType<Prisma.EventFindFirstArgs> = z.object({
  select: EventSelectSchema.optional(),
  include: EventIncludeSchema.optional(),
  where: EventWhereInputSchema.optional(),
  orderBy: z.union([ EventOrderByWithRelationInputSchema.array(),EventOrderByWithRelationInputSchema ]).optional(),
  cursor: EventWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ EventScalarFieldEnumSchema,EventScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.EventFindFirstArgs>;

export const EventFindFirstOrThrowArgsSchema: z.ZodType<Prisma.EventFindFirstOrThrowArgs> = z.object({
  select: EventSelectSchema.optional(),
  include: EventIncludeSchema.optional(),
  where: EventWhereInputSchema.optional(),
  orderBy: z.union([ EventOrderByWithRelationInputSchema.array(),EventOrderByWithRelationInputSchema ]).optional(),
  cursor: EventWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ EventScalarFieldEnumSchema,EventScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.EventFindFirstOrThrowArgs>;

export const EventFindManyArgsSchema: z.ZodType<Prisma.EventFindManyArgs> = z.object({
  select: EventSelectSchema.optional(),
  include: EventIncludeSchema.optional(),
  where: EventWhereInputSchema.optional(),
  orderBy: z.union([ EventOrderByWithRelationInputSchema.array(),EventOrderByWithRelationInputSchema ]).optional(),
  cursor: EventWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ EventScalarFieldEnumSchema,EventScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.EventFindManyArgs>;

export const EventAggregateArgsSchema: z.ZodType<Prisma.EventAggregateArgs> = z.object({
  where: EventWhereInputSchema.optional(),
  orderBy: z.union([ EventOrderByWithRelationInputSchema.array(),EventOrderByWithRelationInputSchema ]).optional(),
  cursor: EventWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() as z.ZodType<Prisma.EventAggregateArgs>;

export const EventGroupByArgsSchema: z.ZodType<Prisma.EventGroupByArgs> = z.object({
  where: EventWhereInputSchema.optional(),
  orderBy: z.union([ EventOrderByWithAggregationInputSchema.array(),EventOrderByWithAggregationInputSchema ]).optional(),
  by: EventScalarFieldEnumSchema.array(),
  having: EventScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() as z.ZodType<Prisma.EventGroupByArgs>;

export const EventFindUniqueArgsSchema: z.ZodType<Prisma.EventFindUniqueArgs> = z.object({
  select: EventSelectSchema.optional(),
  include: EventIncludeSchema.optional(),
  where: EventWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.EventFindUniqueArgs>;

export const EventFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.EventFindUniqueOrThrowArgs> = z.object({
  select: EventSelectSchema.optional(),
  include: EventIncludeSchema.optional(),
  where: EventWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.EventFindUniqueOrThrowArgs>;

export const ChallengeFindFirstArgsSchema: z.ZodType<Prisma.ChallengeFindFirstArgs> = z.object({
  select: ChallengeSelectSchema.optional(),
  where: ChallengeWhereInputSchema.optional(),
  orderBy: z.union([ ChallengeOrderByWithRelationInputSchema.array(),ChallengeOrderByWithRelationInputSchema ]).optional(),
  cursor: ChallengeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ ChallengeScalarFieldEnumSchema,ChallengeScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.ChallengeFindFirstArgs>;

export const ChallengeFindFirstOrThrowArgsSchema: z.ZodType<Prisma.ChallengeFindFirstOrThrowArgs> = z.object({
  select: ChallengeSelectSchema.optional(),
  where: ChallengeWhereInputSchema.optional(),
  orderBy: z.union([ ChallengeOrderByWithRelationInputSchema.array(),ChallengeOrderByWithRelationInputSchema ]).optional(),
  cursor: ChallengeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ ChallengeScalarFieldEnumSchema,ChallengeScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.ChallengeFindFirstOrThrowArgs>;

export const ChallengeFindManyArgsSchema: z.ZodType<Prisma.ChallengeFindManyArgs> = z.object({
  select: ChallengeSelectSchema.optional(),
  where: ChallengeWhereInputSchema.optional(),
  orderBy: z.union([ ChallengeOrderByWithRelationInputSchema.array(),ChallengeOrderByWithRelationInputSchema ]).optional(),
  cursor: ChallengeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ ChallengeScalarFieldEnumSchema,ChallengeScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.ChallengeFindManyArgs>;

export const ChallengeAggregateArgsSchema: z.ZodType<Prisma.ChallengeAggregateArgs> = z.object({
  where: ChallengeWhereInputSchema.optional(),
  orderBy: z.union([ ChallengeOrderByWithRelationInputSchema.array(),ChallengeOrderByWithRelationInputSchema ]).optional(),
  cursor: ChallengeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() as z.ZodType<Prisma.ChallengeAggregateArgs>;

export const ChallengeGroupByArgsSchema: z.ZodType<Prisma.ChallengeGroupByArgs> = z.object({
  where: ChallengeWhereInputSchema.optional(),
  orderBy: z.union([ ChallengeOrderByWithAggregationInputSchema.array(),ChallengeOrderByWithAggregationInputSchema ]).optional(),
  by: ChallengeScalarFieldEnumSchema.array(),
  having: ChallengeScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() as z.ZodType<Prisma.ChallengeGroupByArgs>;

export const ChallengeFindUniqueArgsSchema: z.ZodType<Prisma.ChallengeFindUniqueArgs> = z.object({
  select: ChallengeSelectSchema.optional(),
  where: ChallengeWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.ChallengeFindUniqueArgs>;

export const ChallengeFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.ChallengeFindUniqueOrThrowArgs> = z.object({
  select: ChallengeSelectSchema.optional(),
  where: ChallengeWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.ChallengeFindUniqueOrThrowArgs>;

export const CompanyFindFirstArgsSchema: z.ZodType<Prisma.CompanyFindFirstArgs> = z.object({
  select: CompanySelectSchema.optional(),
  include: CompanyIncludeSchema.optional(),
  where: CompanyWhereInputSchema.optional(),
  orderBy: z.union([ CompanyOrderByWithRelationInputSchema.array(),CompanyOrderByWithRelationInputSchema ]).optional(),
  cursor: CompanyWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CompanyScalarFieldEnumSchema,CompanyScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.CompanyFindFirstArgs>;

export const CompanyFindFirstOrThrowArgsSchema: z.ZodType<Prisma.CompanyFindFirstOrThrowArgs> = z.object({
  select: CompanySelectSchema.optional(),
  include: CompanyIncludeSchema.optional(),
  where: CompanyWhereInputSchema.optional(),
  orderBy: z.union([ CompanyOrderByWithRelationInputSchema.array(),CompanyOrderByWithRelationInputSchema ]).optional(),
  cursor: CompanyWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CompanyScalarFieldEnumSchema,CompanyScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.CompanyFindFirstOrThrowArgs>;

export const CompanyFindManyArgsSchema: z.ZodType<Prisma.CompanyFindManyArgs> = z.object({
  select: CompanySelectSchema.optional(),
  include: CompanyIncludeSchema.optional(),
  where: CompanyWhereInputSchema.optional(),
  orderBy: z.union([ CompanyOrderByWithRelationInputSchema.array(),CompanyOrderByWithRelationInputSchema ]).optional(),
  cursor: CompanyWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CompanyScalarFieldEnumSchema,CompanyScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.CompanyFindManyArgs>;

export const CompanyAggregateArgsSchema: z.ZodType<Prisma.CompanyAggregateArgs> = z.object({
  where: CompanyWhereInputSchema.optional(),
  orderBy: z.union([ CompanyOrderByWithRelationInputSchema.array(),CompanyOrderByWithRelationInputSchema ]).optional(),
  cursor: CompanyWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() as z.ZodType<Prisma.CompanyAggregateArgs>;

export const CompanyGroupByArgsSchema: z.ZodType<Prisma.CompanyGroupByArgs> = z.object({
  where: CompanyWhereInputSchema.optional(),
  orderBy: z.union([ CompanyOrderByWithAggregationInputSchema.array(),CompanyOrderByWithAggregationInputSchema ]).optional(),
  by: CompanyScalarFieldEnumSchema.array(),
  having: CompanyScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() as z.ZodType<Prisma.CompanyGroupByArgs>;

export const CompanyFindUniqueArgsSchema: z.ZodType<Prisma.CompanyFindUniqueArgs> = z.object({
  select: CompanySelectSchema.optional(),
  include: CompanyIncludeSchema.optional(),
  where: CompanyWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.CompanyFindUniqueArgs>;

export const CompanyFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.CompanyFindUniqueOrThrowArgs> = z.object({
  select: CompanySelectSchema.optional(),
  include: CompanyIncludeSchema.optional(),
  where: CompanyWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.CompanyFindUniqueOrThrowArgs>;

export const GameserverFindFirstArgsSchema: z.ZodType<Prisma.GameserverFindFirstArgs> = z.object({
  select: GameserverSelectSchema.optional(),
  include: GameserverIncludeSchema.optional(),
  where: GameserverWhereInputSchema.optional(),
  orderBy: z.union([ GameserverOrderByWithRelationInputSchema.array(),GameserverOrderByWithRelationInputSchema ]).optional(),
  cursor: GameserverWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ GameserverScalarFieldEnumSchema,GameserverScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.GameserverFindFirstArgs>;

export const GameserverFindFirstOrThrowArgsSchema: z.ZodType<Prisma.GameserverFindFirstOrThrowArgs> = z.object({
  select: GameserverSelectSchema.optional(),
  include: GameserverIncludeSchema.optional(),
  where: GameserverWhereInputSchema.optional(),
  orderBy: z.union([ GameserverOrderByWithRelationInputSchema.array(),GameserverOrderByWithRelationInputSchema ]).optional(),
  cursor: GameserverWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ GameserverScalarFieldEnumSchema,GameserverScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.GameserverFindFirstOrThrowArgs>;

export const GameserverFindManyArgsSchema: z.ZodType<Prisma.GameserverFindManyArgs> = z.object({
  select: GameserverSelectSchema.optional(),
  include: GameserverIncludeSchema.optional(),
  where: GameserverWhereInputSchema.optional(),
  orderBy: z.union([ GameserverOrderByWithRelationInputSchema.array(),GameserverOrderByWithRelationInputSchema ]).optional(),
  cursor: GameserverWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ GameserverScalarFieldEnumSchema,GameserverScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.GameserverFindManyArgs>;

export const GameserverAggregateArgsSchema: z.ZodType<Prisma.GameserverAggregateArgs> = z.object({
  where: GameserverWhereInputSchema.optional(),
  orderBy: z.union([ GameserverOrderByWithRelationInputSchema.array(),GameserverOrderByWithRelationInputSchema ]).optional(),
  cursor: GameserverWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() as z.ZodType<Prisma.GameserverAggregateArgs>;

export const GameserverGroupByArgsSchema: z.ZodType<Prisma.GameserverGroupByArgs> = z.object({
  where: GameserverWhereInputSchema.optional(),
  orderBy: z.union([ GameserverOrderByWithAggregationInputSchema.array(),GameserverOrderByWithAggregationInputSchema ]).optional(),
  by: GameserverScalarFieldEnumSchema.array(),
  having: GameserverScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() as z.ZodType<Prisma.GameserverGroupByArgs>;

export const GameserverFindUniqueArgsSchema: z.ZodType<Prisma.GameserverFindUniqueArgs> = z.object({
  select: GameserverSelectSchema.optional(),
  include: GameserverIncludeSchema.optional(),
  where: GameserverWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.GameserverFindUniqueArgs>;

export const GameserverFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.GameserverFindUniqueOrThrowArgs> = z.object({
  select: GameserverSelectSchema.optional(),
  include: GameserverIncludeSchema.optional(),
  where: GameserverWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.GameserverFindUniqueOrThrowArgs>;

export const PasswordresetsFindFirstArgsSchema: z.ZodType<Prisma.PasswordresetsFindFirstArgs> = z.object({
  select: PasswordresetsSelectSchema.optional(),
  where: PasswordresetsWhereInputSchema.optional(),
  orderBy: z.union([ PasswordresetsOrderByWithRelationInputSchema.array(),PasswordresetsOrderByWithRelationInputSchema ]).optional(),
  cursor: PasswordresetsWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PasswordresetsScalarFieldEnumSchema,PasswordresetsScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.PasswordresetsFindFirstArgs>;

export const PasswordresetsFindFirstOrThrowArgsSchema: z.ZodType<Prisma.PasswordresetsFindFirstOrThrowArgs> = z.object({
  select: PasswordresetsSelectSchema.optional(),
  where: PasswordresetsWhereInputSchema.optional(),
  orderBy: z.union([ PasswordresetsOrderByWithRelationInputSchema.array(),PasswordresetsOrderByWithRelationInputSchema ]).optional(),
  cursor: PasswordresetsWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PasswordresetsScalarFieldEnumSchema,PasswordresetsScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.PasswordresetsFindFirstOrThrowArgs>;

export const PasswordresetsFindManyArgsSchema: z.ZodType<Prisma.PasswordresetsFindManyArgs> = z.object({
  select: PasswordresetsSelectSchema.optional(),
  where: PasswordresetsWhereInputSchema.optional(),
  orderBy: z.union([ PasswordresetsOrderByWithRelationInputSchema.array(),PasswordresetsOrderByWithRelationInputSchema ]).optional(),
  cursor: PasswordresetsWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PasswordresetsScalarFieldEnumSchema,PasswordresetsScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.PasswordresetsFindManyArgs>;

export const PasswordresetsAggregateArgsSchema: z.ZodType<Prisma.PasswordresetsAggregateArgs> = z.object({
  where: PasswordresetsWhereInputSchema.optional(),
  orderBy: z.union([ PasswordresetsOrderByWithRelationInputSchema.array(),PasswordresetsOrderByWithRelationInputSchema ]).optional(),
  cursor: PasswordresetsWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() as z.ZodType<Prisma.PasswordresetsAggregateArgs>;

export const PasswordresetsGroupByArgsSchema: z.ZodType<Prisma.PasswordresetsGroupByArgs> = z.object({
  where: PasswordresetsWhereInputSchema.optional(),
  orderBy: z.union([ PasswordresetsOrderByWithAggregationInputSchema.array(),PasswordresetsOrderByWithAggregationInputSchema ]).optional(),
  by: PasswordresetsScalarFieldEnumSchema.array(),
  having: PasswordresetsScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() as z.ZodType<Prisma.PasswordresetsGroupByArgs>;

export const PasswordresetsFindUniqueArgsSchema: z.ZodType<Prisma.PasswordresetsFindUniqueArgs> = z.object({
  select: PasswordresetsSelectSchema.optional(),
  where: PasswordresetsWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.PasswordresetsFindUniqueArgs>;

export const PasswordresetsFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.PasswordresetsFindUniqueOrThrowArgs> = z.object({
  select: PasswordresetsSelectSchema.optional(),
  where: PasswordresetsWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.PasswordresetsFindUniqueOrThrowArgs>;

export const SessionFindFirstArgsSchema: z.ZodType<Prisma.SessionFindFirstArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereInputSchema.optional(),
  orderBy: z.union([ SessionOrderByWithRelationInputSchema.array(),SessionOrderByWithRelationInputSchema ]).optional(),
  cursor: SessionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SessionScalarFieldEnumSchema,SessionScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.SessionFindFirstArgs>;

export const SessionFindFirstOrThrowArgsSchema: z.ZodType<Prisma.SessionFindFirstOrThrowArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereInputSchema.optional(),
  orderBy: z.union([ SessionOrderByWithRelationInputSchema.array(),SessionOrderByWithRelationInputSchema ]).optional(),
  cursor: SessionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SessionScalarFieldEnumSchema,SessionScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.SessionFindFirstOrThrowArgs>;

export const SessionFindManyArgsSchema: z.ZodType<Prisma.SessionFindManyArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereInputSchema.optional(),
  orderBy: z.union([ SessionOrderByWithRelationInputSchema.array(),SessionOrderByWithRelationInputSchema ]).optional(),
  cursor: SessionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SessionScalarFieldEnumSchema,SessionScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.SessionFindManyArgs>;

export const SessionAggregateArgsSchema: z.ZodType<Prisma.SessionAggregateArgs> = z.object({
  where: SessionWhereInputSchema.optional(),
  orderBy: z.union([ SessionOrderByWithRelationInputSchema.array(),SessionOrderByWithRelationInputSchema ]).optional(),
  cursor: SessionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() as z.ZodType<Prisma.SessionAggregateArgs>;

export const SessionGroupByArgsSchema: z.ZodType<Prisma.SessionGroupByArgs> = z.object({
  where: SessionWhereInputSchema.optional(),
  orderBy: z.union([ SessionOrderByWithAggregationInputSchema.array(),SessionOrderByWithAggregationInputSchema ]).optional(),
  by: SessionScalarFieldEnumSchema.array(),
  having: SessionScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() as z.ZodType<Prisma.SessionGroupByArgs>;

export const SessionFindUniqueArgsSchema: z.ZodType<Prisma.SessionFindUniqueArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.SessionFindUniqueArgs>;

export const SessionFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.SessionFindUniqueOrThrowArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.SessionFindUniqueOrThrowArgs>;

export const TeamFindFirstArgsSchema: z.ZodType<Prisma.TeamFindFirstArgs> = z.object({
  select: TeamSelectSchema.optional(),
  include: TeamIncludeSchema.optional(),
  where: TeamWhereInputSchema.optional(),
  orderBy: z.union([ TeamOrderByWithRelationInputSchema.array(),TeamOrderByWithRelationInputSchema ]).optional(),
  cursor: TeamWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ TeamScalarFieldEnumSchema,TeamScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.TeamFindFirstArgs>;

export const TeamFindFirstOrThrowArgsSchema: z.ZodType<Prisma.TeamFindFirstOrThrowArgs> = z.object({
  select: TeamSelectSchema.optional(),
  include: TeamIncludeSchema.optional(),
  where: TeamWhereInputSchema.optional(),
  orderBy: z.union([ TeamOrderByWithRelationInputSchema.array(),TeamOrderByWithRelationInputSchema ]).optional(),
  cursor: TeamWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ TeamScalarFieldEnumSchema,TeamScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.TeamFindFirstOrThrowArgs>;

export const TeamFindManyArgsSchema: z.ZodType<Prisma.TeamFindManyArgs> = z.object({
  select: TeamSelectSchema.optional(),
  include: TeamIncludeSchema.optional(),
  where: TeamWhereInputSchema.optional(),
  orderBy: z.union([ TeamOrderByWithRelationInputSchema.array(),TeamOrderByWithRelationInputSchema ]).optional(),
  cursor: TeamWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ TeamScalarFieldEnumSchema,TeamScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.TeamFindManyArgs>;

export const TeamAggregateArgsSchema: z.ZodType<Prisma.TeamAggregateArgs> = z.object({
  where: TeamWhereInputSchema.optional(),
  orderBy: z.union([ TeamOrderByWithRelationInputSchema.array(),TeamOrderByWithRelationInputSchema ]).optional(),
  cursor: TeamWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() as z.ZodType<Prisma.TeamAggregateArgs>;

export const TeamGroupByArgsSchema: z.ZodType<Prisma.TeamGroupByArgs> = z.object({
  where: TeamWhereInputSchema.optional(),
  orderBy: z.union([ TeamOrderByWithAggregationInputSchema.array(),TeamOrderByWithAggregationInputSchema ]).optional(),
  by: TeamScalarFieldEnumSchema.array(),
  having: TeamScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() as z.ZodType<Prisma.TeamGroupByArgs>;

export const TeamFindUniqueArgsSchema: z.ZodType<Prisma.TeamFindUniqueArgs> = z.object({
  select: TeamSelectSchema.optional(),
  include: TeamIncludeSchema.optional(),
  where: TeamWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.TeamFindUniqueArgs>;

export const TeamFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.TeamFindUniqueOrThrowArgs> = z.object({
  select: TeamSelectSchema.optional(),
  include: TeamIncludeSchema.optional(),
  where: TeamWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.TeamFindUniqueOrThrowArgs>;

export const UserFindFirstArgsSchema: z.ZodType<Prisma.UserFindFirstArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithRelationInputSchema.array(),UserOrderByWithRelationInputSchema ]).optional(),
  cursor: UserWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ UserScalarFieldEnumSchema,UserScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.UserFindFirstArgs>;

export const UserFindFirstOrThrowArgsSchema: z.ZodType<Prisma.UserFindFirstOrThrowArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithRelationInputSchema.array(),UserOrderByWithRelationInputSchema ]).optional(),
  cursor: UserWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ UserScalarFieldEnumSchema,UserScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.UserFindFirstOrThrowArgs>;

export const UserFindManyArgsSchema: z.ZodType<Prisma.UserFindManyArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithRelationInputSchema.array(),UserOrderByWithRelationInputSchema ]).optional(),
  cursor: UserWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ UserScalarFieldEnumSchema,UserScalarFieldEnumSchema.array() ]).optional(),
}).strict() as z.ZodType<Prisma.UserFindManyArgs>;

export const UserAggregateArgsSchema: z.ZodType<Prisma.UserAggregateArgs> = z.object({
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithRelationInputSchema.array(),UserOrderByWithRelationInputSchema ]).optional(),
  cursor: UserWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() as z.ZodType<Prisma.UserAggregateArgs>;

export const UserGroupByArgsSchema: z.ZodType<Prisma.UserGroupByArgs> = z.object({
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithAggregationInputSchema.array(),UserOrderByWithAggregationInputSchema ]).optional(),
  by: UserScalarFieldEnumSchema.array(),
  having: UserScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() as z.ZodType<Prisma.UserGroupByArgs>;

export const UserFindUniqueArgsSchema: z.ZodType<Prisma.UserFindUniqueArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.UserFindUniqueArgs>;

export const UserFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.UserFindUniqueOrThrowArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.UserFindUniqueOrThrowArgs>;

export const PositionCreateArgsSchema: z.ZodType<Prisma.PositionCreateArgs> = z.object({
  select: PositionSelectSchema.optional(),
  include: PositionIncludeSchema.optional(),
  data: z.union([ PositionCreateInputSchema,PositionUncheckedCreateInputSchema ]),
}).strict() as z.ZodType<Prisma.PositionCreateArgs>;

export const PositionUpsertArgsSchema: z.ZodType<Prisma.PositionUpsertArgs> = z.object({
  select: PositionSelectSchema.optional(),
  include: PositionIncludeSchema.optional(),
  where: PositionWhereUniqueInputSchema,
  create: z.union([ PositionCreateInputSchema,PositionUncheckedCreateInputSchema ]),
  update: z.union([ PositionUpdateInputSchema,PositionUncheckedUpdateInputSchema ]),
}).strict() as z.ZodType<Prisma.PositionUpsertArgs>;

export const PositionCreateManyArgsSchema: z.ZodType<Prisma.PositionCreateManyArgs> = z.object({
  data: z.union([ PositionCreateManyInputSchema,PositionCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() as z.ZodType<Prisma.PositionCreateManyArgs>;

export const PositionCreateManyAndReturnArgsSchema: z.ZodType<Prisma.PositionCreateManyAndReturnArgs> = z.object({
  data: z.union([ PositionCreateManyInputSchema,PositionCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() as z.ZodType<Prisma.PositionCreateManyAndReturnArgs>;

export const PositionDeleteArgsSchema: z.ZodType<Prisma.PositionDeleteArgs> = z.object({
  select: PositionSelectSchema.optional(),
  include: PositionIncludeSchema.optional(),
  where: PositionWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.PositionDeleteArgs>;

export const PositionUpdateArgsSchema: z.ZodType<Prisma.PositionUpdateArgs> = z.object({
  select: PositionSelectSchema.optional(),
  include: PositionIncludeSchema.optional(),
  data: z.union([ PositionUpdateInputSchema,PositionUncheckedUpdateInputSchema ]),
  where: PositionWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.PositionUpdateArgs>;

export const PositionUpdateManyArgsSchema: z.ZodType<Prisma.PositionUpdateManyArgs> = z.object({
  data: z.union([ PositionUpdateManyMutationInputSchema,PositionUncheckedUpdateManyInputSchema ]),
  where: PositionWhereInputSchema.optional(),
}).strict() as z.ZodType<Prisma.PositionUpdateManyArgs>;

export const PositionDeleteManyArgsSchema: z.ZodType<Prisma.PositionDeleteManyArgs> = z.object({
  where: PositionWhereInputSchema.optional(),
}).strict() as z.ZodType<Prisma.PositionDeleteManyArgs>;

export const EventCreateArgsSchema: z.ZodType<Prisma.EventCreateArgs> = z.object({
  select: EventSelectSchema.optional(),
  include: EventIncludeSchema.optional(),
  data: z.union([ EventCreateInputSchema,EventUncheckedCreateInputSchema ]),
}).strict() as z.ZodType<Prisma.EventCreateArgs>;

export const EventUpsertArgsSchema: z.ZodType<Prisma.EventUpsertArgs> = z.object({
  select: EventSelectSchema.optional(),
  include: EventIncludeSchema.optional(),
  where: EventWhereUniqueInputSchema,
  create: z.union([ EventCreateInputSchema,EventUncheckedCreateInputSchema ]),
  update: z.union([ EventUpdateInputSchema,EventUncheckedUpdateInputSchema ]),
}).strict() as z.ZodType<Prisma.EventUpsertArgs>;

export const EventCreateManyArgsSchema: z.ZodType<Prisma.EventCreateManyArgs> = z.object({
  data: z.union([ EventCreateManyInputSchema,EventCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() as z.ZodType<Prisma.EventCreateManyArgs>;

export const EventCreateManyAndReturnArgsSchema: z.ZodType<Prisma.EventCreateManyAndReturnArgs> = z.object({
  data: z.union([ EventCreateManyInputSchema,EventCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() as z.ZodType<Prisma.EventCreateManyAndReturnArgs>;

export const EventDeleteArgsSchema: z.ZodType<Prisma.EventDeleteArgs> = z.object({
  select: EventSelectSchema.optional(),
  include: EventIncludeSchema.optional(),
  where: EventWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.EventDeleteArgs>;

export const EventUpdateArgsSchema: z.ZodType<Prisma.EventUpdateArgs> = z.object({
  select: EventSelectSchema.optional(),
  include: EventIncludeSchema.optional(),
  data: z.union([ EventUpdateInputSchema,EventUncheckedUpdateInputSchema ]),
  where: EventWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.EventUpdateArgs>;

export const EventUpdateManyArgsSchema: z.ZodType<Prisma.EventUpdateManyArgs> = z.object({
  data: z.union([ EventUpdateManyMutationInputSchema,EventUncheckedUpdateManyInputSchema ]),
  where: EventWhereInputSchema.optional(),
}).strict() as z.ZodType<Prisma.EventUpdateManyArgs>;

export const EventDeleteManyArgsSchema: z.ZodType<Prisma.EventDeleteManyArgs> = z.object({
  where: EventWhereInputSchema.optional(),
}).strict() as z.ZodType<Prisma.EventDeleteManyArgs>;

export const ChallengeCreateArgsSchema: z.ZodType<Prisma.ChallengeCreateArgs> = z.object({
  select: ChallengeSelectSchema.optional(),
  data: z.union([ ChallengeCreateInputSchema,ChallengeUncheckedCreateInputSchema ]),
}).strict() as z.ZodType<Prisma.ChallengeCreateArgs>;

export const ChallengeUpsertArgsSchema: z.ZodType<Prisma.ChallengeUpsertArgs> = z.object({
  select: ChallengeSelectSchema.optional(),
  where: ChallengeWhereUniqueInputSchema,
  create: z.union([ ChallengeCreateInputSchema,ChallengeUncheckedCreateInputSchema ]),
  update: z.union([ ChallengeUpdateInputSchema,ChallengeUncheckedUpdateInputSchema ]),
}).strict() as z.ZodType<Prisma.ChallengeUpsertArgs>;

export const ChallengeCreateManyArgsSchema: z.ZodType<Prisma.ChallengeCreateManyArgs> = z.object({
  data: z.union([ ChallengeCreateManyInputSchema,ChallengeCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() as z.ZodType<Prisma.ChallengeCreateManyArgs>;

export const ChallengeCreateManyAndReturnArgsSchema: z.ZodType<Prisma.ChallengeCreateManyAndReturnArgs> = z.object({
  data: z.union([ ChallengeCreateManyInputSchema,ChallengeCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() as z.ZodType<Prisma.ChallengeCreateManyAndReturnArgs>;

export const ChallengeDeleteArgsSchema: z.ZodType<Prisma.ChallengeDeleteArgs> = z.object({
  select: ChallengeSelectSchema.optional(),
  where: ChallengeWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.ChallengeDeleteArgs>;

export const ChallengeUpdateArgsSchema: z.ZodType<Prisma.ChallengeUpdateArgs> = z.object({
  select: ChallengeSelectSchema.optional(),
  data: z.union([ ChallengeUpdateInputSchema,ChallengeUncheckedUpdateInputSchema ]),
  where: ChallengeWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.ChallengeUpdateArgs>;

export const ChallengeUpdateManyArgsSchema: z.ZodType<Prisma.ChallengeUpdateManyArgs> = z.object({
  data: z.union([ ChallengeUpdateManyMutationInputSchema,ChallengeUncheckedUpdateManyInputSchema ]),
  where: ChallengeWhereInputSchema.optional(),
}).strict() as z.ZodType<Prisma.ChallengeUpdateManyArgs>;

export const ChallengeDeleteManyArgsSchema: z.ZodType<Prisma.ChallengeDeleteManyArgs> = z.object({
  where: ChallengeWhereInputSchema.optional(),
}).strict() as z.ZodType<Prisma.ChallengeDeleteManyArgs>;

export const CompanyCreateArgsSchema: z.ZodType<Prisma.CompanyCreateArgs> = z.object({
  select: CompanySelectSchema.optional(),
  include: CompanyIncludeSchema.optional(),
  data: z.union([ CompanyCreateInputSchema,CompanyUncheckedCreateInputSchema ]),
}).strict() as z.ZodType<Prisma.CompanyCreateArgs>;

export const CompanyUpsertArgsSchema: z.ZodType<Prisma.CompanyUpsertArgs> = z.object({
  select: CompanySelectSchema.optional(),
  include: CompanyIncludeSchema.optional(),
  where: CompanyWhereUniqueInputSchema,
  create: z.union([ CompanyCreateInputSchema,CompanyUncheckedCreateInputSchema ]),
  update: z.union([ CompanyUpdateInputSchema,CompanyUncheckedUpdateInputSchema ]),
}).strict() as z.ZodType<Prisma.CompanyUpsertArgs>;

export const CompanyCreateManyArgsSchema: z.ZodType<Prisma.CompanyCreateManyArgs> = z.object({
  data: z.union([ CompanyCreateManyInputSchema,CompanyCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() as z.ZodType<Prisma.CompanyCreateManyArgs>;

export const CompanyCreateManyAndReturnArgsSchema: z.ZodType<Prisma.CompanyCreateManyAndReturnArgs> = z.object({
  data: z.union([ CompanyCreateManyInputSchema,CompanyCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() as z.ZodType<Prisma.CompanyCreateManyAndReturnArgs>;

export const CompanyDeleteArgsSchema: z.ZodType<Prisma.CompanyDeleteArgs> = z.object({
  select: CompanySelectSchema.optional(),
  include: CompanyIncludeSchema.optional(),
  where: CompanyWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.CompanyDeleteArgs>;

export const CompanyUpdateArgsSchema: z.ZodType<Prisma.CompanyUpdateArgs> = z.object({
  select: CompanySelectSchema.optional(),
  include: CompanyIncludeSchema.optional(),
  data: z.union([ CompanyUpdateInputSchema,CompanyUncheckedUpdateInputSchema ]),
  where: CompanyWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.CompanyUpdateArgs>;

export const CompanyUpdateManyArgsSchema: z.ZodType<Prisma.CompanyUpdateManyArgs> = z.object({
  data: z.union([ CompanyUpdateManyMutationInputSchema,CompanyUncheckedUpdateManyInputSchema ]),
  where: CompanyWhereInputSchema.optional(),
}).strict() as z.ZodType<Prisma.CompanyUpdateManyArgs>;

export const CompanyDeleteManyArgsSchema: z.ZodType<Prisma.CompanyDeleteManyArgs> = z.object({
  where: CompanyWhereInputSchema.optional(),
}).strict() as z.ZodType<Prisma.CompanyDeleteManyArgs>;

export const GameserverCreateArgsSchema: z.ZodType<Prisma.GameserverCreateArgs> = z.object({
  select: GameserverSelectSchema.optional(),
  include: GameserverIncludeSchema.optional(),
  data: z.union([ GameserverCreateInputSchema,GameserverUncheckedCreateInputSchema ]),
}).strict() as z.ZodType<Prisma.GameserverCreateArgs>;

export const GameserverUpsertArgsSchema: z.ZodType<Prisma.GameserverUpsertArgs> = z.object({
  select: GameserverSelectSchema.optional(),
  include: GameserverIncludeSchema.optional(),
  where: GameserverWhereUniqueInputSchema,
  create: z.union([ GameserverCreateInputSchema,GameserverUncheckedCreateInputSchema ]),
  update: z.union([ GameserverUpdateInputSchema,GameserverUncheckedUpdateInputSchema ]),
}).strict() as z.ZodType<Prisma.GameserverUpsertArgs>;

export const GameserverCreateManyArgsSchema: z.ZodType<Prisma.GameserverCreateManyArgs> = z.object({
  data: z.union([ GameserverCreateManyInputSchema,GameserverCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() as z.ZodType<Prisma.GameserverCreateManyArgs>;

export const GameserverCreateManyAndReturnArgsSchema: z.ZodType<Prisma.GameserverCreateManyAndReturnArgs> = z.object({
  data: z.union([ GameserverCreateManyInputSchema,GameserverCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() as z.ZodType<Prisma.GameserverCreateManyAndReturnArgs>;

export const GameserverDeleteArgsSchema: z.ZodType<Prisma.GameserverDeleteArgs> = z.object({
  select: GameserverSelectSchema.optional(),
  include: GameserverIncludeSchema.optional(),
  where: GameserverWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.GameserverDeleteArgs>;

export const GameserverUpdateArgsSchema: z.ZodType<Prisma.GameserverUpdateArgs> = z.object({
  select: GameserverSelectSchema.optional(),
  include: GameserverIncludeSchema.optional(),
  data: z.union([ GameserverUpdateInputSchema,GameserverUncheckedUpdateInputSchema ]),
  where: GameserverWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.GameserverUpdateArgs>;

export const GameserverUpdateManyArgsSchema: z.ZodType<Prisma.GameserverUpdateManyArgs> = z.object({
  data: z.union([ GameserverUpdateManyMutationInputSchema,GameserverUncheckedUpdateManyInputSchema ]),
  where: GameserverWhereInputSchema.optional(),
}).strict() as z.ZodType<Prisma.GameserverUpdateManyArgs>;

export const GameserverDeleteManyArgsSchema: z.ZodType<Prisma.GameserverDeleteManyArgs> = z.object({
  where: GameserverWhereInputSchema.optional(),
}).strict() as z.ZodType<Prisma.GameserverDeleteManyArgs>;

export const PasswordresetsCreateArgsSchema: z.ZodType<Prisma.PasswordresetsCreateArgs> = z.object({
  select: PasswordresetsSelectSchema.optional(),
  data: z.union([ PasswordresetsCreateInputSchema,PasswordresetsUncheckedCreateInputSchema ]),
}).strict() as z.ZodType<Prisma.PasswordresetsCreateArgs>;

export const PasswordresetsUpsertArgsSchema: z.ZodType<Prisma.PasswordresetsUpsertArgs> = z.object({
  select: PasswordresetsSelectSchema.optional(),
  where: PasswordresetsWhereUniqueInputSchema,
  create: z.union([ PasswordresetsCreateInputSchema,PasswordresetsUncheckedCreateInputSchema ]),
  update: z.union([ PasswordresetsUpdateInputSchema,PasswordresetsUncheckedUpdateInputSchema ]),
}).strict() as z.ZodType<Prisma.PasswordresetsUpsertArgs>;

export const PasswordresetsCreateManyArgsSchema: z.ZodType<Prisma.PasswordresetsCreateManyArgs> = z.object({
  data: z.union([ PasswordresetsCreateManyInputSchema,PasswordresetsCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() as z.ZodType<Prisma.PasswordresetsCreateManyArgs>;

export const PasswordresetsCreateManyAndReturnArgsSchema: z.ZodType<Prisma.PasswordresetsCreateManyAndReturnArgs> = z.object({
  data: z.union([ PasswordresetsCreateManyInputSchema,PasswordresetsCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() as z.ZodType<Prisma.PasswordresetsCreateManyAndReturnArgs>;

export const PasswordresetsDeleteArgsSchema: z.ZodType<Prisma.PasswordresetsDeleteArgs> = z.object({
  select: PasswordresetsSelectSchema.optional(),
  where: PasswordresetsWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.PasswordresetsDeleteArgs>;

export const PasswordresetsUpdateArgsSchema: z.ZodType<Prisma.PasswordresetsUpdateArgs> = z.object({
  select: PasswordresetsSelectSchema.optional(),
  data: z.union([ PasswordresetsUpdateInputSchema,PasswordresetsUncheckedUpdateInputSchema ]),
  where: PasswordresetsWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.PasswordresetsUpdateArgs>;

export const PasswordresetsUpdateManyArgsSchema: z.ZodType<Prisma.PasswordresetsUpdateManyArgs> = z.object({
  data: z.union([ PasswordresetsUpdateManyMutationInputSchema,PasswordresetsUncheckedUpdateManyInputSchema ]),
  where: PasswordresetsWhereInputSchema.optional(),
}).strict() as z.ZodType<Prisma.PasswordresetsUpdateManyArgs>;

export const PasswordresetsDeleteManyArgsSchema: z.ZodType<Prisma.PasswordresetsDeleteManyArgs> = z.object({
  where: PasswordresetsWhereInputSchema.optional(),
}).strict() as z.ZodType<Prisma.PasswordresetsDeleteManyArgs>;

export const SessionCreateArgsSchema: z.ZodType<Prisma.SessionCreateArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  data: z.union([ SessionCreateInputSchema,SessionUncheckedCreateInputSchema ]),
}).strict() as z.ZodType<Prisma.SessionCreateArgs>;

export const SessionUpsertArgsSchema: z.ZodType<Prisma.SessionUpsertArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereUniqueInputSchema,
  create: z.union([ SessionCreateInputSchema,SessionUncheckedCreateInputSchema ]),
  update: z.union([ SessionUpdateInputSchema,SessionUncheckedUpdateInputSchema ]),
}).strict() as z.ZodType<Prisma.SessionUpsertArgs>;

export const SessionCreateManyArgsSchema: z.ZodType<Prisma.SessionCreateManyArgs> = z.object({
  data: z.union([ SessionCreateManyInputSchema,SessionCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() as z.ZodType<Prisma.SessionCreateManyArgs>;

export const SessionCreateManyAndReturnArgsSchema: z.ZodType<Prisma.SessionCreateManyAndReturnArgs> = z.object({
  data: z.union([ SessionCreateManyInputSchema,SessionCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() as z.ZodType<Prisma.SessionCreateManyAndReturnArgs>;

export const SessionDeleteArgsSchema: z.ZodType<Prisma.SessionDeleteArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.SessionDeleteArgs>;

export const SessionUpdateArgsSchema: z.ZodType<Prisma.SessionUpdateArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  data: z.union([ SessionUpdateInputSchema,SessionUncheckedUpdateInputSchema ]),
  where: SessionWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.SessionUpdateArgs>;

export const SessionUpdateManyArgsSchema: z.ZodType<Prisma.SessionUpdateManyArgs> = z.object({
  data: z.union([ SessionUpdateManyMutationInputSchema,SessionUncheckedUpdateManyInputSchema ]),
  where: SessionWhereInputSchema.optional(),
}).strict() as z.ZodType<Prisma.SessionUpdateManyArgs>;

export const SessionDeleteManyArgsSchema: z.ZodType<Prisma.SessionDeleteManyArgs> = z.object({
  where: SessionWhereInputSchema.optional(),
}).strict() as z.ZodType<Prisma.SessionDeleteManyArgs>;

export const TeamCreateArgsSchema: z.ZodType<Prisma.TeamCreateArgs> = z.object({
  select: TeamSelectSchema.optional(),
  include: TeamIncludeSchema.optional(),
  data: z.union([ TeamCreateInputSchema,TeamUncheckedCreateInputSchema ]),
}).strict() as z.ZodType<Prisma.TeamCreateArgs>;

export const TeamUpsertArgsSchema: z.ZodType<Prisma.TeamUpsertArgs> = z.object({
  select: TeamSelectSchema.optional(),
  include: TeamIncludeSchema.optional(),
  where: TeamWhereUniqueInputSchema,
  create: z.union([ TeamCreateInputSchema,TeamUncheckedCreateInputSchema ]),
  update: z.union([ TeamUpdateInputSchema,TeamUncheckedUpdateInputSchema ]),
}).strict() as z.ZodType<Prisma.TeamUpsertArgs>;

export const TeamCreateManyArgsSchema: z.ZodType<Prisma.TeamCreateManyArgs> = z.object({
  data: z.union([ TeamCreateManyInputSchema,TeamCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() as z.ZodType<Prisma.TeamCreateManyArgs>;

export const TeamCreateManyAndReturnArgsSchema: z.ZodType<Prisma.TeamCreateManyAndReturnArgs> = z.object({
  data: z.union([ TeamCreateManyInputSchema,TeamCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() as z.ZodType<Prisma.TeamCreateManyAndReturnArgs>;

export const TeamDeleteArgsSchema: z.ZodType<Prisma.TeamDeleteArgs> = z.object({
  select: TeamSelectSchema.optional(),
  include: TeamIncludeSchema.optional(),
  where: TeamWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.TeamDeleteArgs>;

export const TeamUpdateArgsSchema: z.ZodType<Prisma.TeamUpdateArgs> = z.object({
  select: TeamSelectSchema.optional(),
  include: TeamIncludeSchema.optional(),
  data: z.union([ TeamUpdateInputSchema,TeamUncheckedUpdateInputSchema ]),
  where: TeamWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.TeamUpdateArgs>;

export const TeamUpdateManyArgsSchema: z.ZodType<Prisma.TeamUpdateManyArgs> = z.object({
  data: z.union([ TeamUpdateManyMutationInputSchema,TeamUncheckedUpdateManyInputSchema ]),
  where: TeamWhereInputSchema.optional(),
}).strict() as z.ZodType<Prisma.TeamUpdateManyArgs>;

export const TeamDeleteManyArgsSchema: z.ZodType<Prisma.TeamDeleteManyArgs> = z.object({
  where: TeamWhereInputSchema.optional(),
}).strict() as z.ZodType<Prisma.TeamDeleteManyArgs>;

export const UserCreateArgsSchema: z.ZodType<Prisma.UserCreateArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  data: z.union([ UserCreateInputSchema,UserUncheckedCreateInputSchema ]),
}).strict() as z.ZodType<Prisma.UserCreateArgs>;

export const UserUpsertArgsSchema: z.ZodType<Prisma.UserUpsertArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereUniqueInputSchema,
  create: z.union([ UserCreateInputSchema,UserUncheckedCreateInputSchema ]),
  update: z.union([ UserUpdateInputSchema,UserUncheckedUpdateInputSchema ]),
}).strict() as z.ZodType<Prisma.UserUpsertArgs>;

export const UserCreateManyArgsSchema: z.ZodType<Prisma.UserCreateManyArgs> = z.object({
  data: z.union([ UserCreateManyInputSchema,UserCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() as z.ZodType<Prisma.UserCreateManyArgs>;

export const UserCreateManyAndReturnArgsSchema: z.ZodType<Prisma.UserCreateManyAndReturnArgs> = z.object({
  data: z.union([ UserCreateManyInputSchema,UserCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() as z.ZodType<Prisma.UserCreateManyAndReturnArgs>;

export const UserDeleteArgsSchema: z.ZodType<Prisma.UserDeleteArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.UserDeleteArgs>;

export const UserUpdateArgsSchema: z.ZodType<Prisma.UserUpdateArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  data: z.union([ UserUpdateInputSchema,UserUncheckedUpdateInputSchema ]),
  where: UserWhereUniqueInputSchema,
}).strict() as z.ZodType<Prisma.UserUpdateArgs>;

export const UserUpdateManyArgsSchema: z.ZodType<Prisma.UserUpdateManyArgs> = z.object({
  data: z.union([ UserUpdateManyMutationInputSchema,UserUncheckedUpdateManyInputSchema ]),
  where: UserWhereInputSchema.optional(),
}).strict() as z.ZodType<Prisma.UserUpdateManyArgs>;

export const UserDeleteManyArgsSchema: z.ZodType<Prisma.UserDeleteManyArgs> = z.object({
  where: UserWhereInputSchema.optional(),
}).strict() as z.ZodType<Prisma.UserDeleteManyArgs>;