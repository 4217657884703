import * as React from 'react'
import { useEffect } from 'react'
import * as SliderPrimitive from '@radix-ui/react-slider'
import { DateTime } from 'luxon'

import { cn } from '~/lib/utils'

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => {
  function getValues(idx: number) {
    const val = props.value ?? props.defaultValue ?? []

    return DateTime.now()
      .set({
        hour: val[idx],
        minute: 0,
        millisecond: 0,
      })
      .toFormat('HH:mm')
  }

  useEffect(() => {
    props.value = props.defaultValue
  }, [props.defaultValue])

  return (
    <SliderPrimitive.Root
      ref={ref}
      className={cn(
        'relative flex w-full touch-none select-none items-center',
        className
      )}
      {...props}
    >
      <SliderPrimitive.Track className="relative h-2 w-full grow overflow-hidden rounded-full bg-blue">
        <SliderPrimitive.Range className="absolute h-full bg-white-off" />
      </SliderPrimitive.Track>
      <SliderPrimitive.Thumb className="block h-5 w-5 cursor-grab rounded-full border-2 border-white-off bg-white-off ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">
        <div className="absolute -left-2 -bottom-6">
          <p>{`${getValues(0)}`}</p>
        </div>
      </SliderPrimitive.Thumb>
      <SliderPrimitive.Thumb className="block h-5 w-5 cursor-grab rounded-full border-2 border-white-off bg-white-off ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">
        <div className="absolute -left-2 -bottom-6">
          <p>{`${getValues(1)}`}</p>
        </div>
      </SliderPrimitive.Thumb>
    </SliderPrimitive.Root>
  )
})
Slider.displayName = SliderPrimitive.Root.displayName

export { Slider }
