import type { ReactNode } from 'react'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import type { FrontendUser, UserPayload } from '@teamup/db'

import { WebsocketsProvider } from '~/context/useWebsockets'
import { trpc } from '~/lib/trpc'
import { getAuthCookie, removeAuthCookie, setAuthCookie } from './useTRPC'
import useUser from './useUser'

const AuthContext = createContext<AuthContextType>({} as AuthContextType)

interface AuthContextType {
  user: FrontendUser | undefined
  login: (username: string, password: string) => Promise<UserPayload | unknown>
  logout: () => void
}

export function AuthProvider({ children }: { children: ReactNode }) {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { setUser, user, authenticated, setAuthenticated } = useUser()
  const verifyToken = trpc.auth.verify.useMutation()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    const token = getAuthCookie()
    if (!authenticated && token) {
      verifyToken
        .mutateAsync(token)
        .then(async (res: UserPayload) => {
          setAuthenticated(true)
          setUser(res.user)
        })
        .catch(() => {
          const callback = searchParams.get('callback') || location.pathname
          navigate(`/login?callback=${encodeURIComponent(callback)}`)
          removeAuthCookie()
        })
        .finally(() => {
          setInitialized(true)
        })
    } else if (!location.pathname.startsWith('/dashboard')) {
      setInitialized(true)
    } else if (!authenticated) {
      setInitialized(true)
      navigate(`/login?callback=${encodeURIComponent(location.pathname)}`)
    }
  }, [authenticated])

  const loginMutation = trpc.auth.login.useMutation()

  async function login(email: string, password: string) {
    return new Promise((resolve, reject) => {
      loginMutation
        .mutateAsync({ email, password })
        .then((res) => {
          console.log('Welcome to teamup!')
          setAuthCookie(res.token)
          setUser(res.user)
          setAuthenticated(true)
          resolve(res)
        })
        .catch(reject)
    })
  }

  function logout() {
    removeAuthCookie()
    setUser(undefined)
    navigate('/')
  }

  const memo = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  )

  return (
    <AuthContext.Provider value={memo}>
      {initialized && <WebsocketsProvider>{children}</WebsocketsProvider>}
    </AuthContext.Provider>
  )
}

export default function useAuth() {
  return useContext(AuthContext)
}
