import React from 'react'
import ReactDOM from 'react-dom/client'

import './index.css'

import { HelmetProvider } from 'react-helmet-async'
import { RouterProvider } from 'react-router-dom'

import TraceProvider from '~/lib/tracer'
import { router } from '~/routes'

const rootElement = document.getElementById('root')
if (!rootElement) {
  throw new Error('Root element not found')
}
ReactDOM.createRoot(rootElement).render(
  // <React.StrictMode>
  <HelmetProvider>
    <TraceProvider>
      <RouterProvider router={router} />
    </TraceProvider>
  </HelmetProvider>
  // </React.StrictMode>
)
