import { z } from "zod";
import { ChallengeSchema, CompanySchema, RoleSchema } from "./generated";
import { FacilitatorCommand, PlayerStatus } from "./enums";

export const PlayerSchema = z.object({
  slot: z.number(),
  status: z.nativeEnum(PlayerStatus),
  socket: z.string().optional(),
  uuid: z.string().optional(),
});

export type Player = z.infer<typeof PlayerSchema>;

export const LoginSchema = z.object({
  email: z.string(),
  password: z.string().min(8),
});

export type Login = z.infer<typeof LoginSchema>;

export const ResetPWSchema = z.object({
  password: z.string().min(8),
  password2: z.string().min(8),
});

export type ResetPW = z.infer<typeof ResetPWSchema>;

export const CreateSessionInputSchema = z.object({
  name: z.string().min(4).max(50),
  companyId: z.coerce.number().min(1),
  teams: z.coerce.number().min(1).max(100),
  date: z.coerce.string().datetime({ offset: true }),
  timing: z.object({
    start: z.date(),
    end: z.date(),
  }),
  challenges: ChallengeSchema.array().min(1),
});

export type CreateSessionInput = z.infer<typeof CreateSessionInputSchema>;

export const ForgotPWSchema = z.object({
  email: z.string(),
});

export type ForgotPW = z.infer<typeof ForgotPWSchema>;

export const CreateUserSchema = z.object({
  companyId: z.number(),
  password: z.string(),
  email: z.string().email(),
  name: z.string(),
  role: RoleSchema,
});

export type CreateUser = z.infer<typeof CreateUserSchema>;

export const JWTPayloadSchema = z.object({
  id: z.number(),
});

export type JWTPayload = z.infer<typeof JWTPayloadSchema>;

export const FrontendUserSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  email: z.string().email(),
  company: CompanySchema,
  role: RoleSchema,
});

export type FrontendUser = z.infer<typeof FrontendUserSchema>;

export const UserPayloadSchema = z.object({
  user: FrontendUserSchema.required(),
  token: z.string(),
});

export type UserPayload = z.infer<typeof UserPayloadSchema>;

export const SessionCommandPayloadSchema = z.object({
  command: z.enum([
    FacilitatorCommand.DELETE_SESSION,
    FacilitatorCommand.TERMINATE_SESSION,
    FacilitatorCommand.START_ALL,
    FacilitatorCommand.PAUSE_ALL,
    FacilitatorCommand.RESUME_ALL,
    FacilitatorCommand.ADD_TEAM,
  ]),
  session: z.string().uuid(),
});

export const TeamCommandPayloadSchema = z.object({
  command: z.enum([
    FacilitatorCommand.RESET_CHALLENGE,
    FacilitatorCommand.START_TEAM,
    FacilitatorCommand.PAUSE_TEAM,
    FacilitatorCommand.RESUME_TEAM,
    FacilitatorCommand.SWITCH_MAX_PLAYERS,
  ]),
  team: z.string().uuid(),
});
export const TeamPlayerCommandPayloadSchema = z.object({
  command: z.enum([FacilitatorCommand.KICK_PLAYER]),
  team: z.string().uuid(),
  slot: z.number(),
});
export const TeamChallengeCommandPayloadSchema = z.object({
  command: z.enum([FacilitatorCommand.SKIP_TO_CHALLENGE]),
  team: z.string().uuid(),
  challenge: z.number(),
});

export const FacilitatorCommandSchema = z.discriminatedUnion("command", [
  SessionCommandPayloadSchema,
  TeamCommandPayloadSchema,
  TeamPlayerCommandPayloadSchema,
  TeamChallengeCommandPayloadSchema,
]);

export type FacilitatorCommandType = z.infer<typeof FacilitatorCommandSchema>;
